import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Swal from 'sweetalert2';

// Icons
import {
  faChevronLeft,
} from "@fortawesome/pro-light-svg-icons";

import Helmet from 'react-helmet';

//Images
import CategoryImage_1 from "../assets/images/mobile-categories/mobile-category1.png";
import CategoryImage_2 from "../assets/images/mobile-categories/mobile-category2.png";
import CategoryImage_3 from "../assets/images/mobile-categories/mobile-category3.png";
import CategoryImage_4 from "../assets/images/mobile-categories/mobile-category4.png";
import CategoryImage_5 from "../assets/images/mobile-categories/mobile-category5.png";
import CategoryImage_6 from "../assets/images/mobile-categories/mobile-category6.png";
import CategoryImage_7 from "../assets/images/mobile-categories/mobile-category7.png";
import CategoryImage_8 from "../assets/images/mobile-categories/mobile-category8.png";

// Services
import Ad from '../services/Ad';

// Data
const categories_list = [
  {id: '5f2b397f4132b6c235b31490', category_name: "roupas e etc", category_image: CategoryImage_5, category_link: "/resultados" },
  {id: '5f2b397f4132b6c235b314ed', category_name: "kids", category_image: CategoryImage_6, category_link: "/resultados" },
  {id: '5f2b397f4132b6c235b31545', category_name: "beleza e saúde", category_image: CategoryImage_4, category_link: "/resultados" },
  {id: '5f2b397f4132b6c235b3154a', category_name: "casa e decoração", category_image: CategoryImage_7, category_link: "/resultados" },
  {id: '5f2b397f4132b6c235b31569', category_name: "outros", category_image: CategoryImage_8, category_link: "/resultados" },
]

const scamb_choices = [
  {id: '5f2b397f4132b6c235b31490', category_name: "escolhas scamb", category_image: CategoryImage_3, category_link: "/resultados" },
]

const Category = (props) => {
  return (
    <Col xs="6" md="4" lg="3" key={props.index}>
      {props.children}
    </Col>
  )
}

class MobileSearch extends Component {
 
  redirect = (childState) =>{
      window.location.href=`/resultados?category=${childState.category}&pageNumber=1`;
  }

  search = (e, idCategory) =>{
      e.preventDefault();
      Swal.showLoading();
      Swal.fire('Aguarde...')

      this.redirect({category:idCategory, newSearch:true});
  }

  render() {

    const back = () => window.history.go(-1);

    return (
      <section id="view_search">
        <Helmet title="Scamb - Busca mobile" />
        <div className="_wrap-search">
          <div className="_header">
            <button type="button" className="_back-history btn btn-transparent" onClick={back}>
              <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
            </button>
            <div className="_title-view">Busca por interesses</div>
          </div>

          <div className="_wrap-categories">
            <Row>

              {categories_list.map(({id, category_name, category_image}) => (

                <Category redirect={this.redirect} key={id}>
                  <a href="#!" onClick={(e)=>this.search(e,id)} id={id} className="_category-search-item">
                    <div className="_item-image">
                      <img src={category_image} alt="" />
                    </div>
                    <div className="_item-footer"> {category_name} </div>
                  </a>
                </Category>

              ))}

              
              {scamb_choices.map(({id, category_name, category_image}) => (

                <Category key={id}>
                  <Link to={{pathname:'/resultados', state:{tag:'top20', newSearch:true}}} id={id} className="_category-search-item">
                    <div className="_item-image">
                      <img src={category_image} alt="" />
                    </div>
                    <div className="_item-footer"> {category_name} </div>
                  </Link>
                </Category>

              ))}

              

            </Row>
          </div>
        </div>
      </section>
    );
  }
}

export default MobileSearch;
