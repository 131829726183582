import React, {Component, useState} from 'react';

class ModalTaxs extends Component {

    render () {
    
      return (
        <>
            <ul className="_taxs-list">
                <li>
                    <div className="_taxs-list-title">valor do produto em PONTOS</div>
                    <div className="_taxs-list-value">
                        <div className="_value">taxa Scamb (R$)</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">1 - 99 pontos</div>
                    <div className="_taxs-list-value">
                        <div className="_value">3,90</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">100 - 199 pontos</div>
                    <div className="_taxs-list-value">
                        <div className="_value">5,90</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">200 - 499 pontos</div>
                    <div className="_taxs-list-value">
                        <div className="_value">9,90</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">500 - 799 pontos</div>
                    <div className="_taxs-list-value">
                        <div className="_value">12,90</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">800 - 999 pontos</div>
                    <div className="_taxs-list-value">
                        <div className="_value">14,90</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">{">"} 1.000 pontos (*)</div>
                    <div className="_taxs-list-value">
                        <div className="_value">19,90</div>
                    </div>
                </li>
            </ul>
            <p style={{fontSize: '10px', padding: '0 15px 15px 15px'}}>(*) limite de 5 itens por pedido</p>
        </>
      );
    
}
}

export default ModalTaxs;