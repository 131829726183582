//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React, { Component } from  'react';
import { Link, withRouter } from  'react-router-dom';
import { createHashHistory } from 'history'

//=====================================================================================
// #2 - Import * UI Components
//=====================================================================================
import { AdvertiserCard } from "../components/ui/advertiser-card";
import { ProductThumbnail } from "../components/ui/product-thumbnail";

//=====================================================================================
// #3 - Import * Sweet Alert
//=====================================================================================
import Swal from 'sweetalert';

//=====================================================================================
// #4 - Import * FontAwesome Icons
//=====================================================================================
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShoppingBag, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from "@fortawesome/pro-regular-svg-icons"; 
import { faHeart as faHeartSolid } from "@fortawesome/pro-solid-svg-icons"; 

//=====================================================================================
// #5 - Import * Services
//=====================================================================================
import Favorite from '../services/Favorito';
import { connect } from 'react-redux';

//=====================================================================================
// #6 - Import * Modals
//=====================================================================================
import ShoppingCartDialog from "../components/modals/shopping-cart";

//=====================================================================================
// #7 - Import * Bootstrap Components
//=====================================================================================
import Button from 'react-bootstrap/Button'

//=====================================================================================
// #8 - Fake Products
//=====================================================================================
const path_url_fake = "https://photos.enjoei.com.br/public/";
const products_fake = [
  { url: `${path_url_fake}255x255/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy8xMzU1OTc2MS9iZjg2ODNjMmU4OWVkYmVlZTliZTgzMjViYThlNGJjMi5qcGc`, title: "sandália vermelha 35", price: 90, oldprice: 107, freight: "correios" },
  { url: `${path_url_fake}255x255/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy84MjEyMTI0L2MxZjMzOWIwM2IzZGZmZTJmOTY3MDU0MDBhYzZjNWY3LmpwZw`, title: "star wars a trilogia", price: 104, oldprice: 120, freight: "entrega_maos" },
  { url: `${path_url_fake}255x255/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy82MTY0MzE5LzZhOWIyZDM3ZjY5ZWUxMTQzYzNmYTQ3YzI4Y2EwYjEzLmpwZw`, title: "canecas tok&stok", price: 95, oldprice: "", freight: "entrega_maos" },
]


class GridProduct extends Component {

    constructor(){
        
        super();

        // * Create React Refs
        this.refShoppingCart = React.createRef();

        this.favorite = new Favorite();
        this.history = createHashHistory();

        this.state = {
            activeIconBag: false,
            activeIconHeart: false,
            iconFavorite: true,
            iconShoppingBag: false,
        }
    }

    // * Open Dialog Shopping Cart
    showShoppingCardDialog = () => {

        this.setState({ activeIconBag: !this.state.activeIconBag });

        if(!this.state.activeIconBag) {
            setTimeout(() => {
                this.refShoppingCart.current.openModal()
            }, 450)
        }
    
    };

    // Delete Cart Item
    deleteCartItem = () => {

        Swal({
            title: "Deletar produto?",
            icon: "warning",
            buttons: [
            'Fechar',
            'Confirmar'
            ],
            dangerMode: true })
            .then(function(isConfirm) {
                if (isConfirm) {
                    Swal({title: "Produto removido!", icon: "success", buttons: 'Ok', dangerMode: true})
                }
        });
    }

    componentDidMount(){
        if(this.props.scamber) this.loadIfIsFavorited(this.props.idAd);
    }

    loadIfIsFavorited = ( idAd ) =>{
        this.favorite.find(idAd)
            .then((response) => {
            
                this.setState({iconFavorite: false})
                this.setState({ activeIconHeart: !false});
            })
            .catch((error)=>{
            if(error.response.status === 404) return this.setState({iconFavorite: true})
            Swal.fire({type: "info", html: this.favorite.formatErrorMessage(error.response),title: ""})
        })   
    } 

    handleFavorite = (idAd) => {
        
        this.favorite.create({'idAd':idAd})
        .then((response) => this.loadIfIsFavorited(idAd))
        .catch((error)=>{
            Swal.fire({type: "info", html: this.favorite.formatErrorMessage(error.response),title: ""})
        })
    }

    redirectLogin = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
    }
    

    render() {
        
        return (
            <>
            <ShoppingCartDialog
                ref={this.refShoppingCart}
                dialogTitle="Minha Sacola"
                className="shopping-cart-dialog"
                checkoutUrl="/checkout/"
            >
                <AdvertiserCard storeLogo="https://via.placeholder.com/150x150" storeLink="/loja/" storeTitle="Loja Emilia" storeLocation="Curitiba - PR" button={true} />

                {products_fake.map((product) => (

                    <ProductThumbnail
                        image={product.url}
                        title={product.title}
                        oldPrice={product.oldprice}
                        price={product.price}
                        freightType={product.freight}
                        productUrl="/produto?id=5f71fab26f53fc335a8aa21f"
                    >
                        
                        <Button 
                            className="trash-item"
                            color="primary"
                            autoFocus
                            type="button"
                            onClick={this.deleteCartItem}
                        >
                        <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </ProductThumbnail>

                ))}

            </ShoppingCartDialog>

            <div className="_ui-item-wrapper">
                <div className="_ui-item">
                    <Link to={this.props.link} style={{backgroundImage: `url(${this.props.image})`}} className="_ui-item-image">
                        {/* <img src={this.props.image} className="_ui-item-image_block" alt="" /> */}
                    </Link>
                    <div className="_ui-item-bottom">
                        <div className="_col-left">
                            <Link to={this.props.link} className="_product-title">
                                {this.props.title}
                            </Link>
                        </div>

                        <div className="_col-right">

                            <div className="_product-price _1x">
                                <div className="ui-item__discount-price _is-hidden">
                                    <span className="price-tag-fraction">{this.props.discount}</span>
                                    <span className="price-tag-symbol">pts</span>
                                </div>
                                <div className="ui-item__price">
                                    <span className="price-tag-fraction">{this.props.price}</span>
                                    <span className="price-tag-symbol">pts</span>
                                </div>

                            </div>

                            <div className="actions">

                                <button
                                    type="button"
                                    className={this.state.activeIconBag ? "btn btn-transparent active" : "btn btn-transparent" }
                                    onClick={this.showShoppingCardDialog}
                                >
                                    {this.state.activeIconBag && (
                                        <span className="check">
                                            <FontAwesomeIcon icon={faCheck} />
                                        </span>
                                    )}
                                    <FontAwesomeIcon icon={faShoppingBag} />
                                </button>

                                <button
                                    type="button"
                                    className={this.state.activeIconHeart ? "btn btn-transparent active" : "btn btn-transparent" }
                                    onClick={this.props.scamber ? ()=>this.handleFavorite(this.props.idAd) : (e)=>this.redirectLogin(e) }
                                >
                                    <FontAwesomeIcon icon={this.state.iconFavorite ? faHeart : faHeartSolid} />
                                </button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            </>
        );
        
    };
    
};


const mapStateToProps = state => {
	return {
		scamber: state.scamberReducer.scamber
	}
}

export default withRouter(connect(mapStateToProps)(GridProduct));