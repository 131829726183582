import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Layout from "../../../components/account/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';

import { faCog, faPlus, faCreditCard } from "@fortawesome/pro-light-svg-icons";

import Scamber from '../../../services/Scamber';
import StoreForm from './StoreForm';
import PersonalForm from './PersonalForm';
import AddressForm from './AddressForm';

import { setScamber } from './../../../redux/actions/scamberActions';
import Helmet from 'react-helmet';

class MyProfile extends Component {

  loadScamberData = () => {
    Swal.fire('Aguarde...');
    Swal.showLoading();
    const scamber = new Scamber();
    scamber.findScamber().then(( scamberData ) =>{

      Swal.close();
      if( scamberData.status === 200) this.props.setScamber(scamberData.data.payload);

    }).catch((error)=> Swal.fire({type:'info', html: error.response.data.message, title:''}));
  }

  render() {
    return (
      <Layout className="_profile-width">
        <div id="my-profile" className="_content">
          <h1 className="_title-box">
            <span>
              <FontAwesomeIcon icon={faCog} />
            </span>
            Meu Perfil
          </h1>
          <Helmet title="Scamb - Meu perfil" />

          <StoreForm store={this.props.scamber?.minha_loja} reloadScamber={this.loadScamberData} title="Minha Loja"></StoreForm>
          <PersonalForm profile={this.props.scamber} reloadScamber={this.loadScamberData} title="Dados Pessoais"></PersonalForm>
          <AddressForm address={this.props.scamber?.endereco} reloadScamber={this.loadScamberData} title="Endereço"></AddressForm>

          <div className="_wrap-form-panel">
            <div className="_form">
              <div className="_wrap-form-header">
                <h1 className="_title-box">Adicionar Método de Pagamento</h1>
                <div className="actions">
                  <button
                    type="button"
                    className="btn btn-transparent _edit-cancel"
                  ></button>
                  <button
                    type="button"
                    className="btn btn-transparent _confirm"
                  ></button>
                </div>
              </div>

              <div className="_wrapper">
                <div className="_message-box">
                  <div className="_wrap-content-box">
                    <div className="_icon">
                      <FontAwesomeIcon icon={faCreditCard}></FontAwesomeIcon>
                    </div>

                    <div className="_wrap-texts">
                      <div className="_text">
                        Adicione uma forma de pagamento clicando no botão
                        Adicionar abaixo, ou clique em Agora não para continuar
                        navegando.
                        <div className="actions">
                          <Link to="/" className="btn btn-cancel">
                            Agora não
                          </Link>
                          <Link
                            to="/metodo-de-pagamento"
                            className="btn btn-primary"
                          >
                            Adicionar{" "}
                            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    scamber: state.scamberReducer.scamber
  }
}

export default connect(mapStateToProps, { setScamber })(MyProfile);
