//===========================================================
// #1 - Base Imports
//===========================================================
import React, { useState } from "react";

//===========================================================
// #2 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";


export const SelectBox = (props) => {

    return (
        <div className="select-box">
            <label>
                <div className="_cols">
                    <div className="_col">
                        <div className="icon">
                            {props.icon}
                        </div>
                        <div className="text">
                            {props.titleContent}
                        </div>
                    </div>

                    <div className="_col">
                        {props.input}
                        <div className="check">
                            <Icon icon={faCheck} />
                        </div>
                    </div>
                </div>
                <div className="content">
                    {props.children}
                </div>
            </label>
        </div>
    )
}

export const SelectBoxExpand = (props) => {

    // * States
    const [panel, setPanel] = useState(false);

    // * Functions
    const handlePanel = () => setPanel(!panel);

    return (
        <div className="select-box _expand">
            <label>
                <div className="_cols">
                    <div className="_col" onClick={handlePanel}>
                        <div className="icon">
                            {props.input}
                            <div className="check">
                                <Icon icon={faCheck} />
                            </div>
                            {props.icon}
                        </div>
                        <div className="text">
                            {props.titleContent}
                        </div>
                    </div>

                    <div className="_col">
                    <Icon icon={panel ? faChevronUp : faChevronDown} />
                    </div>

                </div>
                {panel && (
                <div className="content">
                    {props.children}
                </div>
                )}
            </label>
        </div>
    )
}