import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// JQuery
import $ from "jquery";
import "jquery-mask-plugin";

import { faEdit, faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

// Material - UI
import {
  useMediaQuery,
  Grid,
  TextField,
} from "@material-ui/core";

import Endereco from '../../../services/Endereco';

function AddressForm(props) {
    const matches = useMediaQuery("(min-width:600px)");
  
    const { register, handleSubmit, errors, reset } = useForm();
    const [editForm, showForm] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [disableField, setDisableField] = useState(true);
    const [ address, setAddress ] = useState({});
    let [ cep, setCep ] = useState({});

  
    let [ exists_address, set_exists_address ] = useState(false);
    

    const [value, setValue] = useState([
      { id: "10", text: "One" },
      { id: "20", text: "Two" }
    ]);

    useEffect(() => {
      initFormValues(props.address);
    }, [props.address]);

    const initFormValues = (address) => {
      if(address && address.length > 0) {
        set_exists_address(true);
        reset(address[0]); 
        setAddress(address[0]);
      }
    }
  
    let endereco = new Endereco();

    const onSubmit = (data) => {
      
      Swal.fire('Aguarde...');
      Swal.showLoading();

      endereco.update(data).then(( response )=>{

        if( response.status === 200 ){

          Swal.fire({
            icon: "success",
            title: "Endereço alterado!",
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "fechar",
          }).then((its_ok) => {
            props.reloadScamber();
            if (its_ok) {
              setDisableField(!disableField);
            }
          });

        }
       
      }).catch((error)=> Swal.fire({type:'info', html: endereco.formatErrorMessage(error.response), title:''}))
  
      showForm(false);
      setDisabled(!disabled);
    };
  
    const toggleIcon = () => {
      showForm(!editForm);
      setDisabled(!disabled);
      setDisableField(!disableField);
    };
  
    useEffect(() => {
      $(".cep").mask("00000-000");
      $(".house_number").mask("00000");
    });

    let handleChange = (event) => {
      setCep(event.target.value);
    }

    let findAddressByCEP = () => {
      
      if(cep.length === 9){
        endereco.findCep(cep).then(( address ) => {

          let formatedAddress = {
            cep:        address.data.cep,
            estado:     address.data.estado,
            logradouro: address.data.logradouro,
            cidade:     address.data.localidade,
            estado:     address.data.uf,
            bairro:     address.data.bairro,
            complemento:     address.data.complemento,
          }
  
          if(address.data){
            reset(formatedAddress); 
          }
        }).catch((error)=> Swal.fire('info','Não foi possível consultar o endereço',''));
      }
    }
  
    return (
      <>
        <div className="_wrap-form-panel">
          <form
            className={
              editForm ? "_form _validate" : "_form _inline-disable _validate"
            }
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="_wrap-form-header">
              <h1 className="_title-box">{props.title}</h1>
              <div className="actions">
                <button
                  type="button"
                  className="btn btn-transparent _edit-cancel"
                  onClick={toggleIcon}
                >
                  <FontAwesomeIcon
                    icon={editForm ? faTimes : faEdit}
                  ></FontAwesomeIcon>
                </button>
                {editForm ? (
                  <button type="submit" className="btn btn-transparent _confirm">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                    Salvar
                  </button>
                ) : null}
              </div>
            </div>
  
            {exists_address ? (
            <div className="_set-user-location">
              <div className="_wrap-location">
                <div className="_icon">
                  <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
                </div>
  
                <div className="_wrap-current-location">
                <div className="_location-title">CEP: {address.cep?.toUpperCase()}</div>
                  <div className="_location">
                    {address.logradouro?.toUpperCase()}, Nº {address.numero} - BAIRRO {address.bairro?.toUpperCase()},
                    {address.complemento === "" ? "" : " - COMPLEMENTO: "+address.complemento?.toUpperCase()} - 
                    &nbsp;{address.cidade?.toUpperCase()} - {address.estado?.toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
            ) : null}
  
            {editForm ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="form-group">
                    {disableField ? (
                      <label className="_label">
                        Cep <span>*</span>
                      </label>
                    ) : null}
                    <TextField
                      id="outlined-cep"
                      label={disableField ? null : "Cep *"}
                      type="text"
                      variant={disableField ? "filled" : "outlined"}
                      size={matches ? "small" : "small"}
                      name="cep"
                      onChange={handleChange}
                      onBlur={findAddressByCEP}
                      error={!!errors.cep}
                      fullWidth
                      disabled={disableField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{className:'cep'}}
                      inputRef={register({
                        required: true,
                      })}
                    />
                  </div>
                </Grid>
                <Grid item xs={disableField ? 12 : 6}>
                <div className="form-group">
                    {disableField ? (
                      <label className="_label">
                        Estado <span>*</span>
                      </label>
                    ) : null}
                    <TextField
                      id="outlined-state"
                      label={disableField ? null : "Estado *"}
                      type="text"
                      variant={disableField ? "filled" : "outlined"}
                      size={matches ? "small" : "small"}
                      name="estado"
                      error={!!errors.estado}
                      fullWidth
                      disabled={disableField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({
                        required: true,
                      })}
                    />
                  </div>
                </Grid>
                <Grid item xs={disableField ? 12 : 6}>
                <div className="form-group">
                    {disableField ? (
                      <label className="_label">
                        Cidade <span>*</span>
                      </label>
                    ) : null}
                    <TextField
                      id="outlined-city"
                      label={disableField ? null : "Cidade *"}
                      type="text"
                      variant={disableField ? "filled" : "outlined"}
                      size={matches ? "small" : "small"}
                      name="cidade"
                      error={!!errors.cidade}
                      fullWidth
                      disabled={disableField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({
                        required: true,
                      })}
                    />
                  </div>
                  </Grid>
                <Grid item xs={disableField ? 12 : 9}>
                <div className="form-group">
                    {disableField ? (
                      <label className="_label">
                        Endereço <span>*</span>
                      </label>
                    ) : null}
                    <TextField
                      id="outlined-username"
                      label={disableField ? null : "Endereço *"}
                      type="text"
                      variant={disableField ? "filled" : "outlined"}
                      size={matches ? "small" : "small"}
                      name="logradouro"
                      error={!!errors.logradouro}
                      fullWidth
                      disabled={disableField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({
                        required: true,
                      })}
                    />
                  </div>
                </Grid>
                <Grid item xs={disableField ? 12 : 3}>
                <div className="form-group">
                    {disableField ? (
                      <label className="_label">
                        Número <span>*</span>
                      </label>
                    ) : null}
                    <TextField
                      id="outlined-number"
                      label={disableField ? null : "Número *"}
                      type="text"
                      variant={disableField ? "filled" : "outlined"}
                      size={matches ? "small" : "small"}
                      name="numero"
                      error={!!errors.numero}
                      fullWidth
                      disabled={disableField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({
                        required: true,
                      })}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                <div className="form-group">
                    {disableField ? (
                      <label className="_label">
                        Bairro <span>*</span>
                      </label>
                    ) : null}
                    <TextField
                      id="outlined-neighborhood"
                      label={disableField ? null : "Bairro *"}
                      type="text"
                      variant={disableField ? "filled" : "outlined"}
                      size={matches ? "small" : "small"}
                      name="bairro"
                      error={!!errors.bairro}
                      fullWidth
                      disabled={disableField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({
                        required: true,
                      })}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                <div className="form-group">
                    {disableField ? (
                      <label className="_label">
                        Complemento <span>*</span>
                      </label>
                    ) : null}
                    <TextField
                      id="outlined-complement"
                      label={disableField ? null : "Complemento"}
                      type="text"
                      variant={disableField ? "filled" : "outlined"}
                      size={matches ? "small" : "small"}
                      name="complemento"
                      error={!!errors.complemento}
                      fullWidth
                      disabled={disableField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({
                        required: false,
                      })}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
            ) : null}
          </form>
        </div>
      </>
    );
  }

  export default AddressForm;