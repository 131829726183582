import React, { Component } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import CreditCard from '../components/account/wallet/CreditCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {selectFx} from '../utils/SelectToggle';

import $ from 'jquery';

import Helmet from 'react-helmet';

// Icons
import { faChevronDown, faCheck, faUser, faChevronRight, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';

class BuyPoints extends Component {
    
    render() {
        
        return (
            
            <section id="view_buy-points">
                <Helmet title="Scamb - Comprar pontos" />
                <div className="_med-container">
                    
                    <div className="_cols">
                        
                        <div className="_col _col-left">
                            <form className="box _form">
                                <div className="_box-title">
                                    Comprar Pontos Scamb
                                </div>
                                
                                <div className="_box-content">
                                    <div className="_wrap-fields">
                                        
                                            <div className="form-group">
                                             <label htmlFor="qtd-points" className="_label">Quantidade em pontos * 
                                                 <button type="button" className="btn btn-transparent _disabled" style={{visibility: 'hidden'}}>
                                                    <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                                </button>
                                              </label>
                                            <input type="text" id="qtd-points" className="form-control" placeholder="0" />
                                          </div>
                                            
                                            <div className="_wrap-form-select">
                                    
                                            <div className="_label">
                                                Opções de parcelamento
                                                <button type="button" className="btn btn-transparent _disabled" style={{visibility: 'hidden'}}>
                                                    <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                                                </button>
                                            </div>
                                            <div className="_form-select _dropdown-select">
                                                <div className="_the-label">
                                                    <span className="_span-name"> Escolha o parcelamento*</span>
                                                    <span className="caret">
                                                        <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                                                    </span>
                                                </div>

                                                <div className="_form-select-content" style={{display: 'none'}}>
                                                    <div className="_select-item">
                                                        <label className="ui-checkbox">
                                                            <input type="radio" className="_checkbox" name="categorias"/>
                                                            <div className="_select-content">
                                                                <span className="_item-name _current-item-name">1x de R$ 100,00</span>
                                                                <span className="_item-icon">
                                                                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                                                                </span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="_select-item">
                                                        <label className="ui-checkbox">
                                                            <input type="radio" className="_checkbox" name="categorias"/>
                                                            <div className="_select-content">
                                                                <span className="_item-name _current-item-name">2x de R$ 50,00</span>
                                                                <span className="_item-icon">
                                                                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                                                                </span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="_select-item">
                                                        <label className="ui-checkbox">
                                                            <input type="radio" className="_checkbox" name="categorias"/>
                                                            <div className="_select-content">
                                                                <span className="_item-name _current-item-name">3x de R$ 33,33</span>
                                                                <span className="_item-icon">
                                                                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                                                                </span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="_select-item">
                                                        <label className="ui-checkbox">
                                                            <input type="radio" className="_checkbox" name="categorias"/>
                                                            <div className="_select-content">
                                                                <span className="_item-name _current-item-name">4x de R$ 25,00</span>
                                                                <span className="_item-icon">
                                                                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                                                                </span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                 
                                    </div>
                                    
                                    <div className="_wrap-credit-card">
                                        
                                        <h1 className="_wrap-title">
                                            Meus cartões de crédito
                                        </h1>
                                        
                                        <CreditCard></CreditCard>
                                    </div>
                                </div>
                                
                                <div className="notice">
                                    Ao clicar em confirmar você aceita os termos e condições<br/> da plataforma.
                                </div>
                                
                                <div className="_buttons">
                                    <div className="form-group-button">
									<button type="submit" className="btn btn-success">Comprar</button>
									<button type="submit" className="btn btn-cancel">Cancelar</button>
								    </div>	
                                </div>
                                
                            </form>
                        </div>
                        <div className="_col _col-right">
                            <div className="box">
                                <div className="_box-title">
                                    Resumo do pedido
                                </div>
                                
                                <ul className="resume-list">
                                    <li>
                                        <div className="resume-list-title">Valor</div>
                                        <div className="resume-list-value">
                                            <div className="_title">pontos scamb</div>
                                            <div className="_value">120 Pontos</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="resume-list-title">Total em dinheiro</div>
                                        <div className="resume-list-value">
                                            <div className="_title">Total a pagar em dinheiro</div>
                                            <div className="_value _price">R$ 120,00</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    
                    </div>
                    
                </div>
            
            </section>
            
        )
        
    }
    
}

export default BuyPoints;