import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Icons
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

// Images
import Tenis from "../../../assets/images/tenis-lacoste.png";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";

function ActivityHistory () {


    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <Accordion className="_activity-history _accordion">
          <Card>
            <Card.Header onClick={() => { setOpen(!open) }}>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <div className="_activity-history-info">
                  <div className="_activity-date">
                    FEV <span className="the-day">03</span>
                  </div>

                  <div className="_activity-info">
                    <div className="_activity-type">
                      Recarga{" "}
                      <span className="_amount">
                        (<span>R$1.000,00</span>)
                      </span>
                    </div>
                    <div className="_activity-payment-type">
                      Cartão de crétido
                    </div>

                    <div className="_activity-amount">+1.000 Pontos</div>

                    {/* className:  approved, pending, canceled*/}
                    <div className="_activity-status approved">
                      concluído
                      <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-transparent toggle-arrow"
                >
                  <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown}></FontAwesomeIcon>
                  
                </button>

              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="_wrap-data-info">
                  <div className="_data-info-title">Pago com</div>
                  <div className="_data-info-content">
                    MasterCard Cartão de crédito x-8817
                    <br />
                    Você verá "Scamb" na fatura do seu cartão.
                  </div>
                </div>

                <div className="_wrap-data-info">
                  <div className="_data-info-title">Data</div>
                  <div className="_data-info-content">21/01/2020</div>
                </div>

                <div className="_wrap-data-info">
                  <div className="_data-info-title">Enviar para</div>
                  <div className="_data-info-content">
                    Emilia Clarke
                    <br />R Bartolomeu bueno da silva, n359 - Bairro Água Verde,
                    Curitiba - Paraná
                  </div>
                </div>

                <div className="_wrap-data-info">
                  <div className="_data-info-title">ID da transação</div>
                  <div className="_data-info-content">6EA69689WW944200R</div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </React.Fragment>
    );

}

export default ActivityHistory;
