import Service from './Service';

class Domain extends Service {

    constructor() {
        super()
    }

    listBrands(brand) {
        if(brand) return this.axiosInstance.get('/domain/brands?brand=' + brand);
        return this.axiosInstance.get('/domain/brands');
    }

    listWeights() {
        return this.axiosInstance.get('/domain/weights');
    }

    listConditions() {
        return this.axiosInstance.get('/domain/conditions');
    }

    findSizeGroup(categorizationIdsSelected) {
        return this.axiosInstance.post('/domain/sizeGroup', categorizationIdsSelected);
    }
    

}

export default Domain;