import React, { Component } from "react";
import { Link } from "react-router-dom";

class OfficialStores extends Component {
  render() {
    return (
      <>
        <Link to={this.props.link} className="official-stores-slide-item">
          <img src={this.props.image} alt="" />
          <span className="points-tag">
            {this.props.price} <span className="points-tag-symbol">pts</span>
          </span>
        </Link>
      </>
    );
  }
}

export default OfficialStores;
