import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import { setScamber } from './../../redux/actions/scamberActions';
// Icons
import {
  faChevronRight,
  faEye,
  faHeart,
  faStore,
  faShoppingCart,
  faWallet,
  faCog,
  faMegaphone,
  faUserFriends,
  faShoppingBag,
} from "@fortawesome/pro-light-svg-icons";

class Menu extends Component {
  render() {
    return (
      <div id="view_my-account-menu">
        <div className="_menu">
          <nav className="_list-nav">
            <ul>
              <li className="active">
                <Link to={"/loja/" + this.props.scamber?.minha_loja?.url}>
                  <span>
                    <FontAwesomeIcon icon={faEye} />
                  </span>
                  Ver loja
                </Link>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </li>
              <li>
                <Link to="/meus-anuncios">
                  <span>
                    <FontAwesomeIcon icon={faMegaphone} />
                  </span>
                  Meus Anúncios
                </Link>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </li>
              <li>
                <Link to="/minhas-compras">
                  <span>
                    <FontAwesomeIcon icon={faShoppingCart} />
                  </span>
                  Minhas Compras
                </Link>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </li>
              <li>
                <Link to="/minhas-vendas">
                  <span>
                    <FontAwesomeIcon icon={faStore} />
                  </span>
                  Minhas Vendas
                </Link>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </li>
              <li>
                <Link to="/meus-favoritos">
                  <span>
                    <FontAwesomeIcon icon={faHeart} />
                  </span>
                  Meus Favoritos
                </Link>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </li>
              <li>
                  <Link to="/minha-sacola">
                      <span>
                          <FontAwesomeIcon icon={faShoppingBag} />
                          <span className="_notification-icon-mark _red"></span>
                      </span>
                      Minha Sacola
                      <div className="count">
                        <h6>2</h6>
                      </div>
                  </Link>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="chevron-right"
                  />
              </li>
              <li>
                <Link to="/minha-carteira">
                  <span>
                    <FontAwesomeIcon icon={faWallet} />
                  </span>
                  Carteira
                </Link>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </li>
              <li>
                <Link to="/meu-perfil">
                  <span>
                    <FontAwesomeIcon icon={faCog} />
                  </span>
                  Meu Perfil
                </Link>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </li>
              <li>
                <Link to="./convidar-amigos">
                  <span>
                    <FontAwesomeIcon icon={faUserFriends} />
                  </span>
                  Convide Amigos
                </Link>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    scamber: state.scamberReducer.scamber
  }
}

export default withRouter(connect(mapStateToProps, { setScamber })(Menu));
