import React, { useState, useEffect} from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import Scamber from './../../services/Scamber';
import Authentication from './../../services/Authentication';
import EventEmitter from './../../services/Events';

import $ from 'jquery';

// Bootstrap
import { Button } from "react-bootstrap";

// Material - UI
import {
  makeStyles,
  useMediaQuery,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

// Icons
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import Helmet from 'react-helmet';

// Styles
const useStyles = makeStyles({
  center: {
    margin: "auto",
    display: "block",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
  svgSize: {
    width: "18px !important",
    height: "auto !important",
  },
});

export default function CreateAccountn(props) {
  // Form
  const { register, handleSubmit, errors, formState, watch } = useForm({
    mode: "onChnage",
  });

   // Handle with facebook login
   const callFacebookLogin = ( event ) => {
     const authentication = new Authentication();
    event.preventDefault();
    authentication.doFacebookLogin(inviteCode);

  }

  const resendValidationEmail = () => {
    const scamber = new Scamber();

    $('.email-error').addClass('d-none');
    $('.email-success').addClass('d-none');

    scamber.resendEmailValidation().then(() => {
      
      $('.email-success').removeClass('d-none');

      setTimeout(() => {
        $('.email-success').addClass('d-none');
      }, 3000)
  
    }).catch((error) => { 
     
      $('.email-error').removeClass('d-none')  
      
      setTimeout(() => {
        $('.email-success').addClass('d-none');
      }, 3000)

    }) 
  }

  useEffect(() => {
    
    if(props.location.pathname.indexOf('/in') !== -1){
      let inviteCode = props.match.params.inviteCode
      setInviteCode(inviteCode);
    }

  });
  
  const onSubmit = (data) => {
    const scamber = new Scamber();

    Swal.fire('Aguarde...');
    Swal.showLoading();
    const simpleScamber = scamber.createSimpleScamber(data.create_acc_email, data.create_acc_password, data.create_acc_username);
    scamber.saveScamber(simpleScamber).then((response) => {
      Swal.close();
      if( response.status === 201 || response.status === 200 ) {
        
        Swal.fire({
          icon: "info",
          title: "Confirme sua conta",
          html:
            "Um código de 6 digitos foi enviado para o email " +
            `<b>${simpleScamber.email}</b> \n <p>Digite o código no campo abaixo para confirmar sua conta</p>
            <p><a id="resend-email" href="#!">Não recebi meu código de 6 dígitos</a></p>
            <p class="email-success d-none">Email de validação reenviado com sucesso!</p>
            <p class="email-error d-none">Não foi possível reenviar o email de validação!</p>`,
          input: "text",
          inputPlaceholder: "Digite seu código aqui",
          showCancelButton: true,
          confirmButtonText: "confirmar",
          cancelButtonText: "cancelar",
          inputValidator: (value) => {
            if (!value) {
              return "Código está incorreto";
            } else {
              scamber.validate(value, inviteCode? inviteCode : null).then((response) => {
                if( response.status === 200) {
                  
                  const authentication = new Authentication();

                  authentication.doLogin( { email: simpleScamber.email, password: simpleScamber.senha } ).then(( isAuthenticated ) =>{
      
                    if( isAuthenticated.status === 200) {
                      localStorage.setItem('token', isAuthenticated.data.payload.token);
                      EventEmitter.dispatch('loginSucess');
                      props.history.push('/');
                      
                      Swal.fire({
                        icon: "success",
                        title: "Conta criada com sucesso!",
                        text: "Clique no botão abaixo para editar suas preferências",
                        confirmButtonText: "continuar",
                      });

                    }
              
                  }).catch (( error ) => Swal.fire({type:'info', html: scamber.formatErrorMessage(error.response), title:''}));

                }
              }).catch(( error ) => Swal.fire({type:'info', html: scamber.formatErrorMessage(error.response), title:''}))

             
            }
          },
        });

        $("#resend-email").on('click', function() {
          console.log('entrou')
          resendValidationEmail();
        });

      }

    }).catch(( error ) => Swal.fire({type:'info', html: scamber.formatErrorMessage(error.response), title:''}))


  };

  // Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  // Password
  const [password, setPassword] = useState(false);

  const handlePassword = () => setPassword(!password);

  // Redirect
  const [redirect, setRedirect] = useState(false);

  const [inviteCode, setInviteCode] = useState('');

  const handleRedirect = () => {
    setRedirect(!redirect);
  };

  const renderRedirect = () => {
    if (redirect) return <Redirect to="/meu-perfil" />;
  };

  return (
    <div id="view_create-account">
      <Helmet title="Scamb - Criar Conta" />
      {
        //Redirect Call
        renderRedirect()
      }

      <section className="_wrap-auth-screen">
        <div className="auth-screen">
          <h1 className="_auth-screen-title headline _color _black _fw700 text-center">
            Acesse sua conta Scamb
          </h1>

          <div className="_wrap-auth-facebook">
            <a href="#!" onClick={callFacebookLogin} className="_auth-facebook-btn">
              <span className="btn-icon">
                <FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon>
              </span>
              Entrar com o facebook
            </a>
          </div>

          <div className="_or">
            <div className="divider _color _gray _13px">
              <span className="text">ou</span>
            </div>
          </div>

          <div className="_wrap-form">
            <form className="" onSubmit={handleSubmit(onSubmit)} id="Form">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-username"
                    label="Nome completo *"
                    type="text"
                    variant="outlined"
                    size={matches ? "small" : "medium"}
                    name="create_acc_username"
                    error={!!errors.create_acc_username}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="outlined-email"
                    label="E-Mail *"
                    type="text"
                    variant="outlined"
                    size={matches ? "small" : "medium"}
                    name="create_acc_email"
                    error={!!errors.create_acc_email}
                    fullWidth
                    helperText={
                      errors.create_acc_email && errors.create_acc_email.message
                    }
                    inputRef={register({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Endereço de email inválido :(",
                      },
                    })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="outlined-password"
                    label="Senha *"
                    type={password ? "text" : "password"}
                    variant="outlined"
                    name="create_acc_password"
                    size={matches ? "small" : "medium"}
                    error={!!errors.create_acc_password}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handlePassword}
                            edge="end"
                          >
                            <FontAwesomeIcon
                              className={classes.svgSize}
                              icon={password ? faEye : faEyeSlash}
                            ></FontAwesomeIcon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="outlined-repeat-password"
                    label="Repita sua senha *"
                    type="password"
                    variant="outlined"
                    size={matches ? "small" : "medium"}
                    name="create_acc_repeat_password"
                    error={!!errors.create_acc_repeat_password}
                    fullWidth
                    inputRef={register({
                      required: true,
                      validate: (value) =>
                        value === watch("create_acc_password"),
                    })}
                  />
                </Grid>
              </Grid>

              <div className="_checkbox form-check">
                <label className="form-check-label">
                  <input
                    name="checkTerms"
                    type="checkbox"
                    className="form-check-input"
                    ref={register({ required: true })}
                  />
                  <span className={errors.checkTerms ? "check error" : "check"}>
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>

                  <div className="_alt-text">
                    Ao clicar em criar conta, voce está de acordo com os nossos
                    <Link to="/termos-e-condicoes" target="_blank"> termos de uso</Link>
                  </div>
                </label>
              </div>

              <div className="form-group-button">
                <Button
                  type="submit"
                  className={classes.center}
                  color="primary"
                  autoFocus
                  style={{ height: matches ? 35 : 40 }}
                  disabled={
                    !formState.dirty || (formState.dirty && !formState.isValid)
                  }
                >
                  Criar conta
                </Button>
              </div>
            </form>
          </div>

          <div className="_have-account _color _gray _13px _fw700 text-center">
            Já possui uma conta? <Link to="/login">Faça o login</Link>
          </div>
        </div>
      </section>
    </div>
  );
}
