import React, { Component } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

class OfficialStores extends Component {
  render() {
    return (
      <>
        {/* UI CARD */}
        <div className="_ui-card official-stores-slide _link">
          {/* WRAPPER */}
          <div className="_ui-item-wrapper">
            <div className="official-stores-slide-background">
              <img src={this.props.banner} alt="" />
            </div>

            <div className="official-stores-slide-logo">
              <div className="logo">
                <img src={this.props.logo} alt="" />
              </div>
              <span className="title-wrapper _color _black _15px _fw700">
                {this.props.title}
              </span>
            </div>

            <div className="official-stores-slide-items">
              {/*  ./components/OficialStoreProducts  */}
              {this.props.children}
            </div>

            <Link to={this.props.link} className="official-stores-action">
              Ver mais <FontAwesomeIcon icon={faChevronRight} />{" "}
            </Link>
          </div>
          {/* END ./ WRAPPER */}
        </div>
        {/* END ./ UI CARD */}
      </>
    );
  }
}

export default OfficialStores;
