import React, { Component } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumbs";
import Helmet from 'react-helmet';

class HowItWorks extends Component {
  render() {
    return (
      <section id="view_common-page">
        <Helmet title="Scamb - Como funciona" />
        <Breadcrumb></Breadcrumb>

        <div className="_med-container">
          <div className="_terms-conditions-content">
            <h1 className="headline">Participar do Scamb é fácil!!</h1>

            <div className="_wrap-topic">
              <p className="_topic-content">
                <br />
                Veja como funciona passo a passo.
                <br />
                Venda. Junte pontos. Compre. Repita
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">Primeiro de tudo</h1>
              <p className="_topic-content">
                Você precisa criar uma conta, é super fácil e rápido! Dados
                simples como nome, e mail e senha.
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">Começando a circular</h1>
              <p className="_topic-content">
                Criar a sua lojinha é divertido, você pode personalizar da sua
                maneira, com foto , descrição, etc. Depois você separa suas
                coisas que quer se desfazer, faz uma foto bem legal e cadastra
                os produtos. É bem intuitivo.
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">Vendendo</h1>
              <p className="_topic-content">
                Com seus produtos cadastrados é hora de vender! Os outros
                Scambers escolhem seu produto e após o recebimento eles dão ok
                para a compra e os pontos são creditados na sua carteira Scamb
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">Comprando</h1>
              <p className="_topic-content">
                Você pode escolher muitas coisas legais nas diversas categorias
                do Scamb: roupas, kids, decoração, etc. Gostou de algo? Compre
                com seus pontos! Não tem suficiente? Calma, você não vai perder
                a oportunidade, é só comprar mais pontos para completar.
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">Pronto! Agora é só repetir.</h1>

            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">Qual o próximo passo?</h1>
              <p className="_topic-content">
                <Link to="/criar-conta" className="btn btn-primary">
                  Criar uma conta
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HowItWorks;
