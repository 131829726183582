import React from "react";

export const Timeline = (props) => {

    return (
        <div className="_order-timeline">

            {props.children}

        </div>
    )
}

export const Status = (props) => {

    return (
        <div className="_timeline-item">
            <div className="_col">
                <div className="icon">
                    {props.icon}
                </div>
                <div className="title">
                    {props.title}
                </div>
            </div>

            <div className="_col">
                <div className="date"> {props.date} </div>
                <div className="hour"> {props.hour} </div>
            </div>
        </div>
    )
}