import React, { useEffect } from "react";

// Components
import Footer from "../../components/Footer";

// Bootstrap
import { Accordion, Card, Button } from "react-bootstrap";

// Slider
import ImageGallery from "react-image-gallery";

// Material - UI
import { useMediaQuery } from "@material-ui/core";

// Modals
import ModalBase from "../../components/modals/ModalBase";
import ModalTaxs from "../../views/modals/ModalTaxs";

// Images
import logo from "../../assets/images/logo.png";
import image_hero_1 from "../../assets/images/institucional/landing-page/image1.png";
import image_hero_2 from "../../assets/images/institucional/landing-page/image2.png";
import image_scamb_1 from "../../assets/images/institucional/landing-page/scamb-crie.png";
import image_scamb_2 from "../../assets/images/institucional/landing-page/scamb-it.png";
import image_scamb_3 from "../../assets/images/institucional/landing-page/scamb-renove.png";
import mosaico from "../../assets/images/institucional/landing-page/mosaico.png";

// Images Banners
import banner_1_desk from "../../assets/images/institucional/landing-page/banner-01.png";
import banner_1_mobl from "../../assets/images/institucional/landing-page/banner-mobile-01.png";
import banner_2_desk from "../../assets/images/institucional/landing-page/banner-02.png";
import banner_2_mobl from "../../assets/images/institucional/landing-page/banner-mobile-crie.png";
import banner_3_desk from "../../assets/images/institucional/landing-page/banner-03.png";
import banner_3_mobl from "../../assets/images/institucional/landing-page/banner-mobile-03.png";

// Manifest Images
import manifest_1 from "../../assets/images/institucional/landing-page/manifesto/1.png";
import manifest_2 from "../../assets/images/institucional/landing-page/manifesto/2.png";
import manifest_3 from "../../assets/images/institucional/landing-page/manifesto/3.png";
import manifest_4 from "../../assets/images/institucional/landing-page/manifesto/4.png";
import manifest_5 from "../../assets/images/institucional/landing-page/manifesto/5.png";
import manifest_6 from "../../assets/images/institucional/landing-page/manifesto/6.png";
import manifest_7 from "../../assets/images/institucional/landing-page/manifesto/7.png";
import manifest_8 from "../../assets/images/institucional/landing-page/manifesto/8.png";
import manifest_9 from "../../assets/images/institucional/landing-page/manifesto/9.png";
import manifest_10 from "../../assets/images/institucional/landing-page/manifesto/10.png";
import manifest_11 from "../../assets/images/institucional/landing-page/manifesto/11.png";
import manifest_12 from "../../assets/images/institucional/landing-page/manifesto/12.png";
import manifest_13 from "../../assets/images/institucional/landing-page/manifesto/13.png";

import Helmet from 'react-helmet';
// Manifest Images
const images_manifest = [
  { original: manifest_1, thumbnail: manifest_1 },
  { original: manifest_2, thumbnail: manifest_2 },
  { original: manifest_3, thumbnail: manifest_3 },
  { original: manifest_4, thumbnail: manifest_4 },
  { original: manifest_5, thumbnail: manifest_5 },
  { original: manifest_6, thumbnail: manifest_6 },
  { original: manifest_7, thumbnail: manifest_7 },
  { original: manifest_8, thumbnail: manifest_8 },
  { original: manifest_9, thumbnail: manifest_9 },
  { original: manifest_10, thumbnail: manifest_10 },
  { original: manifest_11, thumbnail: manifest_11 },
  { original: manifest_12, thumbnail: manifest_12 },
  { original: manifest_13, thumbnail: manifest_13 },
];

function LandingPage() {

  const childTaxs = React.createRef();
  const manifest = React.createRef();

  // Breakpoint
  const matches = useMediaQuery("(min-width:600px)");

  const images = [
    {
      original: matches ? banner_1_desk : banner_1_mobl,
    },
    {
      original: matches ? banner_2_desk : banner_2_mobl,
    },
    {
      original: matches ? banner_3_desk : banner_3_mobl,
    },
  ];

  const params = {
    showThumbnails: false,
    showNav: false,
    showFullscreenButton: false,
    showPlayButton: false,
    infinite: true,
    thumbnailPosition: "bottom",
    showBullets: true,
    showNav: true,
    autoPlay: true,
    slideDuration: 450,
    slideInterval: 6000,
  };

  const params_manifest = {
    showThumbnails: false,
    showNav: false,
    showFullscreenButton: false,
    showPlayButton: false,
    infinite: true,
    thumbnailPosition: "bottom",
    showBullets: true,
    showNav: true,
    autoPlay: true,
    slideDuration: 450,
    slideInterval: 6000,
  };


  // Functions
  const openModalTaxs = () => {
    childTaxs.current.handleShow();
  };

  const openModalManifest = () => {
    manifest.current.handleShow();
  };

  useEffect(() => {
    
    // setTimeout(() => window.location.href = "/institucional/landing#como-funciona", 2000)

  }, [])

  return (
    <>
    <Helmet title="Scamb - Institucional" />
    <div id="view_landing-page">
      {/* HEADER */}
      <header className="header">
        {/* CONTAINER */}
        <div className="_med-container">
          {/* WRAP */}
          <div className="wrap-header">
            <div className="logo-and-icon">
              {/* Logo */}
              <div className="logo">
                <a href="../" className="d-block">
                  <img src={logo} alt="Scamb" />
                </a>
              </div>
              {/* END ./ Logo */}

              {/* Burger Icon */}
              <button className="i-burger">
                <span></span>
                <span></span>
                <span></span>
              </button>
              {/* END ./ Burger Icon */}
            </div>

            {/* Nav */}
            <div className="menu">
              <nav className="nav">
                <ul className="list">
                  
                  <li>
                    <a
                      href="#!"
                      className="_color _black _15px _font _comfortaa _fw700"
                      onClick={openModalManifest}
                    >
                      manifesto scamb
                    </a>
                  </li>
                  <li>
                    <a
                      href="#o-que-e"
                      className="_color _black _15px _font _comfortaa _fw700 sliding-link"
                    >
                      o que é
                    </a>
                  </li>
                  <li>
                    <a
                      href="#como-funciona"
                      className="_color _black _15px _font _comfortaa _fw700 sliding-link"
                    >
                      como funciona
                    </a>
                  </li>
                  <li>
                    <a
                      href="#!"
                      className="_color _black _15px _font _comfortaa _fw700"
                    >
                      blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="#inscreva-se"
                      className="_color _black _15px _font _comfortaa _fw700"
                    >
                      increva-se
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            {/* END ./ Nav */}
          </div>
          {/* END ./ WRAP */}
        </div>
        {/* END ./ CONTAINER */}
      </header>
      {/* END ./ HEADER */}

      <main className="main-content">
        {/* slider Desktop */}
        <ImageGallery {...params} items={images} />
        {/* END ./ Slider */}

        <section class="hero-1" id="o-que-e">
          <div class="_med-container">
            <div class="hero-content">
              <div class="column-left wow bounceInUp">
                <img src={image_hero_1} alt="Não é compra e venda" />
              </div>

              <div class="column-right wow bounceInUp">
                <h1 class="_color _nude _font _comfortaa _fw700">
                  Não é <br /> compra e venda
                </h1>
                <h2 class="_color _black _font _comfortaa _fw400">
                  porque queremos estimular o desapego, renovar a energia,
                  facilitar a circularidade. O que está parado na sua casa pode
                  se transformar em algo novo. Para o Scamb, as coisas têm
                  valor! E por isso aqui você não precisa usar dinheiro pra
                  comprar o que quiser! Aqui a moeda são coisas!
                </h2>

                <div class="main-btn">
                  <a href="#como-funciona" class="sliding-link">
                    <span>saiba mais</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="between-sessions">
          <div class="_med-container">
            <div class="main-btn">
              <a href="#como-funciona" class="sliding-link">
                <span>saiba mais</span>
              </a>
            </div>
          </div>
        </section>

        <section class="hero-2">
          <div class="_med-container">
            <div class="hero-content">
              <div class="column-left wow bounceInUp">
                <h1 class="_color _red _font _comfortaa _fw700">
                  Não é<br /> uma simples troca
                </h1>
                <h2 class="_color _black _font _comfortaa _fw400">
                  não tem que dar match... porque, como as pessoas são
                  diferentes, não podemos esperar que o casaco que você quer se
                  desfazer seja amado pelo dono do quadro que você gostaria de
                  ter!
                </h2>
                <div class="main-btn">
                  <a href="#como-funciona" class="sliding-link">
                    <span>saiba mais</span>
                  </a>
                </div>
              </div>

              <div class="column-right wow bounceInUp">
                <img src={image_hero_2} alt="Não é compra e venda" />
              </div>
            </div>
          </div>
        </section>

        <section class="it-works" id="como-funciona">
          <div class="_med-container">
            <h1 class="headline _color _blue _font _comfortaa _fw700 text-center">
              {" "}
              Como funciona{" "}
            </h1>
          </div>

          <div class="_med-container">
            {/* WORKS BOX #1 */}
            <div class="works-box">
              <div class="works-box-group flex">
                <div class="box-image">
                  <img src={image_scamb_1} alt="Scamb Crie" />
                </div>

                <div class="box-text">
                  <p class="_color _black _font _comfortaa _fw400">
                    <span class="first-letter">C</span>adastre-se na plataforma.
                    É super rápido e free. Escolha os itens que você quer
                    colocar para circular (não se esqueça de fazer fotos muito
                    legais). Você precifica a sua peça em reais e a plataforma
                    faz a conversão em pontos.
                  </p>
                </div>
              </div>
            </div>
            {/* END ./ WORKS BOX #1 */}

            {/* WORKS BOX #2 */}
            <div class="works-box">
              <div class="works-box-group flex reverse">
                <div class="box-image">
                  <img src={image_scamb_2} alt="Scamb It" />
                </div>

                <div class="box-text">
                  <p class="_color _black _font _comfortaa _fw400">
                    <span class="first-letter">V</span>ocê pode navegar pelo
                    Scamb e vender e comprar coisas bacanas, como em qualquer
                    site de compra e venda! A diferença é que aqui a moeda são
                    pontos!
                    <span class="white-space"></span>
                    Ah, e você pode se conectar com outros Scambers para
                    negociar preços, combinar a melhor forma de entrega etc!
                    Sempre que uma venda for finalizada, você receberá seus
                    pontos em sua conta Scamb para usá-los dentro da plataforma
                    !<span class="white-space"></span>
                    <a href="#!" onClick={openModalTaxs}>
                      Saiba mais aqui sobre valores.
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* END ./ WORKS BOX #2 */}

            {/* WORKS BOX #3 */}
            <div class="works-box">
              <div class="works-box-group flex t3">
                <div class="box-image">
                  <img src={image_scamb_3} alt="Scamb It" />
                </div>

                <div class="box-text">
                  <p class="_color _black _font _comfortaa _fw400">
                    <span class="first-letter">C</span>om os pontos da sua
                    venda, você pode comprar o que quiser! Encontre no acervo de
                    outros Scambers coisas incríveis e use seus pontos na
                    compra.
                    <span class="white-space"></span>
                    Se você não tem quantidade suficiente, calma... vc pode
                    comprar adicionais para completar. Ah, e também tem outras
                    formas de conseguir pontos.
                    <span class="white-space"></span>
                    <a href="#!">Saiba mais aqui!</a>
                  </p>
                </div>
              </div>
            </div>
            {/* END ./ WORKS BOX #3 */}
          </div>
        </section>

        <section
          class="faq wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
          id="faq"
        >
          <div class="_med-container">
            <h1 class="headline _color _blue _font _comfortaa _fw700 text-center _transf _upper">
              {" "}
              Faq{" "}
            </h1>

            <Accordion id="accordion">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    1 - Como crio a minha conta?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    Já já estaremos juntos, Scamber! Estamos trabalhando para
                    criar tudo do jeito que você deseja! Se inscreva para
                    receber todas as novidades e ser um dos primeiros a utilizar
                    o Scamb. Além disso, os primeiros Scambers ganharão pontos
                    pra usar na plataforma assim que ela for lançada!{" "}
                    <a href="#inscreva-se">Inscreva-se</a>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    2 - O que são os pontos?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    Os pontos são a moeda do Scamb. Nós acreditamos que as
                    coisas bacanas paradas em nossas casas têm valor! E no Scamb
                    elas valem pontos. Você ganha pontos quando cadastra seu
                    espaço, quando vende coisas, quando indica amigos...há
                    muitas maneiras de ganhar pontos. E depois você pode usá-los
                    para comprar coisas muito legais!{" "}
                    <a href="../">Clique aqui</a> e veja as promoções ativas pra
                    ganhar pontos!
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    3 - Quantos produtos posso cadastrar?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    Quantos produtos voce quiser! Na verdade, quanto mais
                    produtos você cadastrar, mais incentivos você receberá, e
                    mais chances de juntar pontos para comprar coisas incríveis
                    você terá!
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    4 - Quais produtos posso vender?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    Roupas, acessórios femininos e masculinos, tudo do universo
                    infantil, itens de casa e decoração… mas se você tem uma
                    coisa que acha muito bacana e que tem valor, mas não se
                    encaixa nessas categorias, calma! Temos a nossa categoria
                    “outros” e nela você pode cadastrar essas coisas! Nossa
                    curadoria vai ajudar nesse processo para garantir que a
                    gente tenha um acervo incrível para todos os Scambers!
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </section>

        <section class="mosaic">
          <div class="mosaic-image text-center">
            <img class="w-100" src={mosaico} alt="" />
          </div>
        </section>

        <section class="newsletter _bg-color _blue" id="inscreva-se">
          <div class="_med-container">
            <div class="newsletter-content">
              <h1 class="_color _white _font _comfortaa _fw700 text-center">
                Inscreva-se e receba notícias!
              </h1>

              <h2 class="_color _white _font _comfortaa _fw300 text-center">
                estamos trabalhando para criar uma plataforma incrível pra você!
                Mas mesmo antes de lançarmos, você já pode fazer parte deste
                movimento com a gente! Torne-se um Scamber desde já! Inscreva-se
                e acompanhe todas as novidades!
              </h2>

              {/* Begin Mailchimp Signup Form */}
              <div id="mc_embed_signup">
                <form
                  action="https://scamb.us4.list-manage.com/subscribe/post?u=461e5f1fcbec6418e8a7261bc&amp;id=a8d600c8bb"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  class="newsletter-form needs-validation validate"
                  target="_blank"
                  novalidate
                >
                  <div id="mc_embed_signup_scroll">
                    <div class="form-group mc-field-group">
                      <input
                        type="email"
                        name="EMAIL"
                        class="form-control email"
                        id="mce-EMAIL"
                        placeholder="Digite seu melhor email"
                        required
                      />
                    </div>
                    <div id="mce-responses" class="clear">
                      <div
                        class="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        class="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>{" "}
                    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_461e5f1fcbec6418e8a7261bc_a8d600c8bb"
                        tabindex="-1"
                        value=""
                      />
                    </div>
                    <div class="form-group clear">
                      <input
                        type="submit"
                        value="Me inscrever"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        class="button"
                      />
                    </div>
                  </div>
                </form>
              </div>
              {/*End mc_embed_signup*/}
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
    </div>
                      
      <ModalBase
        ref={childTaxs}
        modalTitle="Taxa Scamb"
        modalSubTitle={`
        Você não paga nada para listar produtos no Scamb e ter sua loja ativa.
        Porém, a cada transação efetivada, o Scamb cobra uma taxa de administração para apoiar a manutenção da plataforma.
        A taxa é sempre paga pelo COMPRADOR. Não há taxas para o vendedor.
        O valor da taxa Scamb varia de acordo com o valor de pontos da transação.
        Veja aqui a tabela de valores da taxa Scamb.
        `}
        >
        <ModalTaxs></ModalTaxs>
      </ModalBase>

      <ModalBase
        ref={manifest}
        modalTitle="Manifesto Scamb"
        modalSubTitle=""
        size="lg"
        className="manifest"
      >
        <div className="manifest">
          <ImageGallery {...params_manifest} items={images_manifest} />
        </div>
      </ModalBase>
    </>
  );
}

export default LandingPage;
