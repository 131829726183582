import React, { Component } from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop'

// Views
import Home from './views/Home';
import Product from './views/Product';
import BuyPoints from './views/BuyPoints';
import Buy from './views/Buy';
import Store from './views/Store';
import Help from './views/Help.jsx';
import MobileSearch from './views/MobileSearch';
import Results from './views/Results';
import TermsConditions from './views/TermsConditions';
import OfficialStores from './views/OfficialStores';
import HowItWorks from './views/HowItWorks';
import Welcome from './views/auth/Welcome';
import Login from './views/auth/Login';
import CreateAccount from './views/auth/CreateAccount';
import RecoverPassword from './views/auth/RecoverPassword';
import CreateAd from './views/CreateAd';
import ReviewProduct from './views/ReviewProduct';
import CompleteRegistration from './views/CompleteRegistration';
import MyAds from './views/account/MyAds';
import MyFavorites from './views/account/MyFavorites';
import MyPurchases from './views/account/MyPurchases';
import MySales from './views/account/MySales';
import MyProfile from './views/account/profile/MyProfile';
import Messenger from './views/account/Messenger/Messages';
import MyWallet from './views/account/MyWallet';
import ShoppingCart from './views/account/ShoppingCart';
import AddPaymentMethod from './views/account/AddPaymentMethod';
import InviteFriends from './views/account/InviteFriends';
import ActivityHistoryPage from './views/account/ActivityHistoryPage';

// Institucional
import LandingPage from "./institutional/landing-page/LandingPage"
import Institutional from "./institutional/"

// Googe analytics
import ReactGA from 'react-ga';
import config from './configs/config.json';

class Routes extends Component {

    constructor(props){
        super(props);

        ReactGA.initialize(config.gtag);
        ReactGA.pageview(window.location.pathname + window.location.search);

        props.history.listen(() => {
            ReactGA.pageview(window.location.pathname + window.location.search);
        });
    }
    
    render () {
        return (

            <ScrollToTop>
                <Switch>
                     {/* Páginas deslogadas */}
                    <Route exact path="/" component={Home} />
                    <Route exact path="/produto" component={Product} />
                    <Route exact path="/ajuda" component={Help} />
                    <Route exact path="/buscar" component={MobileSearch} />
                    <Route exact path="/resultados" component={Results} />
                    <Route exact path="/termos-e-condicoes" component={TermsConditions} />
                    <Route exact path="/lojas-oficiais" component={OfficialStores} />
                    <Route exact path="/como-funciona" component={HowItWorks} />
                    <Route exact path="/prosseguir" component={Welcome} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/criar-conta" component={CreateAccount} />
                    <Route exact path="/recuperar-senha" component={RecoverPassword} />
                    <Route exact path="/institucional/landing" component={LandingPage} />
                    <Route exact path="/sobre-o-scamb" component={Institutional} />
                    <Route exact path="/in/:inviteCode" component={CreateAccount} />

                    <Route exact path="/minha-sacola" component={ShoppingCart} />
                    
                    {/* Páginas logadas */}        
                    <PrivateRoute exact path="/comprar-pontos" component={BuyPoints} />
                    <PrivateRoute exact path="/comprar" component={Buy} />
                    <PrivateRoute exact path="/criar-novo" component={CreateAd} />
                    <PrivateRoute exact path="/editar-anuncio/:idAnuncio" component={CreateAd} />
                    {/*<PrivateRoute exact path="/revisar-anuncio" component={ReviewProduct} />*/}
                    <PrivateRoute exact path="/completar-cadastro" component={CompleteRegistration} />
                    <PrivateRoute exact path="/meus-anuncios" component={MyAds} />
                    <PrivateRoute exact path="/minhas-vendas" component={MySales} />
                    <PrivateRoute exact path="/meus-favoritos" component={MyFavorites} />
                    <PrivateRoute exact path="/minhas-compras" component={MyPurchases} />
                    <PrivateRoute exact path="/meu-perfil" component={MyProfile} />
                    <PrivateRoute exact path="/mensagens" component={Messenger} />
                    <PrivateRoute exact path="/minha-carteira" component={MyWallet} />
                    <PrivateRoute exact path="/metodo-de-pagamento" component={AddPaymentMethod} />
                    <PrivateRoute exact path="/convidar-amigos" component={InviteFriends} />
                    <PrivateRoute exact path="/historico-de-atividades" component={ActivityHistoryPage} />
                            

                    {/* Logado e Deslogado */}
                    <Route exact path="/loja/:storeUrl" component={Store} />
                </Switch>
            </ScrollToTop>

        )
    
    }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => localStorage.getItem('token') !== null ? 
        (<Component {...props} />) : (<Redirect to={{pathname: "/login"}}/>)}
    />
);


export default withRouter(Routes);