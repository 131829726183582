import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert";
import Swal2 from "sweetalert2";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

// Modals
import ModalBase from "../components/modals/ModalBase";
import ModalTaxs from "./modals/ModalTaxs";
import ModalShippingOptions from "./modals/ModalShippingOptions";

// Icons
import {
  faQuestionCircle,
  faInfoCircle,
  faTruck,
  faMobile,
  faHandHoldingBox,
  faCheck,
  faEdit,
} from "@fortawesome/pro-light-svg-icons";

//Images
import Ad from "./../services/Ad";

import { connect } from "react-redux";
import { FormGroup } from "@material-ui/core";

const params = {
  showNav: false,
  showFullscreenButton: false,
  showPlayButton: false,
  infinite: false,
  thumbnailPosition: "bottom",
  showBullets: true,
};

class ReviewProduct extends Component {
  constructor(props) {
    super(props);
    this.childTaxs = React.createRef();
    this.childShippingOptions = React.createRef();
    this.state = {
      slider: null,
      sliderThumbs: null,
    };
  }

  componentDidMount() {
    this.setState({
      slider: this.featuredSlider,
      sliderThumbs: this.sliderThumbnail,
    });
  }

  openModalTaxs = () => {
    this.childTaxs.current.handleShow();
  };

  openModalShippingOptions = () => {
    this.childShippingOptions.current.handleShow();
  };

  confirmAd = () => {
    const ad = new Ad();

    let adInfo = this.props.ad;
    //delete adInfo.selectedImages;

    const formDataAd = new FormData();
    formDataAd.append("ad", JSON.stringify(adInfo));

    this.props.ad.selectedImages.map((image, index) => {
      if (image !== 0) {
        formDataAd.append("image" + (index + 1), image.file);
      }
    });

    const idAnuncio = this.props.match.params.idAnuncio;

    if (idAnuncio) {
      //Editando
      Swal2.fire("Atualizando anúncio ...Aguarde");
      Swal2.showLoading();
      ad.update(formDataAd, idAnuncio)
        .then((response) => {
          let context = this;

          Swal2.close();

          Swal({
            title: "Anúncio atualizado!",
            text:
              "Seu anúncio foi atualizado e enviado para nossa curadoria. \n Em até 48 horas ele será publicado.",
            icon: "success",
            buttons: ["Fechar", "Cadastrar outro"],
            dangerMode: true,
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = "/criar-novo";
              //context.props.history.push("/criar-novo");
            } else {
              context.props.history.push("/");
            }
          });
        })
        .catch((error) => {
          Swal2.close();
          Swal({
            type: "info",
            html: ad.formatErrorMessage(error.response),
            title: "",
          });
        });
    } else {
      //Inserindo novo
      Swal2.fire("Inserindo anúncio ...Aguarde");
      Swal2.showLoading();
      ad.insert(formDataAd)
        .then((response) => {
          let context = this;

          Swal2.close();

          Swal({
            title: "Anúncio adicionado!",
            text:
              "Seu anúncio foi enviado para nossa curadoria. \n Em até 48 horas ele será publicado.",
            icon: "success",
            buttons: ["Fechar", "Cadastrar outro"],
            dangerMode: true,
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = "/criar-novo";
              //context.props.history.push("/criar-novo");
            } else {
              context.props.history.push("/");
            }
          });
        })
        .catch((error) => {
          Swal2.close();
          Swal({
            type: "info",
            html: ad.formatErrorMessage(error.response),
            title: "",
          });
        });
    }
  };

  render() {
    return (
      <div id="view_product" className="review-product">
        <div className="_alert-top">Revise seu anúncio</div>

        <div className="layout-main">
          <div className="layout-col layout-col-left">
            <div className="_wrap-product-gallery">
              <ImageGallery
                {...params}
                items={this.props.ad?.selectedImages.reduce(
                  (list, image, index) => {
                    if (image !== 0) {
                      list.push({
                        original: image.show,
                        thumbnail: image.show,
                      });
                    }

                    return list;
                  },
                  []
                )}
              />
            </div>
          </div>

          <div className="layout-col layout-col-right">
            <section className="_short-description">
              <Link
                to="/"
                className="small-link-store _color _gray _12px _fw700"
              >
                {this.props.scamber? 
                  this.props.scamber.minha_loja? 
                    this.props.scamber.minha_loja.titulo
                    :'Lojinha Em Construção' 
                    :'Lojinha Em Construção'
                }
              </Link>

              <div className="item-title">
                <h1
                  className="item-title--primary _color _black _fw400"
                  style={{ wordWrap: "break-word" }}
                >
                  {this.props.ad?.title}
                </h1>
              </div>

              <div className="item-price">
                <div className="_product-price _2x">
                  {Number(this.props.ad?.pricePointsOriginal) >
                  Number(this.props.ad?.pricePoints) ? (
                    <div className="ui-item__discount-price">
                      <span className="price-tag-fraction">
                        {this.props.ad?.pricePointsOriginal}
                      </span>
                      <span className="price-tag-symbol">pts</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="ui-item__price">
                    <span className="price-tag-fraction">
                      {this.props.ad?.pricePoints}
                    </span>
                    <span className="price-tag-symbol">pts</span>
                  </div>
                </div>
              </div>

              <div className="tax-scamb">
                <div className="tax-scamb--title _color _gray _12px">
                <strong className="_fw700">Taxa Scamb</strong> <span className="_11px">a ser paga pelo comprador</span> 
                  <span className="_13px _fw700">
                    R${this.props.ad?.shippingTax}
                  </span>
                  <button
                    className="btn btn-transparent"
                    onClick={this.openModalTaxs}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                  </button>
                </div>
              </div>

              <div className="shipping-options">
                <div className="shipping-options--title _color _gray _12px">
                  Opções de frete disponíveis
                  <button
                    className="btn btn-transparent"
                    onClick={this.openModalShippingOptions}
                  >
                    <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                  </button>
                </div>

                <div className="shipping-options-items">
                  <div
                    className={`shipping-options-item ${
                      this.props.ad?.deliveryTypes.operador_logistico
                        ? "_active"
                        : ""
                    }`}
                  >
                    <div className="item-icon">
                      <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
                    </div>
                    <div className="item-info">Correios</div>
                  </div>

                  {/*<div className={`shipping-options-item ${this.props.ad?.deliveryTypes.aplicativo_entrega? "_active": ""}`}>
                    <div className="item-icon">
                      <FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>
                    </div>
                    <div className="item-info">Aplicativo de entrega</div>
                  </div>*/}

                  <div
                    className={`shipping-options-item ${
                      this.props.ad?.deliveryTypes.entrega_maos ? "_active" : ""
                    }`}
                  >
                    <div className="item-icon">
                      <FontAwesomeIcon
                        icon={faHandHoldingBox}
                      ></FontAwesomeIcon>
                    </div>
                    <div className="item-info">Entrega em mãos</div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="layout-main-informations _med-container">
          <div className="layout-col layout-col-left">
            <section
              className="_long-description"
              style={{ wordWrap: "break-word" }}
            >
              <h1 className="_long-descriptionr-title _title _color _black _fw700">
                Descrição
              </h1>

              {/*<p className="_long-description-text _color _black _fw600">
                short azul e branco confeccionado em jeans, o modelo possui
                fecho de botões vista dupla, dois bolsos frontais e dois
                posteriores, barra desfiada e estampa de listras.
                <br />
                <br />
                composição: algodão
                <br />
                <br />
                medidas: cintura: 80.0cm comprimento: 29.0cm quadril: 98.0cm
                </p>*/}

              {this.props.ad?.description}
            </section>

            <section className="_characteristics">
              <h1 className="_characteristics-title _title _color _black _fw700">
                Características
              </h1>

              <ul className="_characteristics-list">
                <li>
                  <div className="_key">marca</div>
                  <div className="_value">
                    {this.props.ad?.brand
                      ? this.props.ad.brand.nome
                      : this.props.ad.customBrand}
                  </div>
                </li>
                <li>
                  <div className="_key">Condição</div>
                  <div className="_value">{this.props.ad?.condition.nome}</div>
                </li>
                <li>
                  <div className="_key">Categoria</div>
                  <div className="_value">
                    {this.props.ad?.allCategorizationLabel}
                  </div>
                </li>
                {this.props.ad?.size ? (
                  <li>
                    <div className="_key">Tamanho</div>
                    <div className="_value">{this.props.ad?.size.nome}</div>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </section>
          </div>
        </div>

        <div className="_confirm-publish">
          <div className="_wrapper">
            <div className="_wrap-confirm-publish">
              <div className="_col left">
                <div className="_product-image">
                  <div className="_wrap-image">
                    <img
                      src={
                        this.props.ad
                          ? this.props.ad.selectedImages[0].show
                          : ""
                      }
                    />
                  </div>
                  {/* <div className="tag-weight">g</div> */}
                </div>
                <div className="_wrap-info">
                  <div className="product-title">{this.props.ad?.title}</div>
                  <div className="product-category">
                    {this.props.ad?.allCategorizationLabel}
                  </div>
                </div>
              </div>

              <div className="_col right">
                <div className="item-price">
                  <div className="_product-price _2x">
                    <div className="ui-item__price">
                      <span className="price-tag-fraction">
                        {this.props.ad?.pricePoints}
                      </span>
                      <span className="price-tag-symbol">pts</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="_confirm-publish-actions">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.confirmAd}
              >
                Ok <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
              </button>
              <button
                onClick={(e) => {
                  this.props.setIsEditing(true);
                  this.props.setReturnNextPage(true);
                }}
                type="button"
                className="btn btn-cancel"
              >
                Editar
                <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
              </button>
            </div>
          </div>
        </div>

        {/* Modal Taxas Scamb */}
        <ModalBase
          ref={this.childTaxs}
          modalTitle="Taxa Scamb"
          modalSubTitle="
          Você não paga nada para listar produtos no Scamb e ter sua loja ativa.
          Porém, a cada transação efetivada, o Scamb cobra uma taxa de administração para apoiar a manutenção da plataforma.
          A taxa é sempre paga pelo COMPRADOR. Não há taxas para o vendedor.
          O valor da taxa Scamb varia de acordo com o valor de pontos da transação.
          Veja aqui a tabela de valores da taxa Scamb.
				"
        >
          <ModalTaxs></ModalTaxs>
        </ModalBase>

        <ModalBase
          ref={this.childShippingOptions}
          modalTitle="Opções de entrega"
          modalSubTitle="Qual a melhor opção para mim?"
        >
          <ModalShippingOptions></ModalShippingOptions>
        </ModalBase>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ad: state.adReducer.ad,
    scamber: state.scamberReducer.scamber
  };
};

export default withRouter(connect(mapStateToProps)(ReviewProduct));
