import Service from './Service';

export default class Authentication extends Service {

  constructor(){
    super();
  }

  doLogin( userAndPassword ) {
    return this.axiosInstance.post(`/auth/local`, userAndPassword);
  }

  doFacebookLogin(inviteCode){
    window.location.href = `${this.server}/auth/facebook${inviteCode? '?inviteCode=' + inviteCode: ''}`;
  }

  confirmFacebookLogin( tokenFacebook ){
    return this.axiosInstance.get(`/auth/facebook/callback?code=${tokenFacebook}`);
  }

  isAuthenticated() {
    return this.axiosInstance.get(`/auth/isAuthenticated`);
  }

  doLogout() {
    return this.axiosInstance.get(`/auth/logout`);
  }
  

}
