import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";

// Icons
import {
  faChevronDown,
  faChevronUp,
  faCreditCardBlank,
  faUser,
  faCalendarStar,
  faCalendarAlt,
  faLockAlt,
} from "@fortawesome/pro-light-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

import $ from "jquery";
import "jquery-mask-plugin";

// Images
import Visa from "../../../assets/images/credit-card/visa.png";
import Mastercard from "../../../assets/images/credit-card/mastercard.png";
import AmericanExpress from "../../../assets/images/credit-card/americanExpress.png";
import DinersClub from "../../../assets/images/credit-card/DinersClub.png";
import Swal from 'sweetalert2';
import InputMask from "react-input-mask";

// Services
import CreditCardServices from '../../../services/CreditCard';


class CreditCard extends Component {
  state = { 
    toggle_icon: true,
    cards:'',
    creditCardType:'',
    numeroCartao:'',
    nome:'',
    mes:'',
    ano:'',
    cvv:''
 };

  componentDidMount(){
    this.loadCards();
  }

  handlePointsInfo = () => {
    this.setState({
      toggle_icon: !this.state.toggle_icon,
    });
  };

  loadCards = () => {
    let creditCardServices = new CreditCardServices();
    creditCardServices.find().then((response)=>{
      
      if(response.status === 200){
        this.setState({cards: response.data.payload})
      }

    }).catch (( error ) =>{
      if(!error.response.status === 404) {
        Swal.fire({type:'info', html: creditCardServices.formatErrorMessage(error.response), title:''}) 
      } else {
        this.setState({cards: [] })
      }
    })
  }

  setNumeroCartao = (e) => {this.setState({numeroCartao:e.target.value})};
  setNome = (e) => {this.setState({nome:e.target.value})};
  setMes = (e) => {this.setState({mes:e.target.value})};
  setAno = (e) => {this.setState({ano:e.target.value})};
  setCVV = (e) => {this.setState({cvv:e.target.value})}; 

  deleteCreditCard = (card) => {
    console.log(card)
    swal({
        title: "Deletar Cartão?",
        text: `Nome: ${card.nome} \n Final: **** ${card.numero} \n Expira em: ${card.data_expiracao}`,
        icon: "warning",
        buttons: ["cancelar", "Confirmar"],
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          let creditCardServices = new CreditCardServices();
          creditCardServices.delete(card.id).then((response)=>{

            if(response.status === 200){
              swal(`Seu cartão ${card.bandeira} Final: **** ${card.numero} foi Deletado`, {icon: "success",});
              this.loadCards();
            }
              

          }).catch (( error ) => Swal.fire({type:'info', html: creditCardServices.formatErrorMessage(error.response), title:''}));

          
        }
      });
  };

  checkCreditCardType = (e) =>{

    let fieldSize = e.target.value.replace(' ','').replace(/[^0-9]+/g,'').length;
    let cardnumber = e.target.value.replace(' ','').replace(/[^0-9]+/g,'');
    let emptyFields = 16 - cardnumber.length;
    let zeros = "";
    for(let count = 0; count < emptyFields; count++){
      zeros += "0"
    }

      var cards = {
        visa           : /^4[0-9]{12}(?:[0-9]{3})/,
        mastercard     : /^5[1-5][0-9]{14}/,
        DinersClub     : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        americanExpress: /^3[47][0-9]{13}/,
      };

      // discover       : /^6(?:011|5[0-9]{2})[0-9]{12}/,
      // hipercard      : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      // elo            : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      // jcb            : /^(?:2131|1800|35\d{3})\d{11}/,       
      // aura           : /^(5078\d{2})(\d{2})(\d{11})$/     


      for (var flag in cards) {
          if(cards[flag].test(parseInt((cardnumber + zeros)))) {
            return this.setState({creditCardType:flag});
          }
      }       

      return this.setState({creditCardType:null});

  }

  onSubmit = (e, card) =>{
    e.preventDefault();

    let creditCardServices = new CreditCardServices();

    let formattedObject = {}

    formattedObject["bandeira"]      = this.state.creditCardType? this.state.creditCardType:"none";
    formattedObject["numero_cartao"] = this.state.numeroCartao;
    formattedObject["nome"]          = this.state.nome.toUpperCase();
    formattedObject["mes_expiracao"] = this.state.mes;
    formattedObject["ano_expiracao"] = this.state.ano;
    formattedObject["cvv"]           = this.state.cvv

    creditCardServices.update(formattedObject,card.id).then((response)=>{
      Swal.showLoading();
      Swal.fire('Aguarde...')
      if(response.status === 200){

        Swal.fire({
          icon: "success",
          title: "Cartão editado!",
          timer: 4000,
          timerProgressBar: true,
          html:
            "Cartão editado com sucesso.",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "fechar",
        })
        
        this.loadCards();

      }
    }).catch (( error ) => Swal.fire({type:'info', html: creditCardServices.formatErrorMessage(error.response), title:''}));

  }

  render() {
    return (
      <div>
        {this.state.cards? this.state.cards.map((card,index)=>{
          return(
            <Accordion className="_add-payment-accordion _accordion">
              <Card>
                <Card.Header onClick={this.handlePointsInfo}>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    <div className="_wrap-current-cc">
                      <div className="_current-cc-info">
                        <div className="_cc-flag">
                          <div className="_cc-flag-icon">
                            <img src={require(`../../../assets/images/credit-card/${card.bandeira}.png`)} alt="Flag credit card"/>
                          </div>
                          {index === 0? <span className="_tag">principal</span>:<></>}
                          
                        </div>

                        <div className="_cc-info">
                          <div className="_cc-number-prev">
                            {card.bandeira.charAt(0).toUpperCase() + card.bandeira.slice(1)} (<span className="_cc-number">**** {card.numero}</span>)
                          </div>
                          <div className="_cc-exp-date">Expira em: {card.data_expiracao}</div>
                          <div className="_cc-name">{card.nome}</div>
                        </div>
                      </div>

                      <button type="button" className="btn _delete-card" onClick={()=>this.deleteCreditCard(card)}>
                        Excluir cartão
                      </button>
                    </div>

                    <button
                      type="button"
                      className="btn btn-transparent toggle-arrow"
                    >
                      <FontAwesomeIcon
                        icon={this.state.toggle_icon ? faChevronDown : faChevronUp}
                      ></FontAwesomeIcon>
                    </button>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <section className="_update-cc">
                      <section className="_update-cc-informations">
                        <h1 className="_update-cc-title">
                          Atualizar informações do cartão de crédito
                        </h1>

                        <ul className="_payment-methods-list">
                          <li>
                            <div className="creadit-card--flag">
                              <img src={Visa} alt="" />
                            </div>
                          </li>
                          <li>
                            <div className="creadit-card--flag">
                              <img src={Mastercard} alt="" />
                            </div>
                          </li>
                          <li>
                            <div className="creadit-card--flag">
                              <img src={AmericanExpress} alt="" />
                            </div>
                          </li>
                          <li>
                            <div className="creadit-card--flag">
                              <img src={DinersClub} alt="" />
                            </div>
                          </li>
                        </ul>

                        <form onSubmit={(e)=>{this.onSubmit(e,card)}} className="_form">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                            {
                                this.state.creditCardType?
                                <span class="input-group-text" id="cc-number">
                                    <img style={{width:"15.75px"}} src={require(`../../../assets/images/credit-card/${this.state.creditCardType}.png`)} alt="Flag credit card" />
                                </span>
                                :
                                <span class="input-group-text" id="cc-number">
                                  <FontAwesomeIcon
                                    icon={faCreditCardBlank}
                                  ></FontAwesomeIcon>
                                </span>
                              }
                            </div>
                            <InputMask
                              mask="9999 9999 9999 9999"
                              type="text"
                              class="form-control"
                              placeholder={`**** **** **** ${card.numero}`}
                              id="cartao_credito"
                              onInput={this.checkCreditCardType}
                              onChange={this.setNumeroCartao}
                              value={this.state.numeroCartao}
                              onPaste={this.checkCreditCardType}
                              aria-describedby="cc-number"
                            />
                          </div>

                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="cc-name">
                                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              onChange={this.setNome}
                              placeholder={`${card.nome}`}
                              value={this.state.nome}
                              aria-describedby="cc-name"
                              style={{ textTransform: 'uppercase' }}
                            />
                          </div>

                          <div className="_row">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="cc-month">
                                  <FontAwesomeIcon
                                    icon={faCalendarStar}
                                  ></FontAwesomeIcon>
                                </span>
                              </div>
                              <input
                                maxLength="2"
                                type="text"
                                class="form-control"
                                placeholder={`${card.data_expiracao.substring(0,2)}`}
                                onChange={this.setMes}
                                value={this.state.mes}
                                aria-describedby="cc-month"
                              />
                            </div>

                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="cc-year">
                                  <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                  ></FontAwesomeIcon>
                                </span>
                              </div>
                              <input
                                maxLength="4"
                                type="text"
                                class="form-control"
                                placeholder={`${card.data_expiracao.substring(3,7)}`}
                                onChange={this.setAno}
                                value={this.state.ano}
                                aria-describedby="cc-year"
                              />
                            </div>

                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="cc-ccv">
                                  <FontAwesomeIcon icon={faLockAlt}></FontAwesomeIcon>
                                </span>
                              </div>
                              <input
                                maxLength="3"
                                type="text"
                                class="form-control"
                                placeholder={`***`}
                                onChange={this.setCVV}
                                value={this.state.cvv}
                                aria-describedby="cc-cvv"
                              />
                            </div>
                          </div>

                          <div className="form-group-button">
                            <button type="submit" className="btn btn-primary">
                              Salvar
                            </button>
                          </div>
                        </form>
                      </section>

                      <section className="_update-address">
                        <div className="_set-user-location">
                          <h1 className="_user-location-title">
                            Endereço de cobrança
                          </h1>

                          <div className="_wrap-location">
                            <div className="_icon">
                              <FontAwesomeIcon
                                icon={faMapMarkerAlt}
                              ></FontAwesomeIcon>
                            </div>

                            <div className="_wrap-current-location">
                            <div className="_location-title">CEP: {card.endereco.cep}</div>
                              <div className="_location">
                              {card.endereco.logradouro}, nº{card.endereco.numero} - {card.endereco.bairro},
                              {card.endereco.cidade} - {card.endereco.estado}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*<Link className="link-button" to="/">
                          Alterar endereço de cobrança
                        </Link>*/}

                      </section>
                    </section>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )
        }):<></>}
      </div>
    );
  }
}

export default CreditCard;
