import React from "react";

export const Price = (props) => {

    return (
        <div className={"_price real "+ props.size}>
            <div className="ui-item__price">
                <span class="price-tag-fraction"> {props.value} </span>
                <span class="price-tag-symbol">r$</span>
            </div>
        </div>
    )

};

export const PricePoints = (props) => {

    return (
        <>
        <div className={"_price points "+ props.size}>
            <div className="ui-item__price">
                <span class="price-tag-fraction">{props.value}</span>
                <span class="price-tag-symbol">pts</span>
            </div>
        </div>
        </>
    )

};