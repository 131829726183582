import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import SideMenu from './SideMenu';
import SideMenuCategories from './SideMenuCategories';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import $ from 'jquery';
import EventEmitter from './../services/Events';

// Icons
import { faHome, faSearch, faPlusSquare, faQuestionCircle, faUserCircle, faInbox } from '@fortawesome/pro-light-svg-icons';

//Imagens
import Avatar from '../assets/images/avatar.png';

import { connect } from 'react-redux';
import Scamber from './../services/Scamber';

class FixedMenu extends Component {

	constructor() {
		super();
	
		this.state = {};
		this.scamber = new Scamber();
	}

	componentDidMount() {

		this.setState({ isAuthenticated: localStorage.getItem('token')? true: false });
	
		EventEmitter.subscribe('loginSucess', () => {
			this.setState({ isAuthenticated: true });
		});

		EventEmitter.subscribe('logoutSucess', () => {
			this.setState({ isAuthenticated: false });
		});

		let current = null;
		let fixedMenu = $("._ui-fixed-menu-items");

		$(fixedMenu).on('click', '._ui-fixed-menu-item', function () {

			$(this).parents(fixedMenu).find('._active').removeClass('_active').end().end().addClass('_active');

		});

	}

	render() {
		
		return (
			<>
            <SideMenu className="_aside-menu-user _is-hidden"></SideMenu>
			<SideMenuCategories className="_aside-menu-categories _is-hidden"></SideMenuCategories>
            
			{/* Barra usuário logado */}
			{this.state.isAuthenticated? 
				(<div id="_ui-fixed-menu--wrapper">
					<div className="_ui-fixed-menu-items">
						<div className="_ui-fixed-menu-item _active">
							<Link to="./" className="">
								<FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
							</Link>
						</div>
						<div className="_ui-fixed-menu-item">
							<Link to="/buscar" className="">
								<FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
							</Link>
						</div>
						<div className="_ui-fixed-menu-item">
							<Link to="/criar-novo" className="">
								<FontAwesomeIcon icon={faPlusSquare}></FontAwesomeIcon>
							</Link>
						</div>
						<div className="_ui-fixed-menu-item">
							<Link to="/mensagens" className="">
								<span className="message-box">
									<FontAwesomeIcon icon={faInbox} />
									{/*<span className="_notification-icon-mark _red"></span>*/}
								</span>
							</Link>
						</div>
						<div className="_ui-fixed-menu-item">
							<button type="button" className="btn btn-transparent _color _black _mobile-nav-user">
								<span className="user-image _rounded-image__with-notification-icon _1x">
								{this.props.scamber? this.props.scamber.imagens?.avatar? (<img src={this.scamber.loadImage(this.props.scamber.imagens?.avatar)} alt="Avatar" />):
                      				(<img src={Avatar} alt="Avatar" />):<></>}
									{/*<span className="_notification-icon-mark _red"></span>*/}
								</span>
							</button>
						</div>
					</div>
				</div>):''
			}

			{/* Barra usuário deslogado */}
			{!this.state.isAuthenticated?
				(<div id="_ui-fixed-menu--wrapper">
					<div className="_ui-fixed-menu-items">
						<div className="_ui-fixed-menu-item _active">
							<Link to="./" className="">
								<FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
							</Link>
						</div>
						<div className="_ui-fixed-menu-item">
							<Link to="/buscar" className="">
								<FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
							</Link>
						</div>
						<div className="_ui-fixed-menu-item">
							<Link to="/criar-novo" className="">
								<FontAwesomeIcon icon={faPlusSquare}></FontAwesomeIcon>
							</Link>
						</div>
						<div className="_ui-fixed-menu-item">
							<Link to="/mensagens" className="">
								<span className="message-box">
									<FontAwesomeIcon icon={faQuestionCircle} />
									<span className="_notification-icon-mark _red _is-hidden"></span>
								</span>
							</Link>
						</div>
						<div className="_ui-fixed-menu-item">
							<Link to="/mensagens" className="">
								<span className="message-box">
									<FontAwesomeIcon icon={faUserCircle} />
								</span>
							</Link>
							<button type="button" className="btn btn-transparent _color _black _mobile-nav-user _is-hidden">
								<span className="user-image _rounded-image__with-notification-icon _1x">
									<img src={Avatar} alt="Avatar" />
									<span className="_notification-icon-mark _red"></span>
								</span>
							</button>
						</div>
					</div>
				</div>):''
			}
			</>
		);
		
	};
	
};

const mapStateToProps = state => {
	return {
		scamber: state.scamberReducer.scamber
	}
}

export default connect(mapStateToProps)(FixedMenu);