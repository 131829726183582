
//===========================================================
// #1 - Base Imports
//===========================================================
import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";

//===========================================================
// #2 - Import * Account Layout Base
//===========================================================
import Layout from "../../components/account/Layout";

//===========================================================
// #3 - Import * Sweet Alert
//===========================================================
import Swal2 from "sweetalert2";
import Swal from "sweetalert";

//===========================================================
// #4 - Import * React Hook Form
//===========================================================
import { useForm } from "react-hook-form";

//===========================================================
// #5 - Import * { Button } Bootstrap
//===========================================================
import { Button } from "react-bootstrap";

//===========================================================
// #6 - Import * UI Components
//===========================================================
import { Price, PricePoints } from "../../components/ui/price";
import {
  Accordion,
  AccordionCard,
  AccordionInside,
  AccordionContent,
} from "../../components/ui/accordion";
import { ListContent, ListItem } from "../../components/ui/list-content";
import { ProductThumbnail } from "../../components/ui/product-thumbnail";
import { Timeline, Status } from "../../components/ui/order-timeline";
import { Checkbox } from "../../components/ui/checkbox";
import { StatusMessage } from "../../components/ui/status-message";
import { GreenList, GreenListItem } from "../../components/ui/green-list";
import { BoxPrint } from "../../components/ui/box-print";
import { CurrentPage } from "../../components/ui/current-page";

//===========================================================
// #7 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faTruck, faThumbsUp, faCheck, faCommentAltLines, faChevronRight, faChevronLeft, faTimes, faBox, faPrint, faShippingTimed, faUserClock } from "@fortawesome/pro-light-svg-icons";

//===========================================================
// #8 - Import * Material UI Components
//===========================================================
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Slide,
  Typography,
} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useTheme } from "@material-ui/core/styles";

import Helmet from 'react-helmet';


//===========================================================
// #9 - Import * Images
//===========================================================
const image =
  "https://photos.enjoei.com.br/public/1200xN/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy8xMDY1NTY1My8yZGU4MzgwY2MwODc4MTMwZDE2YjIzMTFmMzc1NjgzMy5qcGc";

const image_2 =
  "https://photos.enjoei.com.br/public/1200xN/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy81OTAzMjM2LzgwZTU1OGY2ZTI0N2FlZWYxNjk2YmIxOTI3NjYwOTA1LmpwZw";

const image_3 =
  "https://photos.enjoei.com.br/public/1200xN/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy82NjY2Njk0LzFiNDRiODAzZjMwNDk4N2U2NTEwZDIzMTExNjRkZGU3LmpwZw";

const image_4 =
  "https://photos.enjoei.com.br/public/1200xN/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy8xMDY1NTY1My9jYzBhNDVkZjc0N2E3NDUyMDdhMjNmNWNmYWEwZDQ2Zi5qcGc";

const image_5 =
  "https://photos.enjoei.com.br/public/1200xN/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy83OTc2NjMvZDg3MDQxNmRmOGJhNDY1YjY3ODcwOGIxNWMwZmVlMTQuanBn";


//===========================================================
// #10 - Create Material UI Modal Dialog
//===========================================================
const ModalDialog = forwardRef((props, ref) => {

  // * Material UI Styles - useMediaQuery
  const matches = useMediaQuery("(min-width:600px)");

  // # Define States
  const [open, setOpen] = React.useState(false);

  // * Material UI Styles - useTheme
  const theme = useTheme();

  // # Define Material UI Modal Dialog Fullscreen on mobile 
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  
  // # Define Functions

  // * Set Material UI Modal Dialog Open
  const handleClickOpen = () => {
    setOpen(true);
  };

  // * Set Material UI Modal Dialog Close
  const handleClose = () => {
    setOpen(false);
  };

  // * Set Function Using useImperativeHandle();
  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  // * Capture Event onSubmit Form with
  // * React Hook Forms
  const onSubmit = (data) => {

    // * Close Material UI Modal Dialog After Submit 
    setOpen(false);

    // * If Submit Success, Show Sweet Alert Dialog 
    Swal.fire({
      icon: "success",
      title:
        "Proposta enviada!",
        text: `Sua proposta foi enviada e, assim que o vendedor responder, mandaremos uma notificação para você!`,
      timer: 6000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "fechar",
    }).then((eComplete) => {
      if (eComplete) {
        console.log("ok");
      }
    });
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <Icon
                icon={matches ? faTimes : faChevronLeft}
              ></Icon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
          <DialogContent className={props.className}>
            <Typography component={"span"} variant={"body2"}>
              <div className="subtitle">
                {props.subTitle}
              </div>

              {props.children}
              
            </Typography>
          </DialogContent>
      </Dialog>
    </>
  );
});

// * Material UI Dialog Slide Transition Effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


//===========================================================
// #11 - Material UI Set Panel
//===========================================================
const TabPanel = (props) => {

  const { children, value, index, ...other } = props;

  return (

    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          <>
            {children}
          </>
      )}
    </div>

  )
} 

function MySales () {

    // * States
    const [tabValue, setTabValue] = useState(0);
    const [buttonState, setButtonState] = useState({accordion: true, buttonConfirm: true, buttonVitrine: false, buttonCancelSale: true });

    // * Material UI Styles - useMediaQuery
    const matches = useMediaQuery("(min-width:600px)");

    // * Create React UI Dialog Refs
    const refModalPrintLabel  = useRef();
    const refModalCancelProduct     = useRef();

    //* React Hook Forms
    const { register, handleSubmit, errors, formState } = useForm({ mode: 'onChange'});

    const openDialogModal  = (refModal) => refModal.current.openModal();
    const closeDialogModal = (refModal) => refModal.current.closeModal();


    // * Capture Event onSubmit Form with
    // * React Hook Forms
    // * Form - Submit Cancel Product Form
    const onSubmitCancelSale = (data) => {

      closeDialogModal(refModalCancelProduct);

      Swal({
        title: "Confirmar o cancelamento?",
        text: `Ao clicar em confirmar, a sua venda será
        cancelada e seu produto voltará ativo
        com o preço normal para sua loja.` ,
        icon: "warning",
        buttons: ["Cancelar", "Confirmar"],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          
          Swal({
            title: "Tudo pronto!",
            text: `Fique atendo a sua caixa de email.
            Em até 12 horas entraremos em
            contato com o status do cancelamento.`,
            icon: "success",
            buttons: ["Ok", false],
            dangerMode: true,
          });

          setButtonState({ buttonCancelSale: false });
  
        } else {
          Swal.close();
        }
      });

    }

    // * UI Modal Dialog Close Return Product
    const handleClosePrintLabel = () => {
      closeDialogModal(refModalPrintLabel);
    }

    // * Swal Confirm Product Receipt
    const handleProductReceipt = () => {

      Swal({
        title: "Confirmar recebimento?",
        text: `Ao clicar em confirmar você afirma que
        recebeu o produto enviado pelo comprador.` ,
        icon: "warning",
        buttons: ["Cancelar", "Confirmar"],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          
          Swal({
            title: "Confirmado!",
            text: `Agradecemos a sua confirmação.`,
            icon: "success",
            buttons: ["Ok", false],
            dangerMode: true,
          }).then(function (isConfirm) {

            if(!isConfirm) {
              Swal({
                title: "Publicar produto?",
                text: `Ao clicar em confirmar seu produto irá ficar disponível novamento para negociação. Seu produto voltará para a sua loja com o valor original.`,
                icon: "warning",
                buttons: ["Cancelar", "Confirmar"],
                dangerMode: true,
              }).then(function (isConfirm) {

                if(isConfirm) {
                  Swal({
                    title: "Confirmado!",
                    text: `Seu produto foi publicado novamente um sua loja.`,
                    icon: "success",
                    buttons: ["Ok", false],
                    dangerMode: true,
                  });
                  
                  /*
                    - Estado inicial de {buttonState.accordion} é {true}.
                    - Caso o usuário confirme que recebeu o produto o estado de
                    - {buttonState.accordion} é marcado como {false} e esconde o
                    - componente <AccordionInside />
                  */
                  setButtonState({ accordion: false });
                  
                  
                } else {
                  /*
                    - Caso o usuário clique em cancelar o estado do "Botão: Sim! recebi o produto!" -> {buttonState.buttonConfirm}
                    -  é marcado como {false} e ele é escondido do accordion.
                    - logo após o estado do "Botão: Vitrine" -> {buttonState.buttonVitrine} é alterado para {true}
                    - e ele é exibido na tela até o usuário clicar e optar por colocar o produto novamente na vitrine.
                  */
                  setButtonState({ accordion: true, buttonConfirm: false, buttonVitrine: true  });
                  Swal.close();
                }

              })
            }

          })

        } else {
          Swal.close();
        }
      });

    }

    // * Swall Publish Product
    const handleButtonVitrine = () => {

      Swal({
        title: "Publicar produto?",
        text: `Ao clicar em confirmar seu produto irá ficar disponível novamento para negociação. Seu produto voltará para a sua loja com o valor original.`,
        icon: "warning",
        buttons: ["Cancelar", "Confirmar"],
        dangerMode: true,
      }).then(function (isConfirm) {

        if(isConfirm) {
          Swal({
            title: "Confirmado!",
            text: `Seu produto foi publicado novamente um sua loja.`,
            icon: "success",
            buttons: ["Ok", false],
            dangerMode: true,
          });
          
          /*
            - Estado inicial de {buttonState.accordion} é {true}.
            - Caso o usuário confirme que recebeu o produto o estado de
            - {buttonState.accordion} é marcado como {false} e esconde o
            - componente <AccordionInside />
            *
            - Clicando em confirmar o estado do "Botão: Vitrine" -> {buttonState.buttonVitrine} é alterado para {false}
            - e esconde o botão do accortion de ações.
          */
          setButtonState({ accordion: false, buttonVitrine: false });
          
          
        } else {
          /*
            - Caso o usuário clique em cancelar o estado do "Botão: Sim! recebi o produto!" -> {buttonState.buttonConfirm}
            -  é marcado como {false} e ele é escondido do accordion.
            - logo após o estado do "Botão: Vitrine" -> {buttonState.buttonVitrine} é alterado para {true}
            - e ele é exibido na tela até o usuário clicar e optar por colocar o produto novamente na vitrine.
          */
          Swal.close();
        }

      })
      
    }


    // * Material UI Tabs
    // * Props Tab
    function tabProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    // * Material UI Tabs
    // * On Change Tab
    const handleChange = (event, newValue) => {
      setTabValue(newValue);
    };


    return (
      <>
      <Helmet title="Scamb - Minhas vendas" />


      <ModalDialog ref={refModalCancelProduct} dialogTitle="Cancelar a venda" subTitle="Selecione o motivo do cancelamento da venda abaixo." className="_modal-action-product">

      <form className="_form-modal-product" onSubmit={handleSubmit(onSubmitCancelSale)} >

        <Checkbox title="Me arrependi da venda" alignCenter={true}>
          <input type="radio" id="reason_1" name="reason" ref={register({ required: true })} />
        </Checkbox>

        <Checkbox title="Não tenho o produto disponível" alignCenter={true}>
          <input type="radio" id="reason_2" name="reason" ref={register({ required: true })} />
        </Checkbox>

        <Checkbox title="O comprador se arrependeu" alignCenter={true}>
          <input type="radio" id="reason_3" name="reason" ref={register({ required: true })} />
        </Checkbox>

        <Checkbox title="Tive problemas com o envio" alignCenter={true}>
          <input type="radio" id="reason_4" name="reason" ref={register({ required: true })} />
        </Checkbox>

        <Checkbox title="Tive problemas com a transportadora" alignCenter={true}>
          <input type="radio" id="reason_4" name="reason" ref={register({ required: true })} />
        </Checkbox>

        {errors.reason && <StatusMessage type="error" messageText="Você deve selecionar um dos tópicos acima antes de prosseguir." />}

        <Button
          className="btn-submit"
          color="primary"
          autoFocus
          style={{ height: matches ? 35 : 40, justifyContent: `center` }}
          type="submit"
          disabled={ !formState.dirty || (formState.dirty && !formState.isValid) }
        >
          Continuar
          <Icon icon={faChevronRight}></Icon>
        </Button>

      </form>

      </ModalDialog>

      <ModalDialog ref={refModalPrintLabel} dialogTitle="Impressão de etiqueta" subTitle="Faça a emissão da etiqueta" className="_modal-action-product">

        <div className="_body-modal">

          <BoxPrint type="info" icon={<Icon icon={faBox} />}
                    title="Clique no botão abaixo para realizar
                    a emissão da etiqueta.">
                    <Button
                      className="btn-submit"
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 45 : 55 }}
                      type="button"
                    >
                      Emitir etiqueta
                      <Icon icon={faPrint}></Icon>
                    </Button>
          </BoxPrint>

        </div>

      <Button
          className="btn-submit"
          color="primary"
          autoFocus
          style={{ height: matches ? 35 : 40, justifyContent: `center` }}
          type="submit"
          onClick={handleClosePrintLabel}
        >
          Finalizar
          <Icon icon={faChevronRight}></Icon>
        </Button>
      </ModalDialog>

      <Layout>
        <div id="my-sales" className="_content">

        <div className="_wrap-title-and-tabs">

          <CurrentPage icon={<Icon icon={faShoppingCart} />} title="Minhas Vendas" />

          <Tabs value={tabValue} onChange={handleChange} aria-label="Tabs My Sales" className="_material-tabs">
            <Tab label="Pendentes" {...tabProps(0)} />
            <Tab label="Aguardando envio" {...tabProps(1)} />
            <Tab label="Concluídas" {...tabProps(2)} />
          </Tabs>
        </div>

          {/* 
           /* Material UI Tab Panel - Em Aberto
          */}
          <TabPanel value={tabValue} index={0}>

              <Accordion
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image}
                      title="tênis cinza escuro mary jane"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faShippingTimed} />} title="Aguardando devolução" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                </ListContent>

                {/*

                  - Estado inicial de {buttonState.buttonConfirm} é {true}.
                  - Caso o usuário confirme que recebeu o produto o estado de
                  - {buttonState.buttonConfirm} é marcado como {false} e esconde o
                  - componente <AccordionInside />

                */}

                {buttonState.accordion && (
                <AccordionInside title="Ações" open={true}>
                  <AccordionContent bgColor="#F9F9F9">

                    {buttonState.buttonConfirm && (
                    <ListItem title="Você recebeu o seu pedido?">
                      <button type="button" className="btn-received" onClick={handleProductReceipt}>
                        Sim! recebi o produto
                        <span>
                          <Icon icon={faThumbsUp} />
                        </span>
                      </button>
                    </ListItem>
                    )}

                    {buttonState.buttonVitrine && (
                      <button type="button" className="small-button" onClick={handleButtonVitrine}>
                        Solicitar etiqueta
                        <Icon icon={faChevronRight} />
                      </button>
                    )}

                  </AccordionContent>
                </AccordionInside>
                )}
              </Accordion>

          </TabPanel>
          
          {/* 
           /* Material UI Tab Panel - Aguardando envio
          */}
          <TabPanel value={tabValue} index={1}>

              <Accordion
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image_3}
                      title="macaquinho vermelho"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Tipo de entrega" className="flex">
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faUserClock} />} title="Pendente de envio" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                  <ListItem title="Chat">
                    <button type="button" className="small-button">
                      Falar com o vendendor
                      <Icon icon={faCommentAltLines} />
                    </button>
                  </ListItem>
                </ListContent>

                <AccordionInside title="Ações" open={true}>
                  <AccordionContent bgColor="#F9F9F9">

                    <button type="button" className="small-button" onClick={() => openDialogModal(refModalPrintLabel)}>
                      Solicitar etiqueta
                      <Icon icon={faChevronRight} />
                    </button>

                    {buttonState.buttonCancelSale && (
                    <button type="button" className="small-button" onClick={() => openDialogModal(refModalCancelProduct)}>
                      Cancelar a venda
                      <Icon icon={faChevronRight} />
                    </button>
                    )}

                  </AccordionContent>
                </AccordionInside>

              </Accordion>

              <Accordion
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image_4}
                      title="melissa sneaker + fila"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Taxa em reais">
                    <Price value="10,00" />
                  </ListItem>

                  <ListItem title="Frete" className="flex">
                    <Price value="17,50"/>
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faCheck} />} title="Disponível" date="27/10/2020" hour="16h07"></Status>
                      <Status icon={<Icon icon={faCheck} />} title="Pagamento realizado" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                </ListContent>

                <AccordionInside title="Ações" open={true}>
                  <AccordionContent bgColor="#F9F9F9">

                    <button type="button" className="small-button" onClick={() => openDialogModal(refModalPrintLabel)}>
                      Solicitar etiqueta
                      <Icon icon={faChevronRight} />
                    </button>

                    {buttonState.buttonCancelSale && (
                    <button type="button" className="small-button" onClick={() => openDialogModal(refModalCancelProduct)}>
                      Cancelar a venda
                      <Icon icon={faChevronRight} />
                    </button>
                    )}

                  </AccordionContent>
                </AccordionInside>

              </Accordion>

              <Accordion
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image_5}
                      title="scrunchie xadrez preto e branca"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Taxa em reais">
                    <Price value="10,00" />
                  </ListItem>

                  <ListItem title="Frete" className="flex">
                    <Price value="17,50"/>
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faCheck} />} title="Disponível" date="27/10/2020" hour="16h07"></Status>
                      <Status icon={<Icon icon={faCheck} />} title="Pagamento realizado" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                </ListContent>

                <AccordionInside title="Ações" open={true}>
                  <AccordionContent bgColor="#F9F9F9">

                    <button type="button" className="small-button" onClick={() => openDialogModal(refModalPrintLabel)}>
                      Solicitar etiqueta
                      <Icon icon={faChevronRight} />
                    </button>

                    {buttonState.buttonCancelSale && (
                    <button type="button" className="small-button" onClick={() => openDialogModal(refModalCancelProduct)}>
                      Cancelar a venda
                      <Icon icon={faChevronRight} />
                    </button>
                    )}

                  </AccordionContent>
                </AccordionInside>

              </Accordion>

          </TabPanel>


          {/* 
           /* Material UI Tab Panel - Concluídas
          */}
          <TabPanel value={tabValue} index={2}>

              {/* 
              /* productStatus possui 3 estados:
              /* productStatus="delivered"
              /* productStatus="canceled"
              /* productStatus="returned"
              */}

              <Accordion
                productStatus="delivered"
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image_3}
                      title="macaquinho vermelho"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Frete" className="flex">
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  <ListItem title="Endereço da entrega">
                    Rua Alameda Ministro Rocha Azevedo, Nº 912,
                    Bairro: Jardim Paulista, São Paulo/SP, CEP: 01410-002
                  </ListItem>

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faCheck} />} title="Concluído" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                  <ListItem title="Comprador">
                    <div className="_wrap-user">

                      <span className="user-image _rounded-image__with-notification-icon _2x">
                        <img src="https://via.placeholder.com/150x150" alt="Avatar" />
                      </span>
                      <div className="_username">
                        Ana vieira
                      </div>
                    </div>
                  </ListItem>

                  <ListItem title="Chat">
                    <button type="button" className="small-button">
                      Falar com o vendendor
                      <Icon icon={faCommentAltLines} />
                    </button>
                  </ListItem>

                </ListContent>

              </Accordion>

              <Accordion
                productStatus="canceled"
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image_4}
                      title="melissa sneaker + fila"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Frete" className="flex">
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  <ListItem title="Endereço da entrega">
                    Rua Alameda Ministro Rocha Azevedo, Nº 912,
                    Bairro: Jardim Paulista, São Paulo/SP, CEP: 01410-002
                  </ListItem>

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faCheck} />} title="Concluído" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                  <ListItem title="Comprador">
                    <div className="_wrap-user">

                      <span className="user-image _rounded-image__with-notification-icon _2x">
                        <img src="https://via.placeholder.com/150x150" alt="Avatar" />
                      </span>
                      <div className="_username">
                        Ana vieira
                      </div>
                    </div>
                  </ListItem>

                  <ListItem title="Chat">
                    <button type="button" className="small-button">
                      Falar com o vendendor
                      <Icon icon={faCommentAltLines} />
                    </button>
                  </ListItem>

                  </ListContent>

              </Accordion>

              <Accordion
                productStatus="returned"
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image_5}
                      title="scrunchie xadrez preto e branca"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Frete" className="flex">
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  <ListItem title="Endereço da entrega">
                    Rua Alameda Ministro Rocha Azevedo, Nº 912,
                    Bairro: Jardim Paulista, São Paulo/SP, CEP: 01410-002
                  </ListItem>

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faCheck} />} title="Concluído" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                  <ListItem title="Comprador">
                    <div className="_wrap-user">

                      <span className="user-image _rounded-image__with-notification-icon _2x">
                        <img src="https://via.placeholder.com/150x150" alt="Avatar" />
                      </span>
                      <div className="_username">
                        Ana vieira
                      </div>
                    </div>
                  </ListItem>

                  <ListItem title="Chat">
                    <button type="button" className="small-button">
                      Falar com o vendendor
                      <Icon icon={faCommentAltLines} />
                    </button>
                  </ListItem>

                  </ListContent>

              </Accordion>

          </TabPanel>

       </div>
      </Layout>
      </>
    );
  
}

export default MySales;
