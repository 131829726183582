import React, { Component } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/account/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faUserFriends } from "@fortawesome/pro-light-svg-icons";
import { connect } from 'react-redux';
import config from '../../configs/config.json';

import Helmet from 'react-helmet';

class InviteFriends extends Component {

  constructor(){
    super();
    this.state = {
      // inviteUrl:''
    }
  }

  componentWillMount(){
    if(!this.state.inviteUrl && this.props.scamber){
      if(config.nodejs.server.indexOf('localhost') !== -1){
        this.setState({inviteUrl: `http://localhost:3000/in/${this.props?.scamber._id}`})
      }else if(config.nodejs.server.indexOf('/dev') !== -1){
        this.setState({inviteUrl: `https://d1.scamb.com.vc/in/${this.props?.scamber._id}`})
      }else{
        this.setState({inviteUrl: `https://scamb.com.vc/in/${this.props?.scamber._id}`})
      }
    }
  }

  componentDidUpdate(nextProps){
    if(nextProps.scamber !== this.props.scamber){
      if(config.nodejs.server.indexOf('localhost') !== -1){
        this.setState({inviteUrl: `http://localhost:3000/in/${this.props?.scamber._id}`})
      }else if(config.nodejs.server.indexOf('/dev') !== -1){
        this.setState({inviteUrl: `https://d1.scamb.com.vc/in/${this.props?.scamber._id}`})
      }else{
        this.setState({inviteUrl: `https://scamb.com.vc/in/${this.props?.scamber._id}`})
      }
    }
  }

  copyText = () =>{
    let textArea = document.createElement("textarea");
    let inviteLink = document.getElementById("inviteLink");
    let oldValue = inviteLink.textContent;

    textArea.textContent = inviteLink.value;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy')
    textArea.remove();
  }

  render() {
    return (
      <div id="view_invite-friends">
        <Helmet title="Scamb - Convidar amigos" />
        <Layout>
          <div id="invite-friends">
            <h1 className="_title-box">
              <span>
                <FontAwesomeIcon icon={faUserFriends} />
              </span>
              Programa de indicação
            </h1>

            <ul className="_list-info">
              <li>
                <div className="key">
                  Link de compartilhamento
                  <div id="inviteLink" className="val link">
                    {this.state.inviteUrl}
                    <button onClick={this.copyText} type="button" className="btn">
                      <FontAwesomeIcon icon={faClone}></FontAwesomeIcon>
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <div className="key">
                  Usuários cadastrados com seu link
                    <div className="val">{this.props?.scamber?.convidados? this.props?.scamber?.convidados.length : ''}</div>
                </div>
              </li>
              <li>
                <div className="key">
                  Pontos Recebidos:
                  <div className="_product-price _1x">
                    <div className="ui-item__price">
                      <span class="price-tag-fraction">0</span>
                      <span class="price-tag-symbol">pts</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      scamber: state.scamberReducer.scamber
  }
}

export default connect(mapStateToProps)(InviteFriends);
