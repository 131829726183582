import React, {
  Component,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Breadcrumb from "../components/Breadcrumbs";
import ReactDOM from 'react-dom';
import ModalBase from "../components/modals/ModalBase";
import ModalTaxs from "./modals/ModalTaxs";

import 'bootstrap/dist/css/bootstrap.css';

import helpText from './helpText';

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faMegaphone,
  faSearch,
  faRocketLaunch,
  faHandshakeAlt,
  faChevronLeft,
  faTimes,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";

// Material - UI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Slide,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import $ from "jquery";
import Helmet from 'react-helmet';

// Styles
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
});

// First Li Item
const FirstLi = forwardRef((props, ref) => {

  const [panel, setPanel] = useState(false);

  const handleClickOpen = () => {
    setPanel(true);
  };

  const handleClose = () => {
    setPanel(false);
  };

  useImperativeHandle(ref, () => ({
    openListItem: () => handleClickOpen(),
    closeListItem: () => handleClose(),
  }));

  const handlePanel = () => {
    setPanel(!panel);
  };

  return (
    <>
    <li id={props.id}>
      <a className={panel ? "_item active" : "_item"} onClick={handlePanel}>
        <div className="_item-name">{props.list_title}</div>
      </a>
      {panel ? <>{props.children}</> : null}
    </li>
    </>
  )

})

// Modal Dialog Base
const ModalDialog = forwardRef((props, ref) => {
  // Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  // Material ui Modal
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <FontAwesomeIcon
                icon={matches ? faTimes : faChevronLeft}
              ></FontAwesomeIcon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
        <DialogContent className={props.className}>
          <Typography component={"span"} variant={"body2"}>
            {props.children}
          </Typography>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
});

// Material ui Dialog Slide
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AccordionList(props) {

  return (
    <Accordion className="faq-accordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{props.title}</Typography>

      </AccordionSummary>
      <AccordionDetails>
        <Typography>{props.children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

function Li(props) {
  const [panel, setPanel] = useState(false);

  const handlePanel = () => {
    setPanel(!panel);
  };

  return (
    <li id={props.id}>
      <a className={panel ? "_item active" : "_item"} onClick={handlePanel}>
        <div className="_item-name">{props.list_title}</div>
      </a>
      {panel ? <>{props.children}</> : null}
    </li>
  );
}

class Help extends Component {

  constructor(props) {
    super(props);
    this.childTaxs1 = React.createRef();
    this.childTaxs = React.createRef();
    this.refFirstLi = React.createRef();
    this.pointsExpire = React.createRef();
    this.state = {
      filterText:'',
      content: helpText(this.openModalPoints),
      perguntasERespotasFiltradas:[]
    }
  }

  openFirstItem = () => {
    setTimeout(() => {
      this.refFirstLi.current.openListItem();
    },600)
  }
  
  openModalTaxs1 = () => {
    this.childTaxs1.current.handleShow();
  };
  
  openModalTaxs = () => {
    this.childTaxs.current.handleShow();
  };

  openModalPoints = () => {
    this.pointsExpire.current.handleShow();
  };

  componentDidMount(){
    window.openModalPoints = this.openModalPoints;
    window.openModalTaxs1 = this.openModalTaxs1;
    window.openModalTaxs = this.openModalTaxs;
  }

  filter = (e) => {
    e.preventDefault();
    this.setState({filterText: this.state.filterText})
    
    let perguntasERespotasFiltradas = [];
    

    this.state.content.titulo.map((titulo,index)=>{
    let topicos = titulo.topicos.filter((topico) =>{ 
        
        if(topico.pergunta.toLowerCase().trim().indexOf(this.state.filterText.toLowerCase().trim()) !== -1) return topico;
        if(topico.resposta.toLowerCase().trim().indexOf(this.state.filterText.toLowerCase().trim()) !== -1) return topico;
        
      });      

      let tituloFiltrado = Object.assign({},titulo);
      tituloFiltrado.topicos = topicos;
      perguntasERespotasFiltradas.push(tituloFiltrado);
      this.setState({perguntasERespotasFiltradas:perguntasERespotasFiltradas})
    })

    window.scrollTo({
      top: 400,
      behavior: 'smooth',
    })

  }

  render() {
    // Ref Modals
    const refModalDialogAbout = React.createRef();
    const refModalDialogStart = React.createRef();
    const refModalDialogPoints = React.createRef();

    return (
      <section id="view_help">
        <Helmet title="Scamb - Ajuda" />
        <Breadcrumb></Breadcrumb>

        

        <ModalDialog dialogTitle="Sobre o Scamb" ref={refModalDialogAbout}>
          <p className="_paraf-help-modal">
            <br/>
            O Scamb é uma comunidade em forma de plataforma que une pessoas por
            um desejo de consumir diferente, um consumo mais soft, que promove a
            circularidade de "coisas". No Scamb as coisas que você já possui se
            tornam pontos e com eles você pode adquirir um monte de coisas
            incríveis dos outros Scambers.
          </p>
          <br />
          <p className="_paraf-help-modal">
            O Scamb traz o assunto segunda mão de forma inovadora:
            <br />
            -É uma evolução no conceito de trocas, as coisas se convertem em
            pontos Scamb.
            <br />
            -Tem uma comunicação direta, sem surpresas ou taxas escondidas.
            <br />
            -Promove a transparência e respeito aos outros Scambers.
          </p>
        </ModalDialog>

        <ModalDialog
          dialogTitle="Começando no Scamb"
          className=""
          ref={refModalDialogStart}
        >
          <p className="_paraf-help-modal">
            <br/>
            Cadastre-se na plataforma. É super rápido e free. Escolha os itens
            que você quer colocar para circular (não se esqueça de fazer fotos
            muito legais). Você precifica a sua peça em reais e a plataforma faz
            a conversão em pontos.
          </p>
          <br />
          <p className="_paraf-help-modal">
            Você pode navegar pelo Scamb e vender e comprar coisas bacanas, como
            em qualquer site de compra e venda! A diferença é que aqui a moeda
            são pontos! Ah, e você pode se conectar com outros Scambers para
            negociar preços, combinar a melhor forma de entrega etc! Sempre que
            uma venda for finalizada, você receberá seus pontos em sua conta
            Scamb para usá-los dentro da plataforma !
          </p>
          <br />
          <p className="_paraf-help-modal">
            Com os pontos da sua venda, você pode comprar o que quiser! Encontre
            no acervo de outros Scambers coisas incríveis e use seus pontos na
            compra. Se você não tem quantidade suficiente, calma... vc pode
            comprar adicionais para completar. Ah, e também tem outras formas de
            conseguir pontos.
          </p>
        </ModalDialog>

        <ModalDialog
          dialogTitle="Pontos"
          className=""
          ref={refModalDialogPoints}
        >
          <p className="_paraf-help-modal">
          Quando criamos o Scamb, queríamos achar uma forma de resgatar o conceito de que as coisas que possuímos têm valor e que não é preciso dinheiro para se adquirir coisas legais.<br/><br/>
          Não queríamos que o Scamb fosse apenas uma simples plataforma de compra e venda porque temos como propósito criar um movimento, estimular a renovação e facilitar a circularidade. Também não queríamos que o Scamb fosse uma simples plataforma de troca, porque uma troca pressupõe que haja o match entre o desejo e gosto do comprador e do vendedor, o que eh muito dificil de acontecer porque as pessoas são diferentes.<br/><br/>
          Além disso, nosso objetivo desde o início foi trazer uma solução diferente das que já existem hoje. Assim, criamos o conceito dos pontos, que resolve todas as questões citadas acima. Os pontos eliminam a necessidade do uso de dinheiro para que coisas bacanas sejam adquiridas.
          </p>
          <br />
        </ModalDialog>

        <div className="_med-container">
          <section className="_wrap-help-search">
            <h1 className="_title">Com qual tema você quer ajuda?</h1>

            <div className="_help-search">
              {/* Search form */}
              <div className="search-menu">
                <form onSubmit={(e)=> this.filter(e)} className="form">
                  <div className="form-group search">
                    <input
                      type="text"
                      placeholder="Digite o que esta buscando"
                      value={this.state.filterText}
                      onChange={(e)=> this.setState({filterText: e.target.value}) }
                    />
                    <button type="submit">
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="fa-question-circle"
                      />
                    </button>
                  </div>
                </form>
              </div>
              {/* END ./ Search form */}
            </div>
          </section>
          
          <section className="_help-boxes">
            <div className="_help-box">
              <div className="_box-title">
                Sobre o Scamb
                <button type="button" className="btn btn-transparent _disabled">
                  <FontAwesomeIcon icon={faMegaphone}></FontAwesomeIcon>
                </button>
              </div>

              <div className="_box-content">
                <p className="_text">
                  Uma plataforma para transações de itens de segunda mão, onde
                  coisas valem pontos que servem como moeda de compra de outras
                  coisas.
                </p>
              </div>

              <a
                href="#!!"
                onClick={() => refModalDialogAbout.current.openModal()}
              >
                Saiba mais
              </a>
            </div>

            <div className="_help-box">
              <div className="_box-title">
                Começando no Scamb
                <button type="button" className="btn btn-transparent _disabled">
                  <FontAwesomeIcon icon={faRocketLaunch}></FontAwesomeIcon>
                </button>
              </div>

              <div className="_box-content">
                <p className="_text">
                  Participar do Scamb é rápido e free! Veja como aqui.
                </p>
              </div>

              <a
                href="#!!"
                onClick={() => refModalDialogStart.current.openModal()}
              >
                Saiba mais
              </a>
            </div>

            <div className="_help-box">
              <div className="_box-title">
                Pontos
                <button type="button" className="btn btn-transparent _disabled">
                  <FontAwesomeIcon icon={faHandshakeAlt}></FontAwesomeIcon>
                </button>
              </div>

              <div className="_box-content">
                <p className="_text">
                  Os pontos são a moeda no Scamb. Eles eliminam a necessidade do
                  uso de dinheiro para que coisas bacanas sejam adquiridas.
                </p>
              </div>

              <a href="#!!" 
              onClick={() => refModalDialogPoints.current.openModal()}
              >Saiba mais
              </a>
            </div>
          </section>

          <section className="_help-content">
            <h1 id="topicos" className="_title">Escolha um tópico</h1>

            <div className="_wrap-topics">
              <div className="_topics">
                <ul className="_topics-list">

                {this.state.perguntasERespotasFiltradas.length > 0?
                  this.state.perguntasERespotasFiltradas.map((titulos)=>
                  <div>
                    {/* CARREGANDO A LINHA SÓ SE TIVER FILHOS ENCONTRADOS  */}
                    {titulos.topicos.length > 0 &&       
                        <Li id="titulo" list_title={titulos.nome}>
                          {titulos.topicos.map((topico)=>
                            <AccordionList title={topico.pergunta}>
                              <div dangerouslySetInnerHTML={ {__html: topico.resposta} } />
                            </AccordionList>
                          )}
                        </Li>
                      }
                    </div>
                  )
                :
                  this.state.content.titulo.map((titulos)=>
                    <React.Fragment>
                      <Li id="titulo" list_title={titulos.nome}>
                        {titulos.topicos.map((topico)=>
                          <AccordionList title={topico.pergunta}>
                            <div dangerouslySetInnerHTML={ {__html: topico.resposta} } />
                          </AccordionList>
                        )}
                      </Li>
                    </React.Fragment>
                  )
                }
                
                
                </ul>
              </div>
            </div>
          </section>
        </div>
        {/* Modal Taxas Scamb */}
        <ModalBase
          ref={this.childTaxs1}
          modalTitle="Pontos e Taxas"
          modalSubTitle="Você não paga nada para listar produtos no Scamb e ter sua loja ativa.
          Porém, a cada transação efetivada, o Scamb cobra uma taxa de administração para apoiar a manutenção da plataforma.
          A taxa é sempre paga pelo COMPRADOR. Não há taxas para o vendedor.
          O valor da taxa Scamb varia de acordo com o valor de pontos da transação. Veja aqui a tabela de valores da taxa Scamb."
          className=""
        >
          <ModalTaxs></ModalTaxs>

        </ModalBase>
        {/* Modal Taxas Scamb */}
        <ModalBase
          ref={this.childTaxs}
          modalTitle="Pontos e Taxas"
          modalSubTitle=""
          className="modal-taxs"
        >
          <ul className="_taxs-list">
                <li>
                    <div className="_taxs-list-title">Quantidade de pontos transferidos</div>
                    <div className="_taxs-list-value">
                        <div className="_value">Taxa transferência (R$)</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">1 - 99 pontos</div>
                    <div className="_taxs-list-value">
                        <div className="_value">3,90</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">100 - 199 pontos</div>
                    <div className="_taxs-list-value">
                        <div className="_value">5,90</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">200 - 499 pontos</div>
                    <div className="_taxs-list-value">
                        <div className="_value">9,90</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">500 - 799 pontos</div>
                    <div className="_taxs-list-value">
                        <div className="_value">12,90</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">800 - 999 pontos</div>
                    <div className="_taxs-list-value">
                        <div className="_value">14,90</div>
                    </div>
                </li>
                <li>
                    <div className="_taxs-list-title">{">"} 1.000 pontos (*)</div>
                    <div className="_taxs-list-value">
                        <div className="_value">19,90</div>
                    </div>
                </li>
            </ul>
        </ModalBase>

        <ModalBase
          ref={this.pointsExpire}
          modalTitle="Pontos expiram?"
          modalSubTitle=""
          className="modal-taxs"
        >
          <div className="_13px _fw700" style={{padding: '0 15px 30px 15px'}}>

          Sim, mas de formas diferentes conforme abaixo:<br/>
          Pontos adquiridos pela venda de produtos na plataforma têm a validade de 2 anos, a contar da data da aquisição dos pontos.
          <br/><br/>
          Pontos comprados na plataforma têm a validade de 2 anos, a contar da data da compra dos pontos.
          <br/><br/>
          Pontos bonificados (pontos recebidos como bônus em promoções e campanhas Scamb) têm a validade de 4 meses a contar da data do recebimento dos pontos.
          <br/><br/>
          Pontos transferidos mantém a validade original da data da aquisição dos pontos.
          </div>
        </ModalBase>
        
      </section>
    );
  }
}

export default Help;
