import config from '../configs/config.json';
import axios from 'axios';
import Swal from 'sweetalert';

class Service {

    constructor(){
        this.server = config.nodejs.server;
    
        this.axiosInstance = axios.create({
          withCredentials: true,
          baseURL: config.nodejs.server
        })

        // Add a response interceptor
        this.axiosInstance.interceptors.response.use(function (response) {
          // Any status code that lie within the range of 2xx cause this function to trigger
          // Do something with response data
          return response;
        }, function (error) {
          
          if(error.response.status === 401 && window.location.pathname !== "/login"){
            
            window.location.href = "/login"
            localStorage.removeItem('token');
            this.setState({ isAuthenticated: false })
 
          }

          return Promise.reject(error);
        });
    }

    loadImage(imageCode) {
      return `${config.nodejs.server}/images/${imageCode}`;
    }

    loadImageFile(imageCode) {
      return (fetch(this.loadImage(imageCode))
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], imageCode + '.webp',{type:"image/webp"});})
      );
    }

    formatErrorMessage(response) {
      if(response){
        if(response.data) {
          if(response.data.payload) {
            if(response.data.payload.errors) {
              const errors = response.data.payload.errors;
              return errors.reduce((reducer, element, index) => {
                return reducer += `<li>${element.msg}</li>${errors.length == (index)? '</ul>':'' }`;
              }, "Você precisa corrigir algumas informações: <ul>");
            } else {
              return response.data.message;
            }
          } else {
            return response.data.message;
          }
        } else {
          return "Não foi possísvel executar a ação, por favor, tente novamente mais tarde."
        } 
      }else{
        return "Não foi possível executar a ação."
      }
     
    } 

}


export default Service;