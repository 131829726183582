//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

//=====================================================================================
// #2 - Import * Sweet Alert 2
//=====================================================================================
import Swal from "sweetalert2";

//=====================================================================================
// #2 - Import * Imput Mask
//=====================================================================================
import InputMask from "react-input-mask";

//=====================================================================================
// #2 - Import * React Hook Form
//=====================================================================================
import { useForm } from "react-hook-form";

//=====================================================================================
// #8 - Import * Countdown Timer
//=====================================================================================
import Timer from "react-compound-timer";

//=====================================================================================
// #8 - Import * Material UI Components
//===================================================================================== 
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  useMediaQuery,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";


//=====================================================================================
// #2 - Import * Boostrap Modals
//=====================================================================================
import { Modal } from "react-bootstrap";
import ModalBase from "../components/modals/ModalBase";
import ModalShippingOptions from "../views/modals/ModalShippingOptions";
import ModalTaxs from "../views/modals/ModalTaxs";

//=====================================================================================
// #2 - Import * UI Components
//=====================================================================================
import { AdvertiserCard } from "../components/ui/advertiser-card";
import { Price, PricePoints } from "../components/ui/price";
import { ListContent, ListItem } from "../components/ui/list-content";
import { ProductThumbnail } from "../components/ui/product-thumbnail";
import { CurrentPage } from "../components/ui/current-page";
import { SelectBox, SelectBoxExpand } from "../components/ui/select-box";
import { BoxInfo } from "../components/ui/box-info";

//=====================================================================================
// #3 - Import * FontAwesome Icons
//=====================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faCreditCardBlank as faCreditCardLt,
  faUser,
  faCalendarStar,
  faCalendarAlt,
  faLockAlt,
  faShoppingCart,
  faHandHoldingBox,
  faTruck,
  faQuestionCircle,
  faCheck,
  faChevronRight,
  faChevronLeft,
  faClock,
  faBarcodeAlt,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";

import { faInfo, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

//===============================================================================================================
// #7 - Import * Bootstrap Components
//===============================================================================================================
import Button from "react-bootstrap/Button";

//===============================================================================================================
// #5 - Credit Card Flags
//===============================================================================================================
import Visa from "../assets/images/credit-card/visa.png";
import Mastercard from "../assets/images/credit-card/mastercard.png";
import AmericanExpress from "../assets/images/credit-card/americanExpress.png";
import DinersClub from "../assets/images/credit-card/DinersClub.png";

//===============================================================================================================
// #4 - Fake Data
//===============================================================================================================
const path_url_fake = "https://photos.enjoei.com.br/public/";
const products_fake = [
  {
    url: `${path_url_fake}255x255/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy8xMzU1OTc2MS9iZjg2ODNjMmU4OWVkYmVlZTliZTgzMjViYThlNGJjMi5qcGc`,
    title: "sandália vermelha 35",
    price: 90,
    oldprice: 107,
    freight: "correios",
  },
  {
    url: `${path_url_fake}255x255/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy84MjEyMTI0L2MxZjMzOWIwM2IzZGZmZTJmOTY3MDU0MDBhYzZjNWY3LmpwZw`,
    title: "star wars a trilogia",
    price: 104,
    oldprice: 120,
    freight: "entrega_maos",
  },
  {
    url: `${path_url_fake}255x255/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy82MTY0MzE5LzZhOWIyZDM3ZjY5ZWUxMTQzYzNmYTQ3YzI4Y2EwYjEzLmpwZw`,
    title: "canecas tok&stok",
    price: 95,
    oldprice: "",
    freight: "entrega_maos",
  },
];

const params = [
  {title: "1x de R$82,20"},
  {title: "2x de R$41,10"},
  {title: "3x de R$27,40"},
]

//===============================================================================================================
// #4 - Modal - Address
//===============================================================================================================
const ModalAddress = forwardRef((props, ref) => {

  // * States
  const [show, setShow] = useState(false);

  // * Function
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title> {props.title} </Modal.Title>
              
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});


//===============================================================================================================
// #4 - Modal - Add Credit Card
//===============================================================================================================
const ModalCreditCard = forwardRef((props, ref) => {
  // Bootstrap Modal
  const [show, setShow] = useState(false);

  // Handle Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title>Adicionar informações</Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

//===============================================================================================================
// #4 - Default
//===============================================================================================================
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize:{
    fontSize: '10px'
  },
  inputSize: {
    height: 50
  }
});

function Buy() {
  
  // States
  const [valueFieldCredt, setValueCredt]    = useState("");
  const [ cep, setCep ]                     = useState("");
  const [creditCardType, setCreditCardType] = useState(null);
  const [valueField, setValue]              = useState("");

  // Styles
  const matches               = useMediaQuery("(min-width:600px)");
  const classes               = useStyles();

  // Refs
  const childShippingOptions  = useRef();
  const childTaxs             = useRef();
  const refAddressModal       = useRef();
  const refCreditCardModal    = useRef();

  // * React Hook Form
  const { register, handleSubmit, errors, formState } = useForm({mode: "onChange"});

  // * React Hook Form - Submit
  const onSubmitAddress     = () => {
    Swal.fire({
      icon: "success",
      title: "Endereço adicionado!",
      timer: 4000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "fechar",
    }).then((eComplete) => {
      if (eComplete) {
        closeModalAddress();
      }
    });
  };

  const onSubmitCreditCard  = () => {
    Swal.fire({
      icon: "success",
      title: "Cartão de crédito adicionado!",
      timer: 4000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "fechar",
    }).then((eComplete) => {
      if (eComplete) {
        closeModalCreditCard();
      }
    });
  };

  // * Functions - Modals
  const openModalShippingOptions  = () => childShippingOptions.current.handleShow();
  const openModalTaxs             = () => childTaxs.current.handleShow();
  const closeModalAddress         = () => refAddressModal.current.closeModal();
  const closeModalCreditCard      = () => refCreditCardModal.current.closeModal();

  // * Functions - React Mask
  const handleCepChange = (e) => setCep(e.target.value);

  // * Check Credit Card Flag Type
  let checkCreditCardType = (e) =>{

    let cardnumber = e.target.value.replace(' ','').replace(/[^0-9]+/g,'');
    let emptyFields = 16 - cardnumber.length;
    let zeros = "";
    for(let count = 0; count < emptyFields; count++){
      zeros += "0"
    }

      var cards = {
        visa           : /^4[0-9]{12}(?:[0-9]{3})/,
        mastercard     : /^5[1-5][0-9]{14}/,
        DinersClub     : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        americanExpress: /^3[47][0-9]{13}/,
      };  

      for (var flag in cards) {
          if(cards[flag].test(parseInt((cardnumber + zeros)))) {
            return setCreditCardType(flag);
          }
      }       

      return setCreditCardType(null);

  }

  return (
    <>
    <section id="view_buy">
      <div className="_block _title-with-timer">
        <div className="_med-container">
          <CurrentPage icon={<Icon icon={faShoppingCart} />} title="Resumo do pedido" />

          <div className="_timer">
            <Icon icon={faClock} />

            <div className="_timer-clock">
            <Timer initialTime={600000} direction="backward">
              {() => (
                <React.Fragment>
                  <Timer.Minutes />:
                  <Timer.Seconds />
                </React.Fragment>
              )}
            </Timer>
            </div>
          </div>
        </div>
      </div>
      <form>

        <div className="_med-container">
        <div className="_columns">
        
        {/* Col #1 */}
        <div className="_column">

          <section className="_block">
              <h6 className="_block-title"> Vendedor </h6>
              <AdvertiserCard storeLogo="https://via.placeholder.com/150x150" storeLink="/loja/" storeTitle="Loja Emilia" storeLocation="Curitiba - PR" button={false} />
          </section>

          <section className="_block">
              <h6 className="_block-title"> Produtos </h6>
              {products_fake.map((product) => (

                <ProductThumbnail
                    image={product.url}
                    title={product.title}
                    oldPrice={product.oldprice}
                    price={product.price}
                    productUrl="/produto?id=5f71fab26f53fc335a8aa21f"
                    className="small"
                >
                    
                    <Button 
                        className="trash-item"
                        color="primary"
                        autoFocus
                        type="button"
                    >
                    <Icon icon={product.freight === "correios" && faTruck || product.freight === "entrega_maos" && faHandHoldingBox } />
                    </Button>
                </ProductThumbnail>

                ))}
          </section>

        </div>{/* END ./ Col #1 */}

        {/* Col #2 */}
        <div className="_column">

          <section className="_block">
            <h6 className="_block-title"> Frete </h6>
              
              

            <SelectBoxExpand
              icon={<Icon icon={faTruck} />}
              input={<input type="radio" name="correios" checked={true}/>}
              titleContent={<ListContent><ListItem title="Correios"> <Price value="17,50"/> </ListItem></ListContent>}
            >

              {products_fake.map((product) => (

              <ProductThumbnail
                  image={product.url}
                  title={product.title}
                  className="small"
                  onClick={() => alert('hello')}
              >
                  
                  <Button 
                      className="trash-item"
                      color="primary"
                      autoFocus
                      type="button"
                  >
                    <Icon icon={faChevronRight} />
                  </Button>
              </ProductThumbnail>

              ))}

            </SelectBoxExpand>

            <SelectBoxExpand
              icon={<Icon icon={faHandHoldingBox} />}
              input={<input type="radio" name="em_maos" checked={true}/>}
              titleContent={<ListContent><ListItem title="Entrega em mãos"> <Price value="00,00"/> </ListItem></ListContent>}
            >

              {products_fake.map((product) => (

              <ProductThumbnail
                  image={product.url}
                  title={product.title}
                  className="small"
                  onClick={() => alert('hello')}
              >
                  
                  <Button 
                      className="trash-item"
                      color="primary"
                      autoFocus
                      type="button"
                  >
                    <Icon icon={faChevronRight} />
                  </Button>
              </ProductThumbnail>

              ))}

              <BoxInfo
                  icon={<Icon icon={faInfo} />}
                  title="importante"
                  text="Assim que finalizar a compra você pode entrar
                  em contato com o vendedor e combinar a
                  entrega do produto."
              />

            </SelectBoxExpand>
          </section>

            <section className="_block">
              <h6 className="_block-title">
                Endereço de entrega
              </h6>

              <BoxInfo
                icon={<Icon icon={faMapMarkerAlt} />}
                title="CEP: 85050-030"
                text="R Bartolomeu bueno da silva, n359 - Bairro Água
                Verde, Curitiba - Paraná"
              />
              <Button
                className="change-address"
                color="primary"
                autoFocus
                style={{ height: 30 }}
                type="button"
                onClick={() => refAddressModal.current.openModal()}
            >
              Alterar endereço
            </Button>
            </section>
          
        </div>{/* END ./ Col #2 */}
 
        {/* Col #3 */}
        <div className="_column">
                  
          <section className="_block">
            <h6 className="_block-title"> Resumo do pedido</h6>

            <ListContent bgColor="#FFFFFF">

              <ListItem title="Total em pontos">
                <PricePoints value="948" />
              </ListItem>

              <ListItem title="Valor a pagar em reais">
                <Price value="82,20" />
              </ListItem>

              <ListItem title="Data de expiração">
                00/00/0000
              </ListItem>

              <ListItem title="Taxa Scamb" icon={<Button type="button" className="button-info" onClick={openModalTaxs}><Icon icon={faQuestionCircle} /></Button>}>
                <Price value="37,40" />
              </ListItem>

              <ListItem title="Frete" icon={<Button type="button" className="button-info" onClick={openModalShippingOptions}><Icon icon={faQuestionCircle} /></Button>}>
                <Price value="44,80" />
              </ListItem>

            </ListContent>
          </section>

          <section className="_block">
            <h6 className="_block-title">
              Forma de pagamento
              <Button className="btn-add-payment" onClick={() => refCreditCardModal.current.openModal()}>
                Novo cartão
                <Icon icon={faPlus} />
              </Button>
            </h6>
            <SelectBox
              icon={<img src={Mastercard} alt="mastercard" />}
              input={<input type="radio" name="cc" checked={true}/>}
              titleContent={<ListContent><ListItem title="Mastercard"> **** **** **** 5487 </ListItem></ListContent>}
            />

            <SelectBox
              icon={<img src={Visa} alt="visa" />}
              input={<input type="radio" name="cc" checked={true}/>}
              titleContent={<ListContent><ListItem title="Mastercard"> **** **** **** 9148 </ListItem></ListContent>}
            />

            <SelectBox
              icon={<Icon icon={faBarcodeAlt} />}
              input={<input type="radio" name="cc" checked={true}/>}
              titleContent={<ListContent><ListItem> Boleto bancário </ListItem></ListContent>}
            />
            
            <Autocomplete
              id="combo-box-demo"
              options={params}
              size="small"
              fullWidth
              getOptionLabel={(option) => option.title}
              renderInput={(params) => <TextField {...params} label="Opções de parcelamento" variant="outlined" />}
            />
          </section>

          <section className="checkout-actions">
            <Button
                className="accept"
                color="primary"
                autoFocus
                style={{ height: matches ? 35 : 40 }}
                type="submit"
            >
              Comprar
            </Button>

            <Button
                className="cancel"
                color="primary"
                autoFocus
                style={{ height: matches ? 35 : 40 }}
                type="submit"
            >
              Cancelar
            </Button>
          </section>

        </div>{/* END ./ Col #3 */}
        
        </div>
        </div>
      </form>
    </section>
    
    <ModalBase
          ref={childShippingOptions}
          modalTitle="Opções de entrega"
          modalSubTitle="Qual a melhor opção para mim?"
        >
          <ModalShippingOptions></ModalShippingOptions>
    </ModalBase>

    <ModalBase
          ref={childTaxs}
          modalTitle="Taxa Scamb"
          modalSubTitle={`
          Você não paga nada para listar produtos no Scamb e ter sua loja ativa.
          Porém, a cada transação efetivada, o Scamb cobra uma taxa de administração para apoiar a manutenção da plataforma.
          A taxa é sempre paga pelo COMPRADOR. Não há taxas para o vendedor.
          O valor da taxa Scamb varia de acordo com o valor de pontos da transação.
          Veja aqui a tabela de valores da taxa Scamb.
				`}
        >
          <ModalTaxs></ModalTaxs>
        </ModalBase>
    
    <ModalAddress ref={refAddressModal} title="Alterar endereço de entrega">
        <form onSubmit={handleSubmit(onSubmitAddress)}>
        <h1 className="_content-title" />
          {/*  Form Body */}
          <section className="_form-body">
            <Grid container spacing={2}>
              
              <Grid item xs={12}>
              <InputMask
                    mask="99999-999"
                    onChange={e => setValueCredt(e.target.value)}
                    onChange={handleCepChange}
                  >
                <TextField
                  id="outlined-cc-number"
                      label="Cep *"
                      type="text"
                      variant="outlined"
                      name="cep"
                      size={matches ? "small" : "small"}
                      error={!!errors.cep}
                      fullWidth
                      inputRef={register({
                        required: true,
                        minLength: 8,
                      })}
                />
                </InputMask>
              </Grid>
              <Grid item xs={6}>
                <TextField
                    id="outlined-neighborhood"
                    label="Estado *"
                    variant="outlined"
                    size={matches ? "small" : "small" }
                    name="estado"
                    error={!!errors.estado}
                    fullWidth
                    inputProps={{ maxLength: 2 }}
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </Grid>
              <Grid item xs={6}>
                <TextField
                    id="outlined-neighborhood"
                    label="Cidade *"
                    variant="outlined"
                    size={matches ? "small" : "small" }
                    name="cidade"
                    error={!!errors.cidade}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="outlined-address"
                  label="Endereço *"
                  variant="outlined"
                  size={matches ? "small" : "small" }
                  name="logradouro"
                  error={!!errors.logradouro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-address-number"
                  label="Número *"
                  type="number"
                  variant="outlined"
                  size={matches ? "small" : "small" }
                  name="numero"
                  error={!!errors.numero}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-neighborhood"
                  label="Bairro *"
                  variant="outlined"
                  size={matches ? "small" : "small" }
                  name="bairro"
                  error={!!errors.bairro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-complement"
                  label="Complemento"
                  variant="outlined"
                  name="complemento"
                  size={matches ? "small" : "small" }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            
            </Grid>

            <div className="mark-default-address">
              <label>
                <input type="checkbox" name="mark_default_address" />

                <div className="text">
                  Estou ciente que esse será meu novo
                  endereço de entrega e que valerá para
                  futuras compras
                </div>

                <div className="check">
                  <Icon icon={faCheck} />
                </div>
              </label>
            </div>

          </section>

          <Button
            type="submit"
            className={"btn btn-primary btn-fluid btn-submit-modal "+classes.center}
            color="primary"
            autoFocus
            style={{height: (matches ? 35 : 40)}}
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Confirmar
            <Icon icon={faCheck} />
          </Button>
          {/*  END ./ Form Body */}
        </form>
      </ModalAddress>

   <ModalCreditCard ref={refCreditCardModal}>
        <form onSubmit={handleSubmit(onSubmitCreditCard)}>
          <h1 className="_content-title">
            Fique tranquilx não será cobrado nenhuma taxa no seu cartão
          </h1>
          <ul className="_payment-methods-list">
            <li>
              <div className="creadit-card--flag">
                <img src={Visa} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={Mastercard} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={AmericanExpress} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={DinersClub} alt="" />
              </div>
            </li>
          </ul>

          {/*  Form Body */}
          <section className="_form-body _credit-card-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <InputMask
                    mask="9999 9999 9999 9999"
                    value={valueField}
                    onChange={e => setValue(e.target.value)}
                  >
                <TextField
                  id="outlined-cc-number"
                  label="Número do cartão *"
                  type="text"
                  value={valueField}
                  variant="outlined"
                  name="numero_cartao"
                  onInput={checkCreditCardType}
                  onPaste={checkCreditCardType}
                  size={matches ? "small" : "medium" }
                  error={!!errors.numero_cartao}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 8,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {
                          creditCardType?
                            <div className="creadit-card--flag">
                              <img style={{width:"15.75px"}} src={require(`../assets/images/credit-card/${creditCardType}.png`)} alt="Flag credit card" />
                            </div>
                          :
                          <Icon icon={faCreditCardLt} />
                        }
                      </InputAdornment>
                    ),
                  }}
                />
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-cc-name"
                  label="Nome do titular cartão *"
                  variant="outlined"
                  name="nome"
                  size={matches ? "small" : "medium" }
                  error={!!errors.nome}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 4,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faUser} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-cc-month"
                  label="Mês *"
                  type="number"
                  variant="outlined"
                  name="mes_expiracao"
                  size={matches ? "small" : "medium" }
                  error={!!errors.mes_expiracao}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 2,
                    maxLength: 2
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faCalendarStar} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-cc-year"
                  label="Ano *"
                  type="number"
                  variant="outlined"
                  name="ano_expiracao"
                  size={matches ? "small" : "medium" }
                  error={!!errors.ano_expiracao}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 2,
                    maxLength: 2
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faCalendarAlt} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-cc-number"
                  label="Cvv *"
                  type="number"
                  variant="outlined"
                  name="cvv"
                  size={matches ? "small" : "medium" }
                  error={!!errors.cvv}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 3,
                    maxLength: 4
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faLockAlt} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <div className="_set-user-location">
              <div className="_wrap-location">
                <div className="_icon">
                  <Icon icon={faMapMarkerAlt} />
                </div>

                <div className="_wrap-current-location">
                  <div className="_location-title">CEP: 85050-030</div>
                  <div className="_location">
                    R Bartolomeu bueno da silva, n359 - Bairro Água
                    Verde, Curitiba - Paraná
                  </div>
                </div>
              </div>
            </div>
            
          </section>
          {/*  END ./ Form Body */}

          <Button
            type="submit"
            className={"btn btn-primary btn-fluid btn-submit-modal "+classes.center}
            color="primary"
            autoFocus
            style={{height: (matches ? 35 : 40)}}
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Confirmar
            <Icon icon={faCheck} />
          </Button>
        </form>
      </ModalCreditCard> 
    </>
  );
}

export default Buy;
