import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import FixedMenu from '../components/FixedMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

import $, { timers } from 'jquery';

// Icons
import {
        faInbox,
        faQuestionCircle,
        faSearch,
        faChevronDown,
        faEye,
        faHeart,
        faStore,
        faShoppingCart,
        faWallet,
        faCog,
        faSignOutAlt,
        faMegaphone,
        faUserFriends,
        faShoppingBag
       } from '@fortawesome/pro-light-svg-icons';

// Images
import Logo from '../assets/images/logo.png';
import Avatar from '../assets/images/avatar.png';

import Authentication from './../services/Authentication';
import EventEmitter from './../services/Events';
import Scamber from '../services/Scamber';

import { setScamber } from '../redux/actions/scamberActions';

// Services
import Ad from '../services/Ad';

// Data Categories
const categories_list = [
    {id: '5f2b397f4132b6c235b31490', category_name: "roupas e etc", category_link: "/resultados" },
    {id: '5f2b397f4132b6c235b314ed', category_name: "kids", category_link: "/resultados" },
    {id: '5f2b397f4132b6c235b31545', category_name: "beleza e saúde", category_link: "/resultados" },
    {id: '5f2b397f4132b6c235b3154a', category_name: "casa e decoração", category_link: "/resultados" },
    {id: '5f2b397f4132b6c235b31569', category_name: "outros", category_link: "/resultados" },
];

const ListItem = (props) => {
    return (
        <li>
            {props.children}
        </li>
    )
}

class Header extends Component {

    constructor (props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            searchValue:'',
            limit: 8
        };

        this.scamber = new Scamber();
        this.ad = new Ad();
    }

    // Redirect categories
    redirect = (childState) =>{
        window.location.href=`/resultados?category=${childState.category}&pageNumber=1`;
    }
    

    onclick = () => {
        this.child.current.getAlert();
    }

    logoutUser = () => {
        const auth = new Authentication();

        auth.doLogout().then(() => {
            localStorage.removeItem('token');
            this.setState({ isAuthenticated: false });
            this.props.setScamber(undefined);

            this.props.history.push('/');
        }).catch(() => {

        });
    }

    loadScamberData = () => {
        Swal.fire('Aguarde...');
        Swal.showLoading();
        this.scamber.findScamber().then(( scamberData ) =>{
            
            Swal.close();
            if( scamberData.status === 200) this.props.setScamber(scamberData.data.payload);
    
        }).catch((error)=> Swal.fire({type:'info', html: this.scamber.formatErrorMessage(error.response), title:''}));
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.location.pathname !== this.props.location.pathname){
            if(this.props.location.pathname !== '/resultados') this.setState({searchValue:''});   
        }
    }

    componentDidMount() {

        if(this.props.location.pathname !== '/resultados') this.setState({searchValue:''});

        this.setState({ isAuthenticated: localStorage.getItem('token')? true: false }, () => {
            if(this.state.isAuthenticated === true) {
                this.loadScamberData();
            }

            this.registerMenuActions();
        });

        EventEmitter.subscribe('loginSucess', () => {
            this.setState({ isAuthenticated: true });
            this.loadScamberData();
            this.registerMenuActions();
        });

        EventEmitter.subscribe('logoutSucess', () => {
			this.setState({ isAuthenticated: false });
        });

        // Adicionar classe abaixo de 768
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth > 768
            });
        }, false);
    }

    registerMenuActions() {
        
        let categoryBtn     = $('.categories-menu');
        let userMenuBtn     = $('._user-menu-btn');
        let categoryMenu    = $('._categories-menu-dropdown');
        let userMenu        = $('._user-menu-dropdown');
        let mobileNavBtn    = $('._mobile-nav-btn');
        let leftMenu        = $('._aside-menu-categories');
        let mobileNavUser 	= $('._mobile-nav-user');
        let rightMenu		= $('._aside-menu-user');
        let closeNav        = $('.aside-menu > .aside-menu-header > #close');
        
        // Hover Category Menu
        $(categoryBtn).on('mouseover', function ()
        {
            $(categoryMenu).removeClass('_is-hidden');
        })
        .on('mouseout', function ()
        {
            $(categoryMenu).addClass('_is-hidden');
        });
        
        // Hover User Menu
        $(userMenuBtn).on('mouseover', function ()
        {
            $(userMenu).removeClass('_is-hidden');
        })
        .on('mouseout', function ()
        {
            $(userMenu).addClass('_is-hidden');
        });
        
        $(mobileNavBtn).on('click', function () {
            $(leftMenu).toggleClass('_is-hidden');
            $(rightMenu).addClass('_is-hidden');
        });
        
		$(mobileNavUser).on('click', function () {
            $(rightMenu).toggleClass('_is-hidden');
            $(leftMenu).addClass('_is-hidden');
        });
        
        $(closeNav).on('click', function () {
            $(leftMenu).addClass('_is-hidden');
            $(rightMenu).addClass('_is-hidden');
        });

        $('._list-nav._user > ul > li > a').on('click', function () {
            $(leftMenu).addClass('_is-hidden');
            $(rightMenu).addClass('_is-hidden');
        });

        $('.aside-nav > ul > li > a').on('click', function () {
            $(leftMenu).addClass('_is-hidden');
            $(rightMenu).addClass('_is-hidden');
        });

    }

    search = (e, idCategory) => {
        e.preventDefault();
        this.redirect({category:idCategory, newSearch:true});
    }

    searchKey = (e) =>{
        e.preventDefault();
        window.location.href = (`/resultados?key=${this.state.searchValue}&pageNumber=1`);
    }



    render () {
        
        return (
            
            <div className="_wrap-header">

            <FixedMenu redirect={this.redirect}></FixedMenu>    
            
            <div className="_header">
                
                {/* Container */}
                <div className="_med-container">
                    
                    {/* Cols */}
                    <div className="cols">

                        {/* Col left */}
                        <div className="col-left">

                            {/* Mobile menu icon */}
                            <div className="icon-nav-mobile">
                                <button type="button" className="_mobile-nav-btn">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>{/* END ./ Mobile menu icon */}

                            {/* Logo */}
                            <div className="logo">
                                <Link to="/">
                                    <img src={Logo} alt="Scamb" />
                                </Link>
                            </div>{/* END ./ Logo */}
                            
                            <div className="_wrap-points-access">
                            
                            {/* How it works */}
                            <div className="it-works-menu">
                                <button type="button">
                                    <FontAwesomeIcon icon={faQuestionCircle} className="fa-question-circle"/>
                                </button>
                            </div>{/* END ./ How it works */}

                            
                            {   //quando logado
                                this.state.isAuthenticated?
                                    ( 
                                        <div className="_my-points">
                                            Meus pontos
                                            <div className="_product-price _1x">
                                                <div className="ui-item__price">
                                                    <span className="price-tag-fraction">0</span>
                                                    <span className="price-tag-symbol">pts</span>
                                                </div>
            
                                            </div>
                                        </div> 
                                    ): ''
                            }

                            {   
                                //quando deslogado
                                !this.state.isAuthenticated?
                                    (
                                        <div className="_login">
                                            <Link to="/prosseguir" className="btn btn-transparent">
                                                Entrar
                                            </Link>
                                        </div>
                                    ): ''
                            }

                            </div>

                        </div>{/* END ./ Col left */}

                        {/* Col right */}
                        <div className="col-right">

                            {/* Search form */}
                            <div className="search-menu">
                                
                                <form onSubmit={(e)=>this.searchKey(e)} className="form">
                                    <div className={"form-group search " + this.state.isMobile ? 'form-group search mobile' : ''}>
                                        <input value={this.state.searchValue} onChange={(e)=>this.setState({searchValue:e.target.value})} type="text" placeholder="Busque por 'Blusas'" />
                                        <button type="submit">
                                            <FontAwesomeIcon icon={faSearch} className="fa-question-circle"/>
                                        </button>
                                    </div>
                                </form>
                                
                            </div>{/* END ./ Search form */}
                            
                            <div className="categories-menu">
                                <button type="button" className="btn btn-transparent _color _black _fw700 _categories-btn">
                                    categorias <FontAwesomeIcon icon={faChevronDown} />
                                </button>
                                
                                <div className="_dropdown-menu _categories-menu-dropdown _positions _is-hidden">
                                    <nav className="_list-nav">
                                        <ul>
                                            {categories_list.map(({id, category_name}) => (
                                                <ListItem redirect={this.redirect} key={id}>
                                                    <a href="#!" onClick={(e)=>this.search(e,id)} id={id}>
                                                        {category_name}
                                                    </a>
                                                </ListItem>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            
                            {   //quando logado
                                this.state.isAuthenticated?
                                (
                                    <React.Fragment>
                                        <div className="shopping-cart-alert">
                                        
                                        <Link to="/minha-sacola" className="btn btn-transparent _color _black">
                                            <span className="alert-icon-box">
                                                <FontAwesomeIcon icon={faShoppingBag} />
                                                <span className="_notification-icon-mark _red"></span>
                                            </span>
                                        </Link>
                                        
                                        </div>
                                        <div className="messages-menu">
                                        
                                        <Link to="/mensagens" className="btn btn-transparent _color _black">
                                            <span className="alert-icon-box">
                                                <FontAwesomeIcon icon={faInbox} />
                                                {/*<span className="_notification-icon-mark _red"></span>*/}
                                            </span>
                                        </Link>
                                        
                                        </div>

                                        <div className="user-menu _user-menu-btn">
                                            <button type="button" className="btn btn-transparent _color _black">
                                                <span className="user-image _rounded-image__with-notification-icon _1x">
                                                    {this.props.scamber? this.props.scamber.imagens?.avatar? (<img src={this.scamber.loadImage(this.props.scamber.imagens?.avatar)} alt="Avatar" />):
                                                        (<img src={Avatar} alt="Avatar" />):<></>}
                                                    {/*<span className="_notification-icon-mark _red"></span>*/}
                                                </span>
                                            </button>
                                            
                                            <div className="_dropdown-menu _user-menu-dropdown _is-hidden">
                                                <nav className="_list-nav">
                                                    <ul>
                                                        <li>
                                                            <Link to={"/loja/" + this.props.scamber?.minha_loja?.url}>
                                                                <span><FontAwesomeIcon icon={faEye} /></span>
                                                                Ver loja
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/meus-favoritos">
                                                                <span><FontAwesomeIcon icon={faHeart} /></span>
                                                                Meus Favoritos
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/minha-sacola">
                                                                <span>
                                                                    <FontAwesomeIcon icon={faShoppingBag} />
                                                                    <span className="_notification-icon-mark _red"></span>
                                                                </span>
                                                                Minha Sacola
                                                                <div className="count">
                                                                    <h6>2</h6>
                                                                </div>
                                                            </Link>

                                                        </li>
                                                        <li>
                                                            <Link to="/minhas-vendas">
                                                                <span><FontAwesomeIcon icon={faStore} /></span>
                                                                Minhas Vendas
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/meus-anuncios">
                                                                <span><FontAwesomeIcon icon={faMegaphone} /></span>
                                                                Meus Anúncios
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/minhas-compras">
                                                                <span><FontAwesomeIcon icon={faShoppingCart} /></span>
                                                                Minhas Compras
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/minha-carteira">
                                                                <span><FontAwesomeIcon icon={faWallet} /></span>
                                                                Carteira
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/meu-perfil">
                                                                <span><FontAwesomeIcon icon={faCog} /></span>
                                                                Meu Perfil
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/convidar-amigos">
                                                                <span><FontAwesomeIcon icon={faUserFriends} /></span>
                                                                Convide Amigos
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/">
                                                                <span><FontAwesomeIcon icon={faQuestionCircle} /></span>
                                                                Ajuda
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <a href="#!" onClick={this.logoutUser}>
                                                                <span><FontAwesomeIcon icon={faSignOutAlt} /></span>
                                                                Sair
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ):'' 
                            }
                            
                            {   
                                //quando deslogado
                                !this.state.isAuthenticated?
                                    ( 
                                        <div className="it-works-menu">
                                            <Link to="/como-funciona" className="btn btn-transparent _fw700 _color _black">
                                                <FontAwesomeIcon icon={faQuestionCircle} className="fa-question-circle"/>
                                            </Link>
                                        </div>
                                    ): ''
                            }
                           

                            {   //quando logado
                                this.state.isAuthenticated?
                                    ( 
                                        <Link to="/minha-carteira" className="_my-points">
                                            Meus pontos
                                            <div className="_product-price _1x">
                                                <div className="ui-item__price">
                                                    <span className="price-tag-fraction">0</span>
                                                    <span className="price-tag-symbol">pts</span>
                                                </div>
            
                                            </div>
                                        </Link> 
                                    ): ''
                            }
                           
                           {   
                                //quando deslogado
                                !this.state.isAuthenticated?
                                    (
                                        <div className="_login">
                                            <Link to="/prosseguir" className="btn btn-transparent">
                                                Entrar
                                            </Link>
                                        </div>
                                    ): ''
                            }

                            <div className="announce-button">
                                <Link onClick={() => window.location.href = "/criar-novo"} to='#!' className="btn btn-red _fw700">Quero vender</Link>
                            </div>
                            
                        </div>{/* END ./ Col right */}

                    </div>{/* END ./ Cols */}
                </div>{/* END ./ Container */}
                
            </div>
            </div>
        )
        
    }
    
}

const mapStateToProps = state => {
    return {
        scamber: state.scamberReducer.scamber
    }
}

export default withRouter(connect(mapStateToProps, { setScamber })(Header));