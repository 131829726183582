import React, { Component } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

class Breadcrumbs extends Component {
    
    render() {
        
        return (
            
            <section id="breadcrumbs">
                <div className="_med-container">
                    <Breadcrumb>
                      <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                      <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                        Library
                      </Breadcrumb.Item>
                      <Breadcrumb.Item active>Data</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </section>
        
        );
        
    };
    
};

export default Breadcrumbs;