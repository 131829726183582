import React, { Component } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/account/Layout";
import Swal from 'sweetalert2';

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck, faHeart, faTrashAlt } from "@fortawesome/pro-light-svg-icons";

// Import Favorites List
import ListItem from "../../components/FavoritesList";

//Images
import ProductIMG from "../../assets/images/product-favorite.png";

// Services
import Favorito from '../../services/Favorito';

import Helmet from 'react-helmet';

class MyFavorites extends Component {

  constructor(){
    super();
    this.favorito = new Favorito();
    this.state = {
      'favoritos':[]
    }
  }


  componentDidMount(){
    Swal.fire('Aguarde...');
    Swal.showLoading();
    this.loadFavorites(0);
  }

  loadFavorites = (sumLimit) => {
    let limit = 5;
    limit += sumLimit;
    Swal.fire('Aguarde...')
    Swal.showLoading();
    this.favorito.list(limit)
      .then((response) => {this.setState({'favoritos':response.data?.payload}); Swal.close() })
      .catch((error) => Swal.fire({type: "info", html: this.favorito.formatErrorMessage(error.response),title: ""}));
  }

  render() {
    return (
      <div id="view_my-favorites">
        <Helmet title="Scamb - Meus Favoritos" />
        <Layout>
          <div id="favorites" className="_content">
            <h1 className="_title-box">
              <span>
                <FontAwesomeIcon icon={faHeart} />
              </span>
              Meus Favoritos
            </h1>

            <div className="_wrap-favorites">
              <div className="_favorites-wrap-list">

                {
                  this.state.favoritos?.map((favorito)=> {
                    return(
                    favorito.anuncio[0]?.ativo?
                      <nav className="_favorites-list">
                        <ul>
                          <ListItem refresh={()=>this.loadFavorites(5)} idFavorite={favorito._id} link={`/produto?id=${favorito.anuncio[0]?._id}`} image={this.favorito.loadImage(favorito.anuncio[0].fotos[0])} title={favorito.anuncio[0].titulo} price={favorito.anuncio[0].pontos_por?.$numberDecimal} oldPrice={favorito.anuncio[0].pontos_de?.$numberDecimal} ></ListItem>
                        </ul>
                      </nav>
                    :<></>
                    )
                  })
                }

              </div>
            </div>

            <div style={{textAlign:"center",marginTop:"10px"}} className="_wrap-load-more">
                <button onClick={()=>this.loadFavorites(5)} type="submit" className="btn btn-red">Carregar mais</button>
            </div>

          </div>
        </Layout>
      </div>
    );
  }
}

export default MyFavorites;
