import Service from './Service';

class Scamber extends Service {

    constructor() {
        super()
    }

    saveScamber(scamber) {
        return this.axiosInstance.post(`/scamber`, scamber);
    }

    updateScamber(scamber) {
        return this.axiosInstance.put(`/scamber`, scamber);
    }

    validate(validationCode, inviteCode) {
        return this.axiosInstance.put(`/scamber/validate${inviteCode ? '?inviteCode=' + inviteCode:''}`, { validationCode: validationCode } );
    }

    createSimpleScamber(email, password, name) {
        return {
            email: email,
            senha: password,
            nome: name
        }
    }

    findScamber() {
        return this.axiosInstance.get(`/scamber`);
    }

    changeImage(formDataAvatar, image) {
        return this.axiosInstance.put(`/scamber/change/${image}`, formDataAvatar,  { headers: {'Content-Type': 'multipart/form-data'} });
    }

    resendEmailValidation() {
        return this.axiosInstance.get('/scamber/resendEmailValidation');
    }


}

export default Scamber;