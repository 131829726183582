import React, { Component, useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Icons
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";

class ModalTaxs extends Component {
  render() {
    return (
      <>
        <Accordion className="_accordion small">
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <div className="_accordion-title">Correios</div>

                <button
                  type="button"
                  className="btn btn-transparent toggle-arrow"
                >
                  <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                </button>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p className="_fw400">
                  Na modalidade de entregas feitas pelos Correios, o preço do
                  frete é calculado com base na distância entre o comprador e o
                  vendedor e no peso do produto (que foi cadastrado pelo
                  vendedor quando colocou o produto em sua loja). Para esta
                  modalidade, temos uma oferta incrível que estará em vigor
                  durante os primeiros meses do Scamb.{" "}
                  <strong>
                    O envio de pacotes de até 0.5 kg de qualquer lugar do Brasil
                    para qualquer lugar do Brasil tem o valor fixo de apenas R$
                    9,90.
                  </strong>
                </p>
                <br />
                <p className="_fw400">
                  Assim que uma venda é finalizada, é gerada uma etiqueta de
                  postagem, na qual constam todos os dados do comprador, para
                  que o vendedor envie o produto. A etiqueta será enviada por
                  e-mail ao vendedor assim que o pagamento for confirmado.
                  Depois é só embalar o produto, colar a etiqueta, a declaração
                  de conteúdo (que está no mesmo arquivo da etiqueta) e ir a
                  qualquer agência dos Correios. O envio já foi pago pelo
                  comprador e não há nenhuma taxa adicional a ser paga na
                  agência.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                <div className="_accordion-title">Entrega em mãos</div>

                <button
                  type="button"
                  className="btn btn-transparent toggle-arrow"
                >
                  <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                </button>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <p className="_fw400">
                  Para a opção de entrega em mãos, obviamente o custo é zero!
                  Basta comprador e vendedor combinarem o melhor lugar para a
                  entrega do produto!
                </p>
                <br />
                <p className="_fw400">
                  Quando o produto é anunciado como{" "}
                  <strong>Entrega em Mãos</strong>, o comprador e o vendedor
                  podem combinar um local, no chat da plataforma, para a entrega
                  do produto. Se morarem na mesma cidade, esta pode ser a
                  solução ideal, uma vez que economizará o valor do frete. Essa
                  modalidade de frete é ótima para a entrega de produtos de
                  grande porte.
                </p>
                <br />
                <p className="_fw400">
                  Vale lembrar que o vendedor é quem define como será a forma de
                  entrega, seja pelos Correios ou Entrega em Mãos. Por isso,
                  fique atento aos avisos presentes nos anúncios dos produtos
                  antes de finalizar uma compra.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    );
  }
}

export default ModalTaxs;
