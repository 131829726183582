//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import { useHistory } from "react-router-dom";

//=====================================================================================
// #2 - Import * Account Layout Base
//=====================================================================================
import Layout from "../../components/account/Layout";

//=====================================================================================
// #3 - Import * Sweet Alert
//=====================================================================================
import Swal from 'sweetalert';

// Material - UI
import { useMediaQuery } from "@material-ui/core";
  
//=====================================================================================
// #2 - Import * UI Components
//=====================================================================================
import { AdvertiserCard } from "../../components/ui/advertiser-card";
import { ProductThumbnail } from "../../components/ui/product-thumbnail";
import { CurrentPage } from "../../components/ui/current-page";

//=====================================================================================
// #3 - Import * FontAwesome Icons
//=====================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faShoppingBag, faTrashAlt, faCheck } from "@fortawesome/pro-light-svg-icons";


//=====================================================================================
// #7 - Import * Bootstrap Components
//=====================================================================================
import Button from 'react-bootstrap/Button'


//=====================================================================================
// #4 - Fake Products
//=====================================================================================
const path_url_fake = "https://photos.enjoei.com.br/public/";
const products_fake = [
  { url: `${path_url_fake}255x255/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy8xMzU1OTc2MS9iZjg2ODNjMmU4OWVkYmVlZTliZTgzMjViYThlNGJjMi5qcGc`, title: "sandália vermelha 35", price: 90, oldprice: 107, freight: "correios" },
  { url: `${path_url_fake}255x255/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy84MjEyMTI0L2MxZjMzOWIwM2IzZGZmZTJmOTY3MDU0MDBhYzZjNWY3LmpwZw`, title: "star wars a trilogia", price: 104, oldprice: 120, freight: "entrega_maos" },
  { url: `${path_url_fake}255x255/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy82MTY0MzE5LzZhOWIyZDM3ZjY5ZWUxMTQzYzNmYTQ3YzI4Y2EwYjEzLmpwZw`, title: "canecas tok&stok", price: 95, oldprice: "", freight: "entrega_maos" },
]




function ShoppingCart () {

    // Styles
    const matches = useMediaQuery("(min-width:600px)");

    const history = useHistory();

    // Delete Cart Item
    const deleteCartItem = () => {

        Swal({
            title: "Deletar produto?",
            icon: "warning",
            buttons: [
            'Fechar',
            'Confirmar'
            ],
            dangerMode: true })
            .then(function(isConfirm) {
                if (isConfirm) {
                    Swal({title: "Produto removido!", icon: "success", buttons: 'Ok', dangerMode: true})
                }
        });
    }

    // *  Redirect To Checkout
    const redirectCheckout = (e) => {
        e.preventDefault();
        history.push({pathname: `/checkout/`});
    }
        
    return (
        
        <Layout>

        <section id="view_shopping-cart">

            <CurrentPage icon={<Icon icon={faShoppingBag} />} title="Minha Sacola" />

            <div className="shopping-cart-items">

            <AdvertiserCard storeLogo="https://via.placeholder.com/150x150" storeLink="/loja/" storeTitle="Loja Emilia" storeLocation="Curitiba - PR" button={true}/>

            {products_fake.map((product) => (

                <ProductThumbnail
                    image={product.url}
                    title={product.title}
                    oldPrice={product.oldprice}
                    price={product.price}
                    freightType={product.freight}
                    productUrl="/produto?id=5f71fab26f53fc335a8aa21f"
                >
                    
                    <Button 
                        className="trash-item"
                        color="primary"
                        autoFocus
                        type="button"
                        onClick={deleteCartItem}
                    >
                    <Icon icon={faTrashAlt} />
                    </Button>
                </ProductThumbnail>

                ))}

            </div>
            
            <section className="checkout-button">
                <Button
                    className="accept"
                    onClick={redirectCheckout}
                    color="primary"
                    autoFocus
                    style={{ height: matches ? 35 : 40 }}
                    type="submit"
                >
                Finalizar compra
                <Icon icon={faCheck} />
                </Button>
            </section>
            

        </section>

        </Layout>
        
    )
    
}

export default ShoppingCart;