import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "../assets/images/avatar.png";

// Icons
import {
  faQuestionCircle,
  faEye,
  faHeart,
  faStore,
  faShoppingCart,
  faWallet,
  faCog,
  faSignOutAlt,
  faLongArrowRight,
  faMegaphone,
  faUserFriends,
  faShoppingBag,
} from "@fortawesome/pro-light-svg-icons";

import Authentication from './../services/Authentication';
import EventEmitter from './../services/Events';

import { connect } from 'react-redux';
import { setScamber } from './../redux/actions/scamberActions';
import Scamber from './../services/Scamber';

class SideMenu extends Component {

  constructor() {
    super();

    this.state = {};
    this.scamber = new Scamber();
  }

  logoutUser = () => {
    const auth = new Authentication();

    auth.doLogout().then(() => {
        localStorage.removeItem('token');
        this.setState({ isAuthenticated: false })
        EventEmitter.dispatch('logoutSucess');
        this.props.setScamber(undefined);

        this.props.history.push('/');
    });

  }

  render() {
    return (
      <div
        id="aside"
        className={"aside-menu _rightwards " + this.props.className}
      >
        <div className="aside-menu-header _user">
          <button type="button" id="close" className="btn close-user-menu">
            <FontAwesomeIcon icon={faLongArrowRight} />
          </button>

          <div className="user-informations">
            <button type="button" className="btn btn-transparent _color _black">
              <span className="user-image _rounded-image__with-notification-icon _2x">
                {this.props.scamber? this.props.scamber.imagens?.avatar? (<img src={this.scamber.loadImage(this.props.scamber.imagens?.avatar)} alt="Avatar" />):
                      (<img src={Avatar} alt="Avatar" />):<></>}
              </span>
              <div className="user-informations-name__and-email">
                {this.props.scamber?.nome}
                <span>{this.props.scamber?.email}</span>
              </div>
            </button>
          </div>
        </div>

        <div className="button-to-announce">
          <Link to="/criar-novo" className="btn btn-danger">
            Quero vender
          </Link>
        </div>

        <nav className="_list-nav _user">
          <ul>
            <li>
              <Link to={"/loja/" + this.props.scamber?.minha_loja?.url}>
                <span>
                  <FontAwesomeIcon icon={faEye} />
                </span>
                Ver loja
              </Link>
            </li>
            <li>
              <Link to="./meus-favoritos">
                <span>
                  <FontAwesomeIcon icon={faHeart} />
                </span>
                Meus Favoritos
              </Link>
            </li>
            <li>
              <Link to="/minha-sacola">
                  <span>
                      <FontAwesomeIcon icon={faShoppingBag} />
                      <span className="_notification-icon-mark _red"></span>
                  </span>
                  Minha Sacola
                  <div className="count">
                    <h6>2</h6>
                  </div>
              </Link>
            </li>
            <li>
              <Link to="./minhas-vendas">
                <span>
                  <FontAwesomeIcon icon={faStore} />
                </span>
                Minhas Vendas
              </Link>
            </li>
            <li>
              <Link to="./meus-anuncios">
                <span>
                  <FontAwesomeIcon icon={faMegaphone} />
                </span>
                Meus Anúncios
              </Link>
            </li>
            <li>
              <Link to="./minhas-compras">
                <span>
                  <FontAwesomeIcon icon={faShoppingCart} />
                </span>
                Minhas Compras
              </Link>
            </li>
            <li>
              <Link to="./minha-carteira">
                <span>
                  <FontAwesomeIcon icon={faWallet} />
                </span>
                Carteira
              </Link>
            </li>
            <li>
              <Link to="./meu-perfil">
                <span>
                  <FontAwesomeIcon icon={faCog} />
                </span>
                Meu Perfil
              </Link>
            </li>
            <li>
              <Link to="./convidar-amigos">
                <span>
                  <FontAwesomeIcon icon={faUserFriends} />
                </span>
                Convide Amigos
              </Link>
            </li>
            <li>
              <Link to="./ajuda">
                <span>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
                Ajuda
              </Link>
            </li>
            <li>
              <a href="#!" onClick={this.logoutUser}>
                <span>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </span>
                Sair
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    scamber: state.scamberReducer.scamber
  }
}

export default withRouter(connect(mapStateToProps, { setScamber })(SideMenu));
