import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import WrapView from "./layout/WrapView";
import InputMask from "react-input-mask";


// Forms
import { useForm } from "react-hook-form";

// Material - UI
import {
  Grid,
  TextField,
  InputAdornment,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {BrowserRouter} from 'react-router';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faCreditCardBlank,
} from "@fortawesome/pro-solid-svg-icons";

import { faCheck } from "@fortawesome/pro-regular-svg-icons";

import {
  faChevronRight,
  faCreditCardBlank as faCreditCardLt,
  faUser,
  faCalendarStar,
  faCalendarAlt,
  faLockAlt,
  faChevronLeft,
  faTimes,
  faIdCard,
  faAddressCard,
} from "@fortawesome/pro-light-svg-icons";

// Images
import Visa from "../assets/images/credit-card/visa.png";
import Mastercard from "../assets/images/credit-card/mastercard.png";
import AmericanExpress from "../assets/images/credit-card/americanExpress.png";
import DinersClub from "../assets/images/credit-card/DinersClub.png";

// Services
import Endereco from './../services/Endereco';
import Scamber from './../services/Scamber';
import CreditCard from './../services/CreditCard';
import Store from './../services/Store';

// JQuery
import $ from "jquery";
import "jquery-mask-plugin";

import { connect } from 'react-redux';

import { setScamber } from './../redux/actions/scamberActions';

import Helmet from 'react-helmet';

const states = [
  { title: "Acre", initials: "AC" },
  { title: "Alagoas", initials: "AL" },
  { title: "Amapá", initials: "AP" },
  { title: "Amazonas", initials: "AM" },
  { title: "Bahia", initials: "BA" },
  { title: "Ceará", initials: "CE" },
  { title: "Distrito Federal", initials: "DF" },
  { title: "Espírito Santo", initials: "ES" },
  { title: "Goiás", initials: "GO" },
  { title: "Maranhão", initials: "MA" },
  { title: "Mato Grosso", initials: "MT" },
  { title: "Mato Grosso do Sul", initials: "MS" },
  { title: "Minas Gerais", initials: "MG" },
  { title: "Pará", initials: "PA" },
  { title: "Paraíba", initials: "PB" },
  { title: "Paraná", initials: "PR" },
  { title: "Pernambuco", initials: "PE" },
  { title: "Piauí", initials: "PI" },
  { title: "Rio de Janeiro", initials: "RJ" },
  { title: "Rio Grande do Norte", initials: "RN" },
  { title: "Rio Grande do Sul", initials: "RS" },
  { title: "Rondônia", initials: "RO" },
  { title: "Roraima", initials: "RR" },
  { title: "Santa Catarina", initials: "SC" },
  { title: "São Paulo", initials: "SP" },
  { title: "Sergipe", initials: "SE" },
  { title: "Tocantins", initials: "TO" },
];

const cities = [
  { title: "Cidade #1" },
  { title: "Cidade #2" },
  { title: "Cidade #3" },
  { title: "Cidade #4" },
  { title: "Cidade #5" },
  { title: "Cidade #6" },
  { title: "Cidade #7" },
  { title: "Cidade #8" },
  { title: "Cidade #9" },
  { title: "Cidade #10" },
  { title: "Cidade #11" },
  { title: "Cidade #12" },
  { title: "Cidade #13" },
  { title: "Cidade #14" },
  { title: "Cidade #15" },
  { title: "Cidade #16" },
];

// Styles
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize:{
    fontSize: '10px'
  },
  inputSize: {
    height: 50
  }
});

const ModalCreditCardAddress = forwardRef((props, ref) => {
  // Bootstrap Modal
  const [show, setShow] = useState(false);

  // Handle Modal
  const handleClose = () => { props.onCloseAddressModal(); setShow(false) };
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content" style={{}}>
              <Button className="_back-history btn" onClick={handleClose}>
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
              </Button>
              <Modal.Title>Alterar endereço de cobrança</Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

const ModalAddress = forwardRef((props, ref) => {
  // Bootstrap Modal
  const [show, setShow] = useState(false);

  // Handle Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
              </Button>
              <Modal.Title>Adicionar endereço</Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

const ModalCreditCard = forwardRef((props, ref) => {
  // Bootstrap Modal
  const [show, setShow] = useState(false);

  // Handle Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
              </Button>
              <Modal.Title>Adicionar informações</Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

function CompleteRegistration(props) {
  const refAddressModal = useRef();
  const refCreditCardModal = useRef();
  const refAddressCreditCardModal = useRef();

  // Styles
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');

  //Enable Buttons
  const [disabledAddress, setDisabledAdrs] = useState(false);
  const [disabledCreditCardButton, setDisabledButton] = useState(true);
  const [disabledCreditCard, setDisabled] = useState(false);
  const [valueField, setValue] = useState("")
  const [valueFieldCredt, setValueCredt] = useState("")
  const [cardAddress, setCardAddress] = useState({});
  const [ cep, setCep ] = useState("");
  const [ cardCep, setCardCep ] = useState("");
  const [creditCardType, setCreditCardType] = useState(null);
  const [disableField, setDisableField] = useState(true);
  const [titulo, setTitulo] = useState("");

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  const [values, setValues] = useState({
    url: "",
  });

  let checkButton = true;

  const { register, handleSubmit, errors, formState, reset } = useForm({
    mode: "onChnage",
  });

  const { register: registerAddressCard, formState: formStateAddressCard, 
    handleSubmit: handleSumbmitAddresCard , errors: errorsAddressCard, reset: resetAddressCard } = useForm({
    mode: "onChnage",
  });

  const endereco = new Endereco();
  const scamber = new Scamber();
  const creditCard = new CreditCard();

  const closeModalAddress = () => refAddressModal.current.closeModal();
  const closeModalCreditCard = () => refCreditCardModal.current.closeModal();
  const closeModalAddressCreditCard = () => refAddressCreditCardModal.current.closeModal();

  const loadScamberData = () => {
    Swal.fire('Aguarde...');
    Swal.showLoading();
    scamber.findScamber().then(( scamberData ) =>{        
        Swal.close();
        if( scamberData.status === 200) props.setScamber(scamberData.data.payload);
    }).catch((error)=> Swal.fire({type:'info', html: scamber.formatErrorMessage(error.response), title:''}));
  }

  const onSubmitAddress = (data) => {
    Swal.fire('Aguarde...');
    Swal.showLoading();

    endereco.update(data).then(( response )=>{

      if( response.status === 200 ){

        Swal.fire({
          icon: "success",
          title: "Endereço adicionado!",
          timer: 4000,
          timerProgressBar: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "fechar",
        }).then((eComplete) => {
          if (eComplete) {
            setDisabledAdrs(true);
            setDisabledButton(!disabledCreditCardButton);
            closeModalAddress();
            // loadScamberData();
          }
        });

      }
     
    }).catch((error)=> Swal.fire({type:'info', html: endereco.formatErrorMessage(error.response), title:''}))

  };


  const onSubmitPersonalData = (data) => {
    const store = new Store();

    console.log(data,' my data')
    if(!data.titulo) return Swal.fire('Nome da loja não preenchido.')

    scamber.updateScamber(data).then((response) => {
      if( response.status === 200) {

        data["url"] = titulo.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase();
        store.saveStore(data).then((response) => {
          if( response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Informações salvas com sucesso!",
              timer: 4000,
              timerProgressBar: true,
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: "fechar",
            }).then((its_ok) => {
              if (its_ok) {
                setDisableField(!disableField);
                setDisabled(true);
                closeModalCreditCard();
              }
            });

          }
        }).catch (( error ) => Swal.fire({type:'info', html: store.formatErrorMessage(error.response), title:''}));
      }
      
    }).catch (( error ) => Swal.fire({type:'info', html: scamber.formatErrorMessage(error.response), title:''}));

  };

  const onSubmitChargeAddress = (data) => {

    setCardAddress({ cep: data.cardCep, estado: data.cardEstado, cidade: data.cardCidade, 
      logradouro: data.cardLogradouro, numero: data.cardNumero, bairro: data.cardBairro, complemento: data.cardComplemento });

    Swal.fire({
      icon: "success",
      title: "Endereço de cobrança alterado!",
      timer: 4000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "fechar",
    }).then((eComplete) => {
      closeModalAddressCreditCard();
    });
  };

  const goTo = () =>{
    loadScamberData();
    props.history.push("/criar-novo");
  } 

  if (disabledAddress === true && disabledCreditCard === true) {
    checkButton = false;
  }

  useEffect(() => {
    setDisableField(!disableField);
  }, []);

  useEffect(() => {
    checkScamberAddress(props.scamber);
    checkScamberPersonalData(props.scamber);
  }, [props.scamber]);

  const checkScamberAddress = (scamber) => {
    if(scamber && scamber.endereco && scamber.endereco.length > 0) {
      setDisabledAdrs(true);
      setDisabledButton(true);

      let endereco = scamber.endereco[0];
      setCardAddress(endereco);
      resetAddressCard({ cardCep: endereco.cep, cardEstado: endereco.estado, cardCidade: endereco.cidade, 
        cardLogradouro: endereco.logradouro, cardNumero: endereco.numero, cardBairro: endereco.bairro, cardComplemento: endereco.complemento });
    }
  }

  const checkScamberPersonalData = (scamber) => {
    //preenchendo o form com os dados que o usuario tiver preenchido
    initFormValues(scamber);
    if(scamber && scamber.cpf && scamber.telefone.length > 0 && scamber.minha_loja) {
      setDisabledAdrs(true);
      setDisabled(true)

      // let endereco = scamber.endereco[0];
      // setCardAddress(endereco);
      // resetAddressCard({ cardCep: endereco.cep, cardEstado: endereco.estado, cardCidade: endereco.cidade, 
      //   cardLogradouro: endereco.logradouro, cardNumero: endereco.numero, cardBairro: endereco.bairro, cardComplemento: endereco.complemento });
    }
  }

  const initFormValues = (profile) => {
    if(profile) {
      //REMOVING ALL EMPTY SPACES ON STRING
      if(profile.telefone && profile.telefone.length > 0) {
        profile.celular = `(${profile.telefone[0].ddd}) ${profile.telefone[0].numero.substring(0, 5)}-${profile.telefone[0].numero.substring(5, 9)}`;
      }
      reset(profile); 
    }
  }

  const handleCepChange = (e) => {
    setCep(e.target.value);
  }

  const handleCardCepChange = (e) => {
    setCardCep(e.target.value);
  }

  useEffect(() => {
    $(".cpf").mask("000.000.000-00", { reverse: false });
    $(".celular").mask("(00) 00000-0000");
  });

  const findAddressByCEP = () => {
    if(cep.length === 9){
      endereco.findCep(cep).then(( address ) => {

        let formatedAddress = {
          cep:        address.data.cep,
          estado:     address.data.estado,
          logradouro: address.data.logradouro,
          cidade:     address.data.localidade,
          estado:     address.data.uf,
          bairro:     address.data.bairro
        }

        if(address.data){
          reset(formatedAddress); 
        }
      }).catch((error)=> Swal.fire('info','Não foi possível consultar o endereço',''));
    }
  }

  const findAddressCardByCEP = () => {
    if(cardCep.length === 9){
      endereco.findCep(cardCep).then(( address ) => {

        let formatedAddress = {
          cardCep:        address.data.cep,
          cardEstado:     address.data.estado,
          cardLogradouro: address.data.logradouro,
          cardCidade:     address.data.localidade,
          cardEstado:     address.data.uf,
          cardBairro:     address.data.bairro
        }

        if(address.data){
          resetAddressCard(formatedAddress); 
        }
      }).catch((error)=> Swal.fire('info','Não foi possível consultar o endereço',''));
    }
  }

  let checkCreditCardType = (e) =>{

    let fieldSize = e.target.value.replace(' ','').replace(/[^0-9]+/g,'').length;
    let cardnumber = e.target.value.replace(' ','').replace(/[^0-9]+/g,'');
    let emptyFields = 16 - cardnumber.length;
    let zeros = "";
    for(let count = 0; count < emptyFields; count++){
      zeros += "0"
    }

      var cards = {
        visa           : /^4[0-9]{12}(?:[0-9]{3})/,
        mastercard     : /^5[1-5][0-9]{14}/,
        DinersClub     : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        americanExpress: /^3[47][0-9]{13}/,
      };

      // discover       : /^6(?:011|5[0-9]{2})[0-9]{12}/,
      // hipercard      : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      // elo            : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      // jcb            : /^(?:2131|1800|35\d{3})\d{11}/,       
      // aura           : /^(5078\d{2})(\d{2})(\d{11})$/     


      for (var flag in cards) {
          if(cards[flag].test(parseInt((cardnumber + zeros)))) {
            return setCreditCardType(flag);
          }
      }       

      return setCreditCardType(null);

  }

  const onCloseAddressModal = () => {
    resetAddressCard({ cardCep: cardAddress.cep, cardEstado: cardAddress.estado, cardCidade: cardAddress.cidade, 
      cardLogradouro: cardAddress.logradouro, cardNumero: cardAddress.numero, cardBairro: cardAddress.bairro, cardComplemento: cardAddress.complemento });
  }

  return (
    <>
      <Helmet title="Scamb - Completar Registro" />
      <ModalAddress ref={refAddressModal}>
        <form onSubmit={handleSubmit(onSubmitAddress)}>
          {/*  Form Body */}
          <h1 className="_content-title">
            Cadastre um endereço a sua conta Scamb. Este será o endereço padrão
            de todos os seus anúncios.
          </h1>
          <section className="_form-body">
            <Grid container spacing={2}>
              
              <Grid item xs={12}>
              <InputMask
                    mask="99999-999"
                    onChange={e => setValueCredt(e.target.value)}
                    onChange={handleCepChange}
                    onBlur={findAddressByCEP}
                  >
                <TextField
                  id="outlined-cc-number"
                      label="Cep *"
                      type="text"
                      variant="outlined"
                      name="cep"
                      size={matches ? "small" : "medium"}
                      error={!!errors.cep}
                      fullWidth
                      inputRef={register({
                        required: true,
                        minLength: 8,
                      })}
                />
                </InputMask>
              </Grid>
              <Grid item xs={6}>
                <TextField
                    id="outlined-neighborhood"
                    label="Estado *"
                    variant="outlined"
                    size={matches ? "small" : "medium" }
                    name="estado"
                    error={!!errors.estado}
                    fullWidth
                    inputProps={{ maxLength: 2 }}
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </Grid>
              <Grid item xs={6}>
                <TextField
                    id="outlined-neighborhood"
                    label="Cidade *"
                    variant="outlined"
                    size={matches ? "small" : "medium" }
                    name="cidade"
                    error={!!errors.cidade}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="outlined-address"
                  label="Endereço *"
                  variant="outlined"
                  size={matches ? "small" : "medium" }
                  name="logradouro"
                  error={!!errors.logradouro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-address-number"
                  label="Número *"
                  type="number"
                  variant="outlined"
                  size={matches ? "small" : "medium" }
                  name="numero"
                  error={!!errors.numero}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-neighborhood"
                  label="Bairro *"
                  variant="outlined"
                  size={matches ? "small" : "medium" }
                  name="bairro"
                  error={!!errors.bairro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-complement"
                  label="Complemento"
                  variant="outlined"
                  name="complemento"
                  size={matches ? "small" : "medium" }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </section>

          <Button
            type="submit"
            className={"btn btn-primary btn-fluid btn-submit-modal "+classes.center}
            color="primary"
            autoFocus
            style={{height: (matches ? 35 : 40)}}
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Confirmar
            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
          </Button>
          {/*  END ./ Form Body */}
        </form>
      </ModalAddress>

      <ModalCreditCard ref={refCreditCardModal}>
        <form onSubmit={handleSubmit(onSubmitPersonalData)}>
          {/*  Form Body */}
          <h1 className="_content-title">
            Cadastre algumas informações pessoais como CPF e telefone celular, para realizar seu primeiro anúncio!
          </h1>
          <section className="_form-body _credit-card-form">
            
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="form-group">
                    <TextField
                        id="outlined-name"
                        label={"CPF *"}
                        type="text"
                        variant={"outlined"}
                        size={matches ? "small" : "medium"}
                        name="cpf"
                        error={!!errors.cpf}
                        fullWidth
                        disabled={disableField}
                        inputProps={{ className: "cpf" }}
                        inputRef={register({
                            required: true,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                    
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="form-group">
                  <TextField
                    id="outlined-name"
                    label={"Celular *"}
                    type="text"
                    variant={"outlined"}
                    size={matches ? "small" : "medium"}
                    name="celular"
                    error={!!errors.celular}
                    fullWidth
                    disabled={disableField}
                    inputProps={{ className: "celular" }}
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <h1 className="_content-title">
              Agora nos informe qual sera o nome da sua loja?
            </h1>
            <Grid item xs={12}>
              <div className="form-group">
                <TextField
                  id="outlined-name"
                  label={"Nome da loja *"}
                  type="text"
                  onChange={(e)=>setTitulo(e.target.value)}
                  // helperText={errors.titulo?.type === "minLength" && "é muito curto (minimo 5 caracteres)" || !!errors.titulo && "Preencha o nome da loja"}
                  variant={"outlined"}
                  size={matches ? "small" : "medium"}
                  name="titulo"
                  error={!!errors.titulo}
                  fullWidth
                  disabled={disableField}
                  inputProps={{ className: "titulo" }}
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              </Grid>
              <div className="form-group">
                <h1 className="_content-title"><b>URL da sua loja sera:</b> {"https://scamb.com.vc/loja/" + titulo.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase()}</h1>
              </div>
          </section>
          {/*  END ./ Form Body */}

          <Button
            type="submit"
            className={"btn btn-primary btn-fluid btn-submit-modal "+classes.center}
            color="primary"
            autoFocus
            style={{height: (matches ? 35 : 40)}}
            disabled={
              !formState.dirty && !formState.isValid
            }
          >
            Confirmar
            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
          </Button>
        </form>
      </ModalCreditCard>

      <ModalCreditCardAddress onCloseAddressModal={onCloseAddressModal} ref={refAddressCreditCardModal}>
        <form
          onSubmit={handleSumbmitAddresCard(onSubmitChargeAddress)}
          className="_form-address-cc"
        >
          {/*  Form Body */}
          <h1 className="_content-title">
            Este endereço de cobrança é associado apenas a este cartão.
          </h1>
          <section className="_form-body">
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputMask
                    mask="99999-999"
                    onChange={handleCardCepChange}
                    onBlur={findAddressCardByCEP}
                  >
                <TextField
                  id="outlined-cep"
                      label="CEP *"
                      type="text"
                      variant="outlined"
                      name="cardCep"
                      size={matches ? "small" : "medium"}
                      error={!!errorsAddressCard.cardCep}
                      fullWidth
                      inputRef={registerAddressCard({
                        required: true,
                        minLength: 8,
                      })}
                />
                </InputMask>
              </Grid>
              <Grid item xs={6}>
                <TextField
                    id="outlined-state"
                    label="Estado *"
                    name="cardEstado"
                    variant="outlined"
                    error={!!errorsAddressCard.cardEstado}
                    size={matches ? "small" : "medium" }
                    fullWidth
                    inputProps={{ maxLength: 2 }}
                    inputRef={registerAddressCard({
                      required: true
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </Grid>
              <Grid item xs={6}>
                <TextField
                    id="outlined-city"
                    label="Cidade *"
                    name="cardCidade"
                    variant="outlined"
                    error={!!errorsAddressCard.cardCidade}
                    size={matches ? "small" : "medium" }
                    fullWidth
                    inputRef={registerAddressCard({
                      required: true
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="outlined-address"
                  label="Endereço *"
                  variant="outlined"
                  size={matches ? "small" : "medium" }
                  name="cardLogradouro"
                  error={!!errorsAddressCard.cardLogradouro}
                  fullWidth
                  inputRef={registerAddressCard({
                    required: true
                  })}
                  helperText={errorsAddressCard.address && "Não pode ficar em branco"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-address-number"
                  label="Número *"
                  type="number"
                  variant="outlined"
                  size={matches ? "small" : "medium" }
                  name="cardNumero"
                  error={!!errorsAddressCard.cardNumero}
                  fullWidth
                  inputRef={registerAddressCard({
                    required: true,
                  })}
                  helperText={
                    errorsAddressCard.address_number && "Não pode ficar em branco"
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-neighborhood"
                  label="Bairro *"
                  variant="outlined"
                  size={matches ? "small" : "medium" }
                  name="cardBairro"
                  error={!!errorsAddressCard.cardBairro}
                  fullWidth
                  inputRef={registerAddressCard({
                    required: true,
                  })}
                  helperText={errorsAddressCard.neighborhood && "Não pode ficar em branco"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-complement"
                  label="Complemento"
                  name="cardComplemento"
                  variant="outlined"
                  size={matches ? "small" : "medium" }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </section>
                  
          <Button
            type="submit"
            className={"btn btn-primary btn-fluid btn-submit-modal "+classes.center}
            color="primary"
            autoFocus
            style={{height: (matches ? 35 : 40)}}
            disabled={
              !formStateAddressCard.isValid
            }
          >
            Confirmar
            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
          </Button>
          {/*  END ./ Form Body */}
        </form>
      </ModalCreditCardAddress>

      <WrapView
        headline="Antes de adicionar um produto você precisa completar o seu cadastro."
        stepTitle="Complete seu cadastro"
        className="_screen-conf"
      >
        
        <button
          type="button"
          className="btn _box-check"
          onClick={() => refAddressModal.current.openModal()}
          disabled={disabledAddress}
        >
          <div className="_col-left">
            <div className="_wrap-icon">
              <span
                className={
                  disabledAddress ? "_check-icon _checked" : "_check-icon"
                }
              >
                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
              </span>
              <div className="_icon">
                <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
              </div>
            </div>

            <div className="_text">Clique aqui para adicionar um endereço</div>
          </div>
          <div className="_col-right">
            <div className="_icon">
              <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
            </div>
          </div>
        </button>

        <button
          type="button"
          className="btn _box-check"
          onClick={() => refCreditCardModal.current.openModal()}
          disabled={disabledCreditCard}
        >
          <div className="_col-left">
            <div className="_wrap-icon">
              <span
                className={
                  disabledCreditCard ? "_check-icon _checked" : "_check-icon"
                }
              >
                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
              </span>
              <div className="_icon">
                <FontAwesomeIcon icon={faAddressCard}></FontAwesomeIcon>
              </div>
            </div>

            <div className="_text">
              Clique aqui para adicionar algumas informações.
            </div>
          </div>
          <div className="_col-right">
            <div className="_icon">
              <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
            </div>
          </div>
        </button>

        {/* END ./ Form Head */}

        <Button
            type="submit"
            className={"btn btn-primary btn-fluid btn-submit-modal "+classes.center}
            color="primary"
            autoFocus
            style={{height: (matches ? 35 : 40)}}
            onClick={() => goTo()}
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid) || (!disabledCreditCard && !disabledCreditCard)
            }
          >
            Continuar
            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
          </Button>
      </WrapView>
    </>
  );
}

const mapStateToProps = state => {
  return { scamber: state.scamberReducer.scamber }
}

export default connect(mapStateToProps, { setScamber })(CompleteRegistration);
