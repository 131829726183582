import React, { Component }                 from 'react';
import { Link }                             from 'react-router-dom';
import TopCategories                        from '../components/TopCategoriesHome';

import Product from '../components/GridProduct';
import OfficialStore from '../components/OfficialStore';
import SmallProduct from '../components/OfficialStoreProducts';
import Swal from 'sweetalert';

import Slider from "react-slick";

// Modals
import ModalBase from "../components/modals/ModalBase";

// Material UI
import Grid from '@material-ui/core/Grid';

// Services 
import Ad from '../services/Ad';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

//Imagens
import BannerNewAcc from '../assets/images/banner-new-account.png';

// Banner #1 Mob & Desk
import BannerChegamosDesk from '../assets/images/banners/banner-chegamos.png';
import BannerChegamosMob from '../assets/images/banners/banner-chegamos_mobile.png';

// Banner #2 Mob & Desk
import BannerCadastreProdutosDesk from '../assets/images/banners/banner-cadastre-produtos.png';
import BannerCadastreProdutosMob from '../assets/images/banners/banner-cadastre-produtos_mobile.png';

// Banner #3 Mob & Desk
import BannerIndiqueAmigosDesk from '../assets/images/banners/banner-indique-amigos.png';
import BannerIndiqueAmigosMob from '../assets/images/banners/banner-indique-amigos_mobile.png';

// Banner #4 Mob & Desk
import BannerPrimeiraCompraDesk from '../assets/images/banners/banner-primeira-compra.png';
import BannerPrimeiraCompraMob from '../assets/images/banners/banner-primeira-compra_mobile.png';

// Banner #5 Mob & Desk
import BannerScamberDesk from '../assets/images/banners/banner-seja-um-scamber.png';
import BannerScamberMob from '../assets/images/banners/banner-seja-um-scamber_mobile.png';

// Oficial Store #1
import BannerOficialStore_1                   from '../assets/images/banner-loja-oficial.png';
import LogoOficialStore_1                     from '../assets/images/logo-oficial-store-1.png';
import ThumbOficialStore_1_1                    from '../assets/images/store-1-tb-1.png';
import ThumbOficialStore_1_2                    from '../assets/images/store-1-tb-2.png';
import ThumbOficialStore_1_3                    from '../assets/images/store-1-tb-3.png';

// Oficial Store #2
import BannerOficialStore_2                   from '../assets/images/banner-loja-oficial-2.png';
import LogoOficialStore_2                     from '../assets/images/logo-oficial-store-2.png';
import ThumbOficialStore_2_1                    from '../assets/images/store-2-tb-1.png';
import ThumbOficialStore_2_2                    from '../assets/images/store-2-tb-2.png';
import ThumbOficialStore_2_3                    from '../assets/images/store-2-tb-3.png';

// Oficial Store #3
import BannerOficialStore_3                   from '../assets/images/banner-loja-oficial-3.png';
import LogoOficialStore_3                     from '../assets/images/logo-oficial-store-3.png';
import ThumbOficialStore_3_1                    from '../assets/images/store-3-tb-1.png';
import ThumbOficialStore_3_2                    from '../assets/images/store-3-tb-2.png';
import ThumbOficialStore_3_3                    from '../assets/images/store-3-tb-3.png';

// Oficial Store #3
import BannerOficialStore_4                   from '../assets/images/banner-loja-oficial-4.png';
import LogoOficialStore_4                     from '../assets/images/logo-oficial-store-4.png';
import ThumbOficialStore_4_1                    from '../assets/images/store-4-tb-1.png';
import ThumbOficialStore_4_2                    from '../assets/images/store-4-tb-2.png';

import Helmet from 'react-helmet';

const paramsSliderHome = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
	arrows: true
}

const paramsOfficialStores = {
    dots: false,
    infinite: false,
    speed: 500,
	arrows: true,
	variableWidth: true,
	responsive: [{
		breakpoint: 480,
		settings: {
			slidesToShow: 1,
    		slidesToScroll: 1,
		}
	}]
}

const banners = [
    { image_desk: BannerChegamosDesk, image_mob: BannerChegamosMob, link: "/sobre-o-scamb#como-funciona" },
    { image_desk: BannerCadastreProdutosDesk, image_mob: BannerCadastreProdutosMob, link: "/sobre-o-scamb#como-funciona" },
    { image_desk: BannerIndiqueAmigosDesk, image_mob: BannerIndiqueAmigosMob, link: "#!" },
    { image_desk: BannerPrimeiraCompraDesk, image_mob: BannerPrimeiraCompraMob, link: "#!" },
    { image_desk: BannerScamberDesk, image_mob: BannerScamberMob, link: "#!" },
]


class Home extends Component {

    constructor(){
        super();
        this.ad = new Ad();

        // Refs
        this.alertModal = React.createRef();

        this.state ={
            products:[]
        }
    }

    redirect = (childState) =>{
        window.location.href = (`/resultados?category=${childState.category}&pageNumber=1`);
    }

    search = () => {
        this.ad
          .findAdsByUserSearch(null, 20, null, 'top20', 20)
          .then((response) => {
            this.setState({products: response.data.payload})
          })
          .catch((error) =>
            Swal.fire({
              type: "info",
              html: this.ad.formatErrorMessage(error.response),
              title: "",
            })
          );
    };

    componentDidMount(){
        this.search();

        const showSwall = () => {
            Swal({
                title: "Olá!",
                text: `Nossa plataforma ainda está em construção!
                Em poucas semanas, vc já poderá usá-la com todos os recursos, de forma totalmente automática. \n
                Enquanto isso, vc pode navegar à vontade, criar sua loja, favoritar, cadastrar produtos e até comprar!\n
                Estamos atendendo pelo nosso chat para finalizar sua compra ou para qualquer outra ajuda que precisar!`,
                icon: "info",
                buttons: ["Fechar", "Inscreva-se"],
                dangerMode: true,
              }).then(function (isConfirm) {
                if (isConfirm) {
                    window.location.href = "/criar-conta";
                }
              });
        }

        showSwall();
    }
	
    render () {
        
        return (
            
            <div id="view_home">
                <Helmet title="Scamb - Home" />
                <div className="new-account-banner _is-hidden">
                    <div className="_wrap-new-account-banner">
                        
                        <button type="button" className="btn btn-transparent _close-modal">
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        
                        <div className="_wrap-new-account-banner--inside">

                            <img src={BannerNewAcc} alt="" />
                            <Link to="/prosseguir" className="btn btn-neutral _fw600"> Criar minha conta</Link>

                        </div>
                    </div>
                </div>
                
                <div id="hero-slider">
                    <Slider {...paramsSliderHome} className="slider-container">

                        {banners.map((item, index) => (

                            <Link to={item.link} className="slider-item" ket={index}>
                            <picture>
                                <source media="(max-width: 650px)" srcSet={item.image_mob} />
                                <img src={item.image_desk} alt="Scamb" />
                            </picture>
                            </Link>

                        ))}
                    </Slider >
                    <div className="slide-action">
                        <Link to="/prosseguir" className="btn btn-neutral _fw600"> Criar minha conta</Link>
                    </div>
                </div>    
                    
                <div className="_med-container">  
                    
                    <div className="_wrap-top-categories">  
                            
                        <h1 className="headline _color _gray-bolder _fw700">TOP Categorias </h1>
                        
                        <TopCategories redirect={this.redirect} ></TopCategories>
                    
                    </div>

                    <div className="_wrap-recently-added">
                    
                    <h1 className="headline _color _gray-bolder _fw700">Escolhas Scamb <Link onClick={()=>window.location.href = (`/resultados?tag=top20&pageNumber=1`)}>ver mais</Link> </h1>

                        {/* Recently Added */}
                        {/* <Slider {...paramsRecentlyAdded} className="_recently-added"> */}
                        <Grid container spacing={2}>
                            {this.state.products.map((item, index) => (
                                index < 10? 
                                <Grid item md={3} sm={4} xs={6} key={index}>
                                    <Product link={`/produto?id=${item._id}`} idAd={item._id} image={this.ad.loadImage(item.fotos[0])} title={item.titulo} price={item.pontos_por.$numberDecimal} ></Product>
                                </Grid>
                                :<></>

                            ))}

                        </Grid>
                            
                        {/* </Slider>END ./ Recently Added */}

                    </div>
                    
                    {false && (
                    <div className="_wrap-official-stores">
                    
                        <h1 className="headline _color _gray-bolder _fw700">Lojas oficiais <Link to="/lojas-oficiais">ver mais</Link> </h1>
                        
						<Slider {...paramsOfficialStores} className="inline-slider _official-stores">

                            <OfficialStore link="/lojas-oficiais" banner={BannerOficialStore_1} logo={LogoOficialStore_1} title="Vic & Lolo">
                                <SmallProduct link="/produto" image={ThumbOficialStore_1_1} price="109"></SmallProduct>
                                <SmallProduct link="/produto" image={ThumbOficialStore_1_2} price="104"></SmallProduct>
                                <SmallProduct link="/produto" image={ThumbOficialStore_1_3} price="129"></SmallProduct>
                            </OfficialStore>

                            <OfficialStore link="/lojas-oficiais" banner={BannerOficialStore_2} logo={LogoOficialStore_2} title="de Casa">
                                <SmallProduct link="/produto" image={ThumbOficialStore_2_1} price="149"></SmallProduct>
                                <SmallProduct link="/produto" image={ThumbOficialStore_2_2} price="104"></SmallProduct>
                                <SmallProduct link="/produto" image={ThumbOficialStore_2_3} price="229"></SmallProduct>
                            </OfficialStore>
                            
                            <OfficialStore link="/lojas-oficiais" banner={BannerOficialStore_3} logo={LogoOficialStore_3} title="Meu Desapego">
                                <SmallProduct link="/produto" image={ThumbOficialStore_3_1} price="339"></SmallProduct>
                                <SmallProduct link="/produto" image={ThumbOficialStore_3_2} price="49"></SmallProduct>
                                <SmallProduct link="/produto" image={ThumbOficialStore_3_3} price="98"></SmallProduct>
                            </OfficialStore>
                            
                            <OfficialStore link="/lojas-oficiais" banner={BannerOficialStore_4} logo={LogoOficialStore_4} title="Bazar">
                                <SmallProduct link="/produto" image={ThumbOficialStore_4_1} price="139"></SmallProduct>
                                <SmallProduct link="/produto" image={ThumbOficialStore_4_2} price="49"></SmallProduct>
                                <SmallProduct link="/produto" image={ThumbOficialStore_3_3} price="92"></SmallProduct>
                            </OfficialStore>

                        </Slider>
							
                    </div>
                    )}
                </div>

            </div>
            
        
        )
        
    }
    
}

export default Home;