import React, { Component } from 'react';
import Routes from "./Routes";
import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './assets/css/index.scss';

class App extends Component {
    
    render () {
        
        return (
            <BrowserRouter >
                <Header />
                <Routes />
                <Footer />
            </BrowserRouter>
        )
        
    }
    
}

export default App;