import React, { Component, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import RecoveryPassword from '../../services/RecoveryPassword';

// Bootstrap
import { Button } from "react-bootstrap";

// Material - UI
import {
  makeStyles,
  useMediaQuery,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

// Icons
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import Helmet from 'react-helmet';

// Styles
const useStyles = makeStyles({
  center: {
    margin: "auto",
    display: "block",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
  svgSize: {
    width: "18px !important",
    height: "auto !important",
  },
});

export default function RecoverPassword(props) {
  // Form
  const { register, handleSubmit, errors, formState, watch } = useForm({
    mode: "onChnage",
  });
  
  const onSubmit = (data) => {

    let recoveryPassword = new RecoveryPassword();

    let paramsString = props.location.search;
    const params = new URLSearchParams(paramsString);
    let token = params.get('token');

    let requestObject = {}
    requestObject["token"]    = token;
    requestObject["password"] = data.create_acc_password;

    Swal.fire("Aguarde...");
    Swal.showLoading();
    recoveryPassword.updatePassword(requestObject).then((response)=>{
      if(response.status === 200){
        Swal.fire({
          icon: "success",
          title: "Senha Alterada!",
          timer: 3000,
          timerProgressBar: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "fechar",
        }).then((eComplete) => {
          if (eComplete) {
            document.location.href = "/login"
          }
        });
      }

    }).catch((error) =>
      Swal.fire({
        type: "info",
        html: recoveryPassword.formatErrorMessage(error.response),
        title: "",
      })
    );
    
  };

  // Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  // Password
  const [password, setPassword] = useState(false);

  const handlePassword = () => setPassword(!password);

  // Redirect
  const [redirect, setRedirect] = useState(false);

  const handleRedirect = () => {
    setRedirect(!redirect);
  };

  const renderRedirect = () => {
    if (redirect) return <Redirect to="/login" />;
  };

  return (
    <div id="view_create-account">
      <Helmet title="Scamb - Recuperar senha" />
      <section className="_wrap-auth-screen">
        <div className="auth-screen">
          <h1 className="_auth-screen-title headline _color _black _fw700 text-center">
            Escolha uma nova senha
          </h1>

          <div className="_wrap-form">
            <form className="" onSubmit={handleSubmit(onSubmit)} id="Form">
              <Grid container spacing={2}>
     
                <Grid item xs={12}>
                  <TextField
                    id="outlined-password"
                    label="Senha *"
                    type={password ? "text" : "password"}
                    variant="outlined"
                    name="create_acc_password"
                    size={matches ? "small" : "medium"}
                    error={!!errors.create_acc_password}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handlePassword}
                            edge="end"
                          >
                            <FontAwesomeIcon
                              className={classes.svgSize}
                              icon={password ? faEye : faEyeSlash}
                            ></FontAwesomeIcon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="outlined-repeat-password"
                    label="Repita sua senha *"
                    type="password"
                    variant="outlined"
                    size={matches ? "small" : "medium"}
                    name="create_acc_repeat_password"
                    error={!!errors.create_acc_repeat_password}
                    fullWidth
                    inputRef={register({
                      required: true,
                      validate: (value) =>
                        value === watch("create_acc_password"),
                    })}
                  />
                </Grid>
              </Grid>

              <div className="form-group-button">
                <Button
                  type="submit"
                  className={classes.center}
                  color="primary"
                  autoFocus
                  style={{ height: matches ? 35 : 40 }}
                  disabled={
                    !formState.dirty || (formState.dirty && !formState.isValid)
                  }
                >
                  Confirmar
                </Button>
              </div>
            </form>
          </div>

        </div>
      </section>
    </div>
  );
}
