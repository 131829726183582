
//===========================================================
// #1 - Base Imports
//===========================================================
import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";

//===========================================================
// #2 - Import * Account Layout Base
//===========================================================
import Layout from "../../components/account/Layout";

//===========================================================
// #3 - Import * Sweet Alert
//===========================================================
import Swal from "sweetalert";

//===========================================================
// #4 - Import * React Hook Form
//===========================================================
import { useForm } from "react-hook-form";

//===========================================================
// #5 - Import * { Button } Bootstrap
//===========================================================
import { Button } from "react-bootstrap";

//===========================================================
// #6 - Import * UI Components
//===========================================================
import { Price, PricePoints } from "../../components/ui/price";
import {
  Accordion,
  AccordionCard,
  AccordionInside,
  AccordionContent,
} from "../../components/ui/accordion";
import { ListContent, ListItem } from "../../components/ui/list-content";
import { ProductThumbnail } from "../../components/ui/product-thumbnail";
import { Timeline, Status } from "../../components/ui/order-timeline";
import { Checkbox } from "../../components/ui/checkbox";
import { StatusMessage } from "../../components/ui/status-message";
import { GreenList, GreenListItem } from "../../components/ui/green-list";
import { BoxPrint } from "../../components/ui/box-print";
import { CurrentPage } from "../../components/ui/current-page";

//===========================================================
// #7 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faTruck, faThumbsUp, faCheck, faCommentAltLines, faChevronRight, faChevronLeft, faTimes, faBox, faPrint } from "@fortawesome/pro-light-svg-icons";

//===========================================================
// #8 - Import * Material UI Components
//===========================================================
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Slide,
  Typography,
} from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useTheme } from "@material-ui/core/styles";
import Helmet from 'react-helmet';

//===========================================================
// #9 - Import * Images
//===========================================================
const image =
  "https://photos.enjoei.com.br/public/1200xN/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy8xMDY1NTY1My8yZGU4MzgwY2MwODc4MTMwZDE2YjIzMTFmMzc1NjgzMy5qcGc";

const image_2 =
  "https://photos.enjoei.com.br/public/1200xN/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy81OTAzMjM2LzgwZTU1OGY2ZTI0N2FlZWYxNjk2YmIxOTI3NjYwOTA1LmpwZw";

const image_3 =
  "https://photos.enjoei.com.br/public/1200xN/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy82NjY2Njk0LzFiNDRiODAzZjMwNDk4N2U2NTEwZDIzMTExNjRkZGU3LmpwZw";

const image_4 =
  "https://photos.enjoei.com.br/public/1200xN/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy8xMDY1NTY1My9jYzBhNDVkZjc0N2E3NDUyMDdhMjNmNWNmYWEwZDQ2Zi5qcGc";

const image_5 =
  "https://photos.enjoei.com.br/public/1200xN/czM6Ly9waG90b3MuZW5qb2VpLmNvbS5ici9wcm9kdWN0cy83OTc2NjMvZDg3MDQxNmRmOGJhNDY1YjY3ODcwOGIxNWMwZmVlMTQuanBn";


//===========================================================
// #10 - Create Material UI Modal Dialog
//===========================================================
const ModalDialog = forwardRef((props, ref) => {

  // * Material UI Styles - useMediaQuery
  const matches = useMediaQuery("(min-width:600px)");

  // # Define States
  const [open, setOpen] = React.useState(false);

  // * Material UI Styles - useTheme
  const theme = useTheme();

  // # Define Material UI Modal Dialog Fullscreen on mobile 
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  
  // # Define Functions

  // * Set Material UI Modal Dialog Open
  const handleClickOpen = () => {
    setOpen(true);
  };

  // * Set Material UI Modal Dialog Close
  const handleClose = () => {
    setOpen(false);
  };

  // * Set Function Using useImperativeHandle();
  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  // * Capture Event onSubmit Form with
  // * React Hook Forms
  const onSubmit = (data) => {

    // * Close Material UI Modal Dialog After Submit 
    setOpen(false);

    // * If Submit Success, Show Sweet Alert Dialog 
    Swal.fire({
      icon: "success",
      title:
        "Proposta enviada!",
        text: `Sua proposta foi enviada e, assim que o vendedor responder, mandaremos uma notificação para você!`,
      timer: 6000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "fechar",
    }).then((eComplete) => {
      if (eComplete) {
        console.log("ok");
      }
    });
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <Icon
                icon={matches ? faTimes : faChevronLeft}
              ></Icon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
          <DialogContent className={props.className}>
            <Typography component={"span"} variant={"body2"}>
              <div className="subtitle">
                {props.subTitle}
              </div>

              {props.children}
              
            </Typography>
          </DialogContent>
      </Dialog>
    </>
  );
});

// * Material UI Dialog Slide Transition Effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


//===========================================================
// #11 - Material UI Set Panel
//===========================================================
const TabPanel = (props) => {

  const { children, value, index, ...other } = props;

  return (

    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          <>
            {children}
          </>
      )}
    </div>

  )
} 

function MyPurchases () {

    // * States
    const [tabValue, setTabValue] = useState(0);
    const [buttonState, setButtonState] = useState({buttonConfirm: true });

    // * Material UI Styles - useMediaQuery
    const matches = useMediaQuery("(min-width:600px)");

    // * Create React UI Dialog Refs
    const refModalReturnProduct     = useRef();
    const refModalReturnProductImp  = useRef();
    const refModalCancelProduct     = useRef();
    const refModalCancelProductImp  = useRef();

    //* React Hook Forms
    const { register, handleSubmit, errors, formState } = useForm({ mode: 'onChange'});

    const openDialogModal  = (refModal) => refModal.current.openModal();
    const closeDialogModal = (refModal) => refModal.current.closeModal();

    // * Capture Event onSubmit Form with
    // * React Hook Forms
    // * Form - Submit Return Product Form
    const onSubmit = (data) => {

      // * Close 
      closeDialogModal(refModalReturnProduct);

      setTimeout(() => openDialogModal(refModalReturnProductImp), 300);

    };

    // * Capture Event onSubmit Form with
    // * React Hook Forms
    // * Form - Submit Cancel Product Form
    const onSubmitCancelProduct = (data) => {

      closeDialogModal(refModalCancelProduct);

      setTimeout(() => openDialogModal(refModalCancelProductImp), 300);
    }

    // * UI Modal Dialog Confirm Cancel Product
    const handleConfirmCancelProduct = () => {

      Swal({
        title: "Confirmar cancelamento?",
        text: `Ao clicar em confirmar, o sistema irá
        cancelar a sua compra.
        Seus pontos Scamb, taxas e frete serão
        Estornados.` ,
        icon: "warning",
        buttons: ["Cancelar", "Confirmar"],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          
          Swal({
            title: "Tudo pronto!",
            text: `Fique atendo a sua caixa de email.
            Em até 12 horas enviaremos entraremos em
            contato.`,
            icon: "success",
            buttons: ["Ok", false],
            dangerMode: true,
          }).then(function (isConfirm) {

              if (!isConfirm) {
                setTimeout(() => closeDialogModal(refModalCancelProductImp), 500)
              }

          });


        } else {
          Swal.close();
        }
      });
    
    }

    // * UI Modal Dialog Close Return Product
    const handleCloseModalReturnProduct = () => {
      closeDialogModal(refModalReturnProductImp);

      setButtonState({ buttonCancelSale: true, buttonReturnProduct: false });
    }

    // * Swal Confirm Product Receipt
    const handleProductReceipt = () => {

      Swal({
        title: "Confirmar recebimento?",
        text: `Ao clicar em confirmar, você afirma que
        recebeu o produto.` ,
        icon: "warning",
        buttons: ["Cancelar", "Confirmar"],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          
          Swal({
            title: "Tudo pronto!",
            text: `Você confirmou que recebeu o
            produto.`,
            icon: "success",
            buttons: ["Ok", false],
            dangerMode: true,
          });


          /*
            - Estado inicial de {buttonState.buttonConfirm} é {true}.
            - Caso o usuário confirme que recebeu o produto o estado de
            - {buttonState.buttonConfirm} é marcado como {false} e esconde o
            - componente <ListItem />
          */
          setButtonState({buttonConfirm: false})

        } else {
          Swal.close();
        }
      });

    }

    // * Material UI Tabs
    // * Props Tab
    function tabProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    // * Material UI Tabs
    // * On Change Tab
    const handleChange = (event, newValue) => {
      setTabValue(newValue);
    };


    return (
      <>
      <Helmet title="Scamb - Minhas compras" />

      <ModalDialog ref={refModalCancelProductImp} dialogTitle="Cancelar produto" subTitle="Antes de cancelar a compra observe:" className="_modal-action-product">

        <div className="_body-modal">

          <GreenList>
            <GreenListItem title="Todos os seus pontos serão estornados para sua carteira Scamb."></GreenListItem>
            <GreenListItem title="Valor do frete e da taxa Scamb serão estornados para.."></GreenListItem>
          </GreenList>

          <BoxPrint type="info" icon={<Icon icon={faBox} />}
                    title="Clicando no botão confirmar, sua solicitação será
                    processada e em até 12 horas enviaremos um
                    e-mail confirmando o cancelamento da compra.">
                    <Button
                      className="btn-submit"
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 45 : 55 }}
                      type="button"
                      onClick={handleConfirmCancelProduct}
                    >
                      Confirmar
                      <Icon icon={faCheck}></Icon>
                    </Button>
          </BoxPrint>

        </div>

      </ModalDialog>

      <ModalDialog ref={refModalCancelProduct} dialogTitle="Cancelar produto" subTitle="Selecione o motivo do cancelamento da compra abaixo." className="_modal-action-product">

      <form className="_form-modal-product" onSubmit={handleSubmit(onSubmitCancelProduct)} >

        <Checkbox title="Recebi o produto com um problema" alignCenter={true}>
          <input type="radio" id="reason_1" name="reason" ref={register({ required: true })} />
        </Checkbox>

        <Checkbox title="Recebi o pacote sem o produto" alignCenter={true}>
          <input type="radio" id="reason_2" name="reason" ref={register({ required: true })} />
        </Checkbox>

        <Checkbox title="O pacote nunca chegou" alignCenter={true}>
          <input type="radio" id="reason_3" name="reason" ref={register({ required: true })} />
        </Checkbox>

        <Checkbox title="Tenho um problema com o pagamento" alignCenter={true}>
          <input type="radio" id="reason_4" name="reason" ref={register({ required: true })} />
        </Checkbox>

        {errors.reason && <StatusMessage type="error" messageText="Você deve selecionar um dos tópicos acima antes de prosseguir." />}

        <Button
          className="btn-submit"
          color="primary"
          autoFocus
          style={{ height: matches ? 35 : 40, justifyContent: `center` }}
          type="submit"
          disabled={ !formState.dirty || (formState.dirty && !formState.isValid) }
        >
          Continuar
          <Icon icon={faChevronRight}></Icon>
        </Button>

      </form>

      </ModalDialog>

      <ModalDialog ref={refModalReturnProductImp} dialogTitle="Devolver produto" subTitle="Antes de fazer a devolução observe:" className="_modal-action-product">

        <div className="_body-modal">

          <GreenList>
            <GreenListItem title="O Produto deve estar sem uso, com todos os seus acessórios e no pacote original."></GreenListItem>
            <GreenListItem title="As devoluções serão conferidas para garantir conformidade em ambos os lados."></GreenListItem>
            <GreenListItem title="Valor da taxa e frete continuam sem o estorno até o vendedor dar ok no recebimento do produto ou  7 dias corridos após a entrega"></GreenListItem>
          </GreenList>

          <BoxPrint type="info" icon={<Icon icon={faBox} />}
                    title="Você devera ir a qualquer agencia dos correios para entregar o produto. Não esqueça de escrever o código no pacote.">
                    <Button
                      className="btn-submit"
                      color="primary"
                      autoFocus
                      style={{ height: matches ? 45 : 55 }}
                      type="button"
                    >
                      Impressão de etiqueta
                      <Icon icon={faPrint}></Icon>
                    </Button>
          </BoxPrint>

        </div>

      <Button
          className="btn-submit"
          color="primary"
          autoFocus
          style={{ height: matches ? 35 : 40, justifyContent: `center` }}
          type="submit"
          onClick={handleCloseModalReturnProduct}
        >
          Finalizar
          <Icon icon={faChevronRight}></Icon>
        </Button>
      </ModalDialog>

      <ModalDialog ref={refModalReturnProduct} dialogTitle="Devolver produto" subTitle="O que aconteceu com sua compra?" className="_modal-action-product">

      <form className="_form-modal-product" onSubmit={handleSubmit(onSubmit)} >

        <Checkbox title="O produto apresenta defeitos" alignCenter={true}>
          <input type="radio" id="reason_1" name="reason" ref={register({ required: true })} />
        </Checkbox>

        <Checkbox title="O pacote chegou incompleto" alignCenter={true}>
          <input type="radio" id="reason_2" name="reason" ref={register({ required: true })} />
        </Checkbox>

        <Checkbox title="É diferente do que comprei" alignCenter={true}>
          <input type="radio" id="reason_3" name="reason" ref={register({ required: true })} />
        </Checkbox>

        <Checkbox title="Eu não fiz essa compra" alignCenter={true}>
          <input type="radio" id="reason_4" name="reason" ref={register({ required: true })} />
        </Checkbox>

        {errors.reason && <StatusMessage type="error" messageText="Você deve selecionar um dos tópicos acima antes de prosseguir." />}

        <Button
          className="btn-submit"
          color="primary"
          autoFocus
          style={{ height: matches ? 35 : 40, justifyContent: `center` }}
          type="submit"
          disabled={ !formState.dirty || (formState.dirty && !formState.isValid) }
        >
          Continuar
          <Icon icon={faChevronRight}></Icon>
        </Button>

      </form>

      </ModalDialog>

      <Layout>
        <div id="my-purchases" className="_content">

        <div className="_wrap-title-and-tabs">

          <CurrentPage icon={<Icon icon={faShoppingCart} />} title="Minhas Compras" />

          <Tabs value={tabValue} onChange={handleChange} aria-label="Tabs My Purchases" className="_material-tabs">
            <Tab label="Em aberto" {...tabProps(0)} />
            <Tab label="Concluídas" {...tabProps(1)} />
          </Tabs>
        </div>

          {/* 
           /* Material UI Tab Panel - Em Aberto
          */}
          <TabPanel value={tabValue} index={0}>

              <Accordion
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image}
                      title="tênis cinza escuro mary jane"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Taxa em reais">
                    <Price value="10,00" />
                  </ListItem>

                  <ListItem title="Frete" className="flex">
                    <Price value="17,50"/>
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  {buttonState.buttonConfirm && (
                  <ListItem title="Confirmar recebimento">
                    <button type="button" className="btn-received" onClick={handleProductReceipt}>
                      Sim! recebi o produto
                      <span>
                        <Icon icon={faThumbsUp} />
                      </span>
                    </button>
                  </ListItem>
                  )}

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faCheck} />} title="Disponível" date="27/10/2020" hour="16h07"></Status>
                      <Status icon={<Icon icon={faCheck} />} title="Pagamento realizado" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                </ListContent>

                <AccordionInside title="Ajuda com a compra" open={false}>
                  <AccordionContent bgColor="#F9F9F9">

                    <button type="button" className="small-button">
                      Falar com o vendendor
                      <Icon icon={faCommentAltLines} />
                    </button>
                    
                    <button type="button" className="small-button" onClick={() => openDialogModal(refModalCancelProduct)}>
                      Cancelar produto
                      <Icon icon={faChevronRight} />
                    </button>

                    <button type="button" className="small-button" onClick={() => openDialogModal(refModalReturnProduct)}>
                      Devolver o produto
                      <Icon icon={faChevronRight} />
                    </button>

                  </AccordionContent>
                </AccordionInside>
              </Accordion>

              <Accordion
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image_2}
                      title="saia bege erre erre"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Taxa em reais">
                    <Price value="10,00" />
                  </ListItem>

                  <ListItem title="Frete" className="flex">
                    <Price value="17,50"/>
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  {buttonState.buttonConfirm && (
                  <ListItem title="Confirmar recebimento">
                    <button type="button" className="btn-received" onClick={handleProductReceipt}>
                      Sim! recebi o produto
                      <span>
                        <Icon icon={faThumbsUp} />
                      </span>
                    </button>
                  </ListItem>
                  )}

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faCheck} />} title="Disponível" date="27/10/2020" hour="16h07"></Status>
                      <Status icon={<Icon icon={faCheck} />} title="Pagamento realizado" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                  </ListContent>

                <AccordionInside title="Ajuda com a compra" open={false}>
                  <AccordionContent bgColor="#F9F9F9">

                    <button type="button" className="small-button">
                      Falar com o vendendor
                      <Icon icon={faCommentAltLines} />
                    </button>
                    
                    <button type="button" className="small-button" onClick={() => openDialogModal(refModalCancelProduct)}>
                      Cancelar produto
                      <Icon icon={faChevronRight} />
                    </button>

                    <button type="button" className="small-button" onClick={() => openDialogModal(refModalReturnProduct)}>
                      Devolver o produto
                      <Icon icon={faChevronRight} />
                    </button>

                  </AccordionContent>
                </AccordionInside>
              </Accordion>

          </TabPanel>
          
          {/* 
           /* Material UI Tab Panel - Concluídas
          */}
          <TabPanel value={tabValue} index={1}>

              {/* 
              /* productStatus possui 3 estados:
              /* productStatus="delivered"
              /* productStatus="canceled"
              /* productStatus="returned"
              */}

              <Accordion
                productStatus="delivered"
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image_3}
                      title="macaquinho vermelho"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Taxa em reais">
                    <Price value="10,00" />
                  </ListItem>

                  <ListItem title="Frete" className="flex">
                    <Price value="17,50"/>
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faCheck} />} title="Disponível" date="27/10/2020" hour="16h07"></Status>
                      <Status icon={<Icon icon={faCheck} />} title="Pagamento realizado" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                </ListContent>

              </Accordion>

              <Accordion
                productStatus="canceled"
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image_4}
                      title="melissa sneaker + fila"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Taxa em reais">
                    <Price value="10,00" />
                  </ListItem>

                  <ListItem title="Frete" className="flex">
                    <Price value="17,50"/>
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faCheck} />} title="Disponível" date="27/10/2020" hour="16h07"></Status>
                      <Status icon={<Icon icon={faCheck} />} title="Pagamento realizado" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                </ListContent>

              </Accordion>

              <Accordion
                productStatus="returned"
                child={
                  <AccordionCard>
                    <ProductThumbnail
                      image={image_5}
                      title="scrunchie xadrez preto e branca"
                      dateTitle="Data da compra"
                      date="00/00/2020"
                    />
                  </AccordionCard>
                }
              >
                <ListContent bgColor="#FFFFFF">

                  <ListItem title="Valor em pontos">
                    <PricePoints value="480" />
                  </ListItem>

                  <ListItem title="Taxa em reais">
                    <Price value="10,00" />
                  </ListItem>

                  <ListItem title="Frete" className="flex">
                    <Price value="17,50"/>
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    SCB-015456933415487
                  </ListItem>

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    
                    <Timeline>

                      <Status icon={<Icon icon={faCheck} />} title="Disponível" date="27/10/2020" hour="16h07"></Status>
                      <Status icon={<Icon icon={faCheck} />} title="Pagamento realizado" date="27/10/2020" hour="16h07"></Status>

                    </Timeline>
                  </ListItem>

                </ListContent>

              </Accordion>

          </TabPanel>

       </div>
      </Layout>
      </>
    );
  
}

export default MyPurchases;
