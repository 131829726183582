import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Breadcrumb from '../components/Breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Icons
import {
    faShoppingBag,
    faShoppingBasket,
    faMegaphone,
    faSearch } from '@fortawesome/pro-light-svg-icons';

// Images
import BrandIMG from '../assets/images/new-balance.png'
import Helmet from 'react-helmet'; 

class OfficialStores extends Component {

    render() {

        return (

            <section id="view_official-stores">
                <Helmet title="Scamb - Lojas oficiais" />
                <Breadcrumb></Breadcrumb>
                
                <div className="_med-container">
                    
                    <section className="_wrap-help-search">
                        <h1 className="_title">
                            Encontre sua loja preferida
                        </h1>
                        
                        <div className="_help-search">
                            {/* Search form */}
                            <div className="search-menu">
                                
                                <form className="form">
                                    <div className="form-group search">
                                        <input type="text" placeholder="Digite o que esta buscando" />
                                        <button type="submit">
                                            <FontAwesomeIcon icon={faSearch} className="fa-question-circle"/>
                                        </button>
                                    </div>
                                </form>
                                
                            </div>{/* END ./ Search form */}
                        </div>
                        
                    </section>
                    
                    <section className="_wrap-stores-boxes">
                    <h1 className="_title">
                    Lojas em destaque em <strong>Calçados, Roupas e Bolsas</strong>
                    </h1>
                    <Row className="_stores-boxes">
                        
                        <Col xs="6" md="4" lg="4">
                            <Link className="_store-box">
                                <img src={BrandIMG} alt="" />
                            </Link>

                        </Col>
                        
                        <Col xs="6" md="4" lg="4">
                            <Link className="_store-box">
                                <img src={BrandIMG} alt="" />
                            </Link>

                        </Col>
                        <Col xs="6" md="4" lg="4">
                            <Link className="_store-box">
                                <img src={BrandIMG} alt="" />
                            </Link>

                        </Col>
                        <Col xs="6" md="4" lg="4">
                            <Link className="_store-box">
                                <img src={BrandIMG} alt="" />
                            </Link>

                        </Col>
                        <Col xs="6" md="4" lg="4">
                            <Link className="_store-box">
                                <img src={BrandIMG} alt="" />
                            </Link>

                        </Col>
                        <Col xs="6" md="4" lg="4">
                            <Link className="_store-box">
                                <img src={BrandIMG} alt="" />
                            </Link>

                        </Col>

                    </Row>
                    </section>
                    
                    
                </div>
            </section>
        

        );

    };

};

export default OfficialStores;