import React, { Component } from "react";
import { Link } from "react-router-dom";
import Layout from '../../components/account/Layout';
import ActivityHistory from "../../components/account/wallet/ActivityHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Helmet from 'react-helmet';

// Icons
import {
  faCalendarAlt,
  faSearch,
} from "@fortawesome/pro-light-svg-icons";

class ActivityHistoryPage extends Component {
  render() {
    return (
      <Layout>
      <Helmet title="Scamb - Historico de atividade" />
      <div id="view_activity-history">
      <h1 className="_title-box _with-breadbrumb"><Link to="/minha-carteira">Minha Carteira</Link><span>/ Histórico de atividades</span></h1>
        <div className="_filter-activity">
          <form className="_form">
            <div class="_wrap-input-group">
              <label className="_title-label-input">Início</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="00/00/0000"
                  aria-describedby="cc-name"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text" id="cc-name">
                    <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
                  </span>
                </div>
              </div>
            </div>

            <div class="_wrap-input-group">
              <label className="_title-label-input">Fim</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="00/00/0000"
                  aria-describedby="cc-name"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text" id="cc-name">
                    <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
                  </span>
                </div>
              </div>
            </div>
          </form>

          <form className="form _search">
            <div className="form-group search">
              <input
                type="text"
                className="form-control"
                placeholder="Procurar em atividades"
              />
              <button type="submit">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="fa-question-circle"
                />
              </button>
            </div>
          </form>
        </div>

        <div className="_wrap-cards">
          <div className="ui-card _fluid _with-icon activity-history">
            <div className="ui-card-head _with-icon">
              <h1 className="ui-card-title">Histórico de atividades</h1>
            </div>

            <div className="ui-card-content">
              <ActivityHistory></ActivityHistory>
              <section className="_load-more-activities">
                    <Link className="link-button _load-more" to="/">Ver mais atividades</Link>
                </section>
            </div>
          </div>
        </div>
      </div>
        </Layout>
    );
  }
}

export default ActivityHistoryPage;
