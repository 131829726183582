import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';

// Icons
import { faTimes, faChevronRight } from '@fortawesome/pro-light-svg-icons';

// Data Categories
const categories_list = [
    {id: '5f2b397f4132b6c235b31490', category_name: "roupas e etc", category_link: "/resultados" },
    {id: '5f2b397f4132b6c235b314ed', category_name: "kids", category_link: "/resultados" },
    {id: '5f2b397f4132b6c235b31545', category_name: "beleza e saúde", category_link: "/resultados" },
    {id: '5f2b397f4132b6c235b3154a', category_name: "casa e decoração", category_link: "/resultados" },
    {id: '5f2b397f4132b6c235b31569', category_name: "outros", category_link: "/resultados" },
];

const ListItem = (props) => {
    return (
        <li>
            {props.children}
        </li>
    )
}

class SideMenuCategories extends Component {

    // Redirect categories
    search = (e, idCategory) =>{
        e.preventDefault();
        Swal.showLoading();
        Swal.fire('Aguarde...');
        
        this.props.history.push({pathname:'/resultados', state: {category:idCategory, newSearch:true}});
        
    }

    componentDidMount() {

    }

    render () {

        
        return (
            
            <div id="aside" className={"aside-menu _leftwards "+this.props.className}>
                
                <div className="aside-menu-header">
                    Categorias
                    <button type="button" id="close" className="btn">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                
                <nav className="aside-nav">
                    <ul>

                    {categories_list.map(({id, category_name}) => (
                        <ListItem key={id}>
                            <a href="#!" onClick={(e)=>this.search(e,id)}>
                                {category_name}
                            </a>
                        </ListItem>
                    ))}
                    </ul>
                </nav>
            </div>
              
        );
        
    };
    
};

export default withRouter(SideMenuCategories);