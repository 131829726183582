import React, { Component } from 'react';
import { Link,BrowserRouter, Route, withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

import TopCategoryIMG1 from '../assets/images/top-category-1.png';
import TopCategoryIMG2 from '../assets/images/top-category-2.png';
import TopCategoryIMG3 from '../assets/images/top-category-3.png';
import TopCategoryIMG4 from '../assets/images/top-category-4.png';
import TopCategoryIMG5 from '../assets/images/product_2.png';
import Swal from 'sweetalert2';
// Services
import Ad from '../services/Ad';

const top_categories = [
  
    { id: '5f2b397f4132b6c235b31490', src: TopCategoryIMG1, description: 'renove tudo!', category: 'Roupas e etc..', color: '_red' },
    { id: '5f2b397f4132b6c235b314ed', src: TopCategoryIMG3, description: 'De tudo', category: 'Kids', color: '_blue' },
    { id: '5f2b397f4132b6c235b3154a', src: TopCategoryIMG2, description: 'tudo para seu lar', category: 'Casa e decoração', color: '_orange' },
    { id: '5f2b397f4132b6c235b31545', src: TopCategoryIMG4, description: 'Se cuidar faz bem!', category: 'Beleza e saúde', color: '_violet' },
    { id: '5f2b397f4132b6c235b31569', src: TopCategoryIMG5, description: 'Um pouco mais', category: 'Outros', color: '_blue-light' }
    
];


class TopCategories extends Component {

    constructor(){
        super();
        this.ad = new Ad();
        this.state ={
            limit:8
        }
    }

    search = (e, idCategory) =>{
        e.preventDefault();
        Swal.showLoading();
        Swal.fire('Aguarde...')

        this.props.redirect({category:idCategory, newSearch:true});
    }

    
    render() {
        
        return (
        
            <div id="top-categories">
                
                {top_categories.map(({id, src, description, category, color}) =>
                    
                    <div className="_grid" key={id}>
                        
                        <a href="#!" onClick={(e)=>this.search(e,id)} id={id} className={"_grid-col _bg-color " + color}>
                            <div className="_grid-col-head">
                                <span className="_color _white">{description}</span>
                                <h1  className="_color _white">{category}</h1>
                            </div>

                            <img src={src} alt={description} />
                        </a>
                                        
                    </div>
                                    
                )}
            </div>
            
            
            
            
        )
        
    };
    
};

export default TopCategories;