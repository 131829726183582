import React, { Component } from "react";
import { Link } from "react-router-dom";

// Alert
import Swal from "sweetalert";
import Swal2 from "sweetalert2";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";

// Services
import Favorito from '../services/Favorito';


class FavoritesList extends Component {

  constructor(){
    super();
    this.favorito = new Favorito();
    this.state = {
      'favoritos':[]
    }
  }

  componentDidMount(){
    
  }


  render() {

    const confirmDelete = () => {

      // Swal2.fire('Aguarde...');
      // Swal2.showLoading();
      this.favorito.delete(this.props.idFavorite)
        .then((response) => {Swal({title: "Anuncio removido dos favoritos!", icon: "success", buttons: 'Ok', dangerMode: true}); this.props.refresh()})
        .catch((error) => Swal.fire({type: "info", html: this.favorito.formatErrorMessage(error.response),title: ""}));

      }

    const deleteInfo = () => {

        Swal({
          title: "Deletar favorito?",
          icon: "warning",
          buttons: [
            'Fechar',
            'Confirmar'
          ],
          dangerMode: true,
        }).then(function(isConfirm) {
          if (isConfirm) {
            confirmDelete()
          }
        });
      }

    return (
      <li>
        <Link to={this.props.link} className="product">
          <div className="product-image">
            <img src={this.props.image} alt=""></img>
          </div>
          <div className="product-info">
            <div className="product-info-title"> {this.props.title} </div>
            <div className="_product-price _1x">
              {/* <div className="ui-item__discount-price">
                <span className="price-tag-fraction"> {this.props.oldPrice} </span>
                <span className="price-tag-symbol">pts</span>
              </div> */}
              <div className="ui-item__price">
                <span className="price-tag-fraction"> {this.props.price} </span>
                <span className="price-tag-symbol">pts</span>
              </div>
            </div>
          </div>
        </Link>

        <div className="_actions">
          <button type="button" className="btn btn-transparent" onClick={deleteInfo}>
            <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
          </button>
        </div>
      </li>
    );
  }
}

export default FavoritesList;
