import React, { Component } from "react";
import Breadcrumb from "../components/Breadcrumbs";

// Bootstrap
import Table from "react-bootstrap/Table";
import Helmet from 'react-helmet'; 

class TermsConditions extends Component {
  render() {
    return (
      <section id="view_common-page">
        <Helmet title="Scamb - Termos e condições" />
        <Breadcrumb></Breadcrumb>

        <div className="_med-container">
          <div className="_terms-conditions-content">
            <h1 className="headline">Termos e condições gerais de uso</h1>

            <div className="_tag-date">Ultima Atualização: 21/10/2020</div>

            <div className="_wrap-topic _first">
              <h1 className="_topic-title">
                Sumário dos termos e condições gerais de uso do Scamb.
              </h1>
              <p className="_topic-content">
                Ao usuário do Scamb,
                <br />
                Essas informações são necessárias para auxiliar você, usuário, a
                operar em nossa plataforma. É imprescindível que conheça nossas
                políticas e, portanto, é necessária a leitura, compreensão e
                aceitação destes Termos e Condições Gerais de Uso.
                <br />
                <br />
                Nesse primeiro momento, estão destacadas as cláusulas que
                possibilitam o uso da plataforma e as limitativas de direitos
                para que você as compreenda de forma fácil e rápida.
              </p>
              <ul>
                <li>
                  <strong>1.</strong> O Scamb não é fornecedor de quaisquer produtos anunciados.
                </li>
                <li>
                <strong>2.</strong> O Scamb é sim prestador de serviço de intermediação, ou seja,
                  oferece uma plataforma na internet que fornece espaço para que
                  usuários cadastrados realizem a compra e venda de produtos
                  diretamente entre si.
                </li>
                <li>
                <strong>3.</strong> Os usuários somente poderão anunciar produtos que estejam em
                  sua posse para entrega imediata, colocando todas as
                  informações necessárias sobre o produto no anúncio, como
                  exemplo: características, condições do produtos, entre outros.
                </li>
                <li>
                <strong>4.</strong> Para utilização da plataforma Scamb, o usuário deverá efetuar
                  um cadastro com e-mail e senha que são pessoais e
                  intransferíveis. O Scamb não se responsabiliza pelo uso
                  inadequado e divulgação desses dados para terceiros.
                </li>
                <li>
                <strong>5.</strong> O Scamb, por motivo de violação à legislação ou a este Termo e
                  Condições Gerais de Uso, conforme o caso, poderá recusar a
                  solicitação ou excluir a conta de um usuário já existente.
                </li>
                <li>
                <strong>6.</strong> Não é permitido ao usuário anunciar produtos expressamente
                  proibidos por lei ou aqueles previstos no ANEXO 1 destes
                  Termos e Condições Gerais de Uso.
                </li>
                <li>
                <strong>7.</strong> O Scamb indica aos usuários, sempre antes de decidir pela
                  compra, atentar-se às informações do produto anunciado, como
                  condições, disponibilidade, preço, forma de pagamento e de
                  entrega.
                </li>
              </ul>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">
                Termos e condições gerais de uso do Scamb
              </h1>
              <p className="_topic-content">
                Estes Termos e Condições Gerais de Uso aplicam-se ao uso do
                serviço oferecido por SCAMB ATIVIDADES DE INTERNET E PRESTAÇÃO
                DE SERVIÇOS LTDA, inscrita no Cadastro Nacional de Pessoas
                Jurídicas sob o nº ***, com sede na Alameda Ministro Rocha
                Azevedo, nº 912, complemento E37, Jardim Paulista, CEP
                01410-002, na cidade de São Paulo (SP), doravante denominada
                Scamb.
                <br />
                <br />
                Qualquer pessoa que pretenda utilizar os serviços do Scamb
                deverá aceitar estes Termos e Condições Gerais de Uso e todas as
                demais políticas e princípios que regem, doravante denominado
                usuário ou Scamber.
                <br />
                <br />O Scamb poderá, a qualquer momento, modificar, alterar ou
                excluir parte do conteúdo do presente termo, visando seu
                aprimoramento e a melhoria dos serviços prestados, razão pela
                qual recomenda-se a leitura a cada acesso do usuário a
                plataforma.
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">
                <strong>1.</strong> Cláusula primeira: o objeto.
              </h1>
              <p className="_topic-content">
                <strong>1.1.</strong> O presente termo regula os serviços de
                intermediação oferecidos pelo Scamb, que consistem em:
              </p>
              <ul>
                <li>
                  <strong>1.1.1.</strong> Ofertar e hospedar espaços na
                  plataforma para que os usuários anunciem para venda os
                  próprios produtos, sejam eles novos, seminovos ou usados;
                </li>
                <li>
                  <strong>1.1.2.</strong> Viabilizar o contato direto entre
                  usuários interessados em adquirir os produtos ofertados, ou
                  seja, o Scamb possibilita aos usuários se contatarem e
                  negociarem diretamente entre si, obrigatoriamente dentro da
                  plataforma, sendo vedado qualquer outro meio de comunicação,
                  mas sem qualquer intervenção na negociação ou concretização do
                  negócio de compra e venda.
                </li>
                <li>
                  <strong>1.2.3.</strong> Oportunizar que os usuários comprem
                  cupons de descontos em empresas parceiras utilizando os pontos
                  adquiridos na plataforma, mas sem qualquer interferência do
                  Scamb.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>1.2.</strong> As negociações realizadas na plataforma
                serão de responsabilidade exclusiva dos usuários, quem as
                realizam por sua conta e risco, sem qualquer intervenção e
                responsabilidade do Scamb.
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">
                <strong>2.</strong> Cláusula segunda: o cadastro do
                usuário/scamber.
              </h1>
              <p className="_topic-content">
                <strong>2.1. </strong> O cadastro para uso da plataforma somente
                poderá ser efetuado pelos usuários que preencham os seguintes
                requisitos:
              </p>
              <ul>
                <li>
                  <strong>2.1.1.</strong> Ser maior de 18 (dezoito) anos e capaz
                  perante a legislação brasileira (observância aos artigos 4º e
                  5º, do Código Civil);
                </li>
                <li>
                  <strong>2.1.2.</strong> Ser devidamente inscrito no Cadastro
                  de Pessoas Físicas;
                </li>
                <li>
                  <strong>2.1.3.</strong> Possuir cartão de crédito e/ou débito
                  válido;
                </li>
                <li>
                  <strong>2.1.4.</strong> Possuir um endereço de e-mail válido.
                </li>
                <li>
                  <strong>2.1.5.</strong> Informar endereço residencial e/ou
                  comercial e número de telefone válido.
                </li>
              </ul>
              <p className="_topic-content">
                <strong>2.2. </strong> O usuário, ao efetuar o cadastro na
                plataforma, deverá preencher todos os campos exigidos, assumindo
                o compromisso de atualizar os dados pessoais fornecidos sempre
                que sofrerem qualquer alteração.
              </p>
              <p className="_topic-content">
                <strong>2.3. </strong> O usuário garante que fornecerá
                informações exatas e verdadeiras, não havendo responsabilidade
                de o Scamb pela verificação dos dados pessoais fornecidos pelos
                usuários, respondendo esses, em qualquer caso, civil e
                criminalmente pela veracidade e autenticidade das informações
                fornecidas no cadastro à plataforma.
              </p>
              <ul>
                <li>
                  <strong>2.3.1.</strong> O Scamb poderá, quando julgar
                  necessário, realizar buscas para apuração de dados incorretos
                  ou inverídicos inseridos pelos usuários, bem como, solicitar
                  documentos adicionais com a finalidade de conferir os dados
                  pessoais informados pelo usuário.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>2.4. </strong> O Scamb assegura-se no direito de recusar
                qualquer pedido de cadastro, assim como suspender ou cancelar
                cadastro anteriormente aceito, se verificar:
              </p>
              <ul>
                <li>
                  <strong>2.4.1.</strong> A inobservância a estes Termos e
                  Condições de Uso;
                </li>
                <li>
                  <strong>2.4.2.</strong> A prática de atos fraudulentos ou
                  dolosos;
                </li>
                <li>
                  <strong>2.4.3.</strong> A inconsistência nas informações
                  fornecidas que não permitir a verificação da identidade do
                  usuário;
                </li>
                <li>
                  <strong>2.4.4.</strong> Quaisquer outros atos que estiver em
                  desacordo com a política da plataforma.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>2.5. </strong> O usuário poderá, a qualquer tempo, sem a
                necessidade de justificação, solicitar a exclusão de seu
                cadastro do Scamb. Para tanto, terá que enviar uma solicitação
                através da plataforma, a qual será analisada no prazo de 15
                (quinze) dias contados do envio da solicitação, a fim de
                verificar a existência de alguma transação ainda pendente.
              </p>
              <ul>
                <li>
                  <strong>2.5.1.</strong> Aceita a solicitação e efetivada a
                  exclusão do cadastro do usuário, este declara estar ciente que
                  os pontos existentes em sua carteira também serão excluídos,
                  não podendo ser retomados em nenhum hipótese.
                </li>
                <li>
                  <strong>2.5.2.</strong> Caso não haja pendências relacionadas
                  a compras ou vendas, o Scamb excluirá o cadastro com todos os
                  dados pessoais dos usuários, mas manterá em arquivo as
                  informações sobre as transações realizadas, conforme
                  autorização da legislação civil.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>2.6. </strong> O Scamb, nem quaisquer de seus empregados
                ou prepostos solicitará, por qualquer meio físico ou eletrônico,
                que o usuário informe sua senha de acesso criada no momento do
                cadastro.
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">
                <strong>3.</strong> Cláusula terceira: do anúncio
              </h1>
              <p className="_topic-content">
                <strong>3.1.</strong> O usuário pode anunciar quantos produtos
                quiser, sendo o anúncio de sua exclusiva responsabilidade,
                obrigando-se a:
              </p>

              <ul>
                <li>
                  <strong>3.1.1.</strong> Ter em sua posse o produto para
                  entrega imediata, assim que o anúncio for realizado na
                  plataforma, bem como, indicar a quantidade do produto
                  ofertado;
                </li>
                <li>
                  <strong>3.1.2.</strong> Não realizar propagandas enganosas que
                  possam induzir o comprador a erro quanto ao produto anunciado
                  e não praticar atos abusivos, respeitando os ditames do Código
                  de Defesa do Consumidor;
                </li>
                <li>
                  <strong>3.1.3.</strong> Informar de forma clara todas as
                  verdadeiras características e condições do produto, assim como
                  o preço e a forma de pagamento e entrega.
                </li>
                <li>
                  <strong>3.1.4.</strong> Utilizar somente fotografias do
                  produto de sua própria autoria, e que correspondem a fiel
                  reprodução do produto, sendo vedada a utilização de fotos de
                  outros usuários ou retiradas de sites na internet, assim como
                  o uso de montagens e marca d’agua, sob pena de reprovação ou
                  exclusão do anúncio pelo Scamb.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>3.2.</strong> Os usuários podem anunciar produtos cujo
                comércio não seja expressamente proibido pela legislação
                brasileira vigente e por estes Termos e Condições de Uso, os
                quais estão listados no ANEXO 1. Assim, ao realizar um anúncio,
                o usuário declara e assume a responsabilidade de que o produto
                não consta na listagem de produtos proibidos no Scamb.
              </p>

              <p className="_topic-content">
                <strong>3.3.</strong> O usuário se responsabiliza integralmente
                pela qualidade, procedência, originalidade, bem como pela
                entrega do produto anunciado, inexistindo qualquer
                responsabilidade do Scamb nesse sentido.
              </p>

              <p className="_topic-content">
                <strong>3.4.</strong> O usuário declara ter conhecimento e
                assume a responsabilidade de que ao anunciar produtos em que a
                certificação pela Instituto Nacional de Metrologia, Qualidade e
                Tecnologia – INMETRO for obrigatória/compulsória, o selo do
                INMETRO deverá estar reproduzido expressamente no anúncio.
              </p>

              <p className="_topic-content">
                <strong>3.5.</strong> O Scamb assegura-se no direito de excluir
                ou cancelar qualquer anúncio que julgar estar em desacordo com
                estes Termos e Condições Gerais de Uso, bem como, com
                inobservância a legislação civil, sem o dever de indenizar ou
                ressarcir o usuário anunciante de qualquer forma e sem prejuízo
                da aplicação das sanções aqui previstas.
              </p>

              <ul>
                <li>
                  <strong>3.5.1.</strong> Após a inserção do produto na
                  plataforma, o Scamb tem o prazo de 48h (quarenta e oito horas)
                  para verificar se o anúncio está em total conformidade com
                  estes Termos e Condições Gerais de Uso, sendo que, caso,
                  verificada alguma irregularidade, o usuário será notificado
                  via e-mail com os fundamentos da exclusão do anúncio.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>3.6.</strong> O usuário ao inserir o anúncio, deverá
                escolher pelas opções de envio disponíveis na plataforma,
                conforme discriminado na cláusula oitava destes Termos e
                Condições Gerais de Uso.
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">
                <strong>4.</strong> Cláusula quarta: da comunicação entre os
                usuários.
              </h1>
              <ul>
                <li>
                  <strong></strong>
                </li>
              </ul>

              <p className="_topic-content">
                <strong>4.1.</strong> As fases de negociações acerca dos
                produtos anunciados no Scamb deverão, obrigatoriamente, ocorrer
                dentro na plataforma através do campo “Iniciar Chat”. Sendo
                proibido aos usuários utilizarem quaisquer outros veículos de
                comunicação para concluir a compra e venda do produto anunciado.
              </p>

              <p className="_topic-content">
                <strong>4.2.</strong> Não é permitido aos usuários inserir ou
                manter no anúncio do produto dados pessoais para contato direto,
                tais como, mas não se limitando a, números de telefones,
                endereços de e-mails, páginas de internet ou perfil de rede
                social, bem como, dados bancários.
              </p>

              <ul>
                <li>
                  <strong>4.1.2.</strong> Todas as fases da negociação devem ser
                  integralmente realizadas pelos usuários dentro da plataforma
                  do Scamb, sendo vedado qualquer divulgação de contato pessoal
                  entre os usuários durante a negociação.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>4.3.</strong> Os usuários declaram ter ciência que o
                Scamb não possui qualquer responsabilidade pelo conteúdo das
                comunicações, assim como não tem obrigação de monitorar esse
                conteúdo.
              </p>

              <ul>
                <li>
                  <strong>4.3.1.</strong> O Scamb reserva ao direito de moderar
                  a seção de perguntas e respostas e eliminar mensagens,
                  palavras e expressões consideradas agressivas, caluniosas,
                  injuriosas e difamatórias ou que violem qualquer outra regra
                  destes Termos e Condições Gerais de Uso.
                </li>
              </ul>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">
                <strong>5.</strong> Cláusula quinta: dos pontos.
              </h1>

              <ul>
                <li>
                  <strong></strong>
                </li>
              </ul>

              <p className="_topic-content">
                <strong>5.1.</strong> Os usuários movimentam as transações
                dentro da plataforma do Scamb exclusivamente com pontos. Os
                pontos podem ser utilizados para compra de produtos anunciados
                na plataforma por outros usuários/Scambers ou para compra de
                cupons de descontos junto aos parceiros do Scamb, nos termos da
                cláusula décima.
              </p>

              <p className="_topic-content">
                <strong>5.2.</strong> Os pontos podem ser adquiridos pelos
                usuários de quatro formas diferentes:
              </p>

              <ul>
                <li>
                  <strong>5.2.1.</strong> Quando o usuário efetua a venda de um
                  produto anunciado, recebe os pontos do usuário comprador dessa
                  operação;
                </li>
                <li>
                  <strong>5.2.2.</strong> Os usuários podem comprar pontos com
                  dinheiro;
                </li>
                <li>
                  <strong>5.2.3.</strong> O Scamb realiza promoções aos
                  usuários, possibilitando o ganho de pontos;
                </li>
                <li>
                  <strong>5.2.4.</strong> Os usuários podem realizar a
                  transferência de pontos entre si.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>5.3.</strong> O valor do ponto é variável de acordo com
                alguns fatores determinados pelo Scamb, como promoções válidas,
                demandas e quantidade de pontos de usuários deseja comprar,
                sendo calculado automaticamente no momento da compra. Todavia, o
                saldo de pontos existentes da carteira do usuário não se altera.
              </p>

              <p className="_topic-content">
                <strong>5.4.</strong> O usuário, ao cadastrar o anúncio,
                precifica o produto e automaticamente a plataforma converte o
                preço em dinheiro para pontos, baseado exclusivamente no preço
                informado pelo usuário vendedor, ou seja, o Scamb não determina
                o preço do produto, somente realiza a conversão.
              </p>

              <p className="_topic-content">
                <strong>5.5.</strong> A compra de pontos com dinheiro será
                processada por ******, pessoa jurídica de direito privado,
                inscrita no Cadastro Nacional de Pessoas Jurídicas sob o nº
                *****, empresa independente, responsável peça gestão de
                pagamentos on-line, devidamente regulamentada pelo Banco Central
                do Brasil.
              </p>

              <ul>
                <li>
                  <strong>5.5.1.</strong> O usuário do Scamb, ao concordar com
                  os presentes Termos e Condições Gerais de Uso,
                  automaticamente, concordam com os Termos e Condições Gerais de
                  Uso da empresa gestora de pagamento, os quais estão
                  disponíveis em ********** (importante inserir o link direito
                  para os Termos de Uso da empresa gestora de pagamento, pois
                  facilita o acesso ao usuário).
                </li>
                <li>
                  <strong>5.5.2.</strong> A empresa ****** é integralmente
                  responsável pela realização das transações financeiras,
                  prestando o serviço de captura, processamento, roteamento,
                  liquidação e gestão dos pagamentos. O Scamb figura tão somente
                  como intermediador entre o usuário que estará comprando os
                  pontos e o sistema operacional da empresa.
                </li>
                <li>
                  <strong>5.5.3.</strong> Os usuários declaram ter ciência que
                  para garantir a realização e integridade das transações
                  financeiras, os dados fornecidos pelo usuário quando do
                  cadastramento ao Scamb, serão compartilhados com a empresa
                  gestora de pagamentos on-line.
                </li>
                <li>
                  <strong>5.5.4.</strong> A empresa ***** se responsabiliza pela
                  segurança e sigilo dos dados dos usuários compartilhados
                  através do Scamb e se compromete, contratualmente, a não
                  utilizar os dados dos usuários para quaisquer finalidades
                  diferentes daquelas estabelecidas nestes Termos e Condições
                  Gerais de Uso.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>5.6.</strong> Quando efetivada a compra dos pontos com
                dinheiro através da empresa gestora de pagamento on-line, os
                pontos adquiridos imediatamente serão transferidos para a
                carteira do usuário.
              </p>

              <p className="_topic-content">
                <strong>5.7.</strong> Os usuários podem realizar transferência
                de pontos entre si. Para tanto, o Scamb cobrará uma taxa de
                transferência calculada em cima da quantidade de pontos que
                serão transferidos, conforme tabela abaixo:
              </p>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Quantidade de pontos transferidos</th>
                    <th>Taxa de transferência</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1 – 99 pontos</td>
                    <td>R$ 3,90</td>
                  </tr>
                  <tr>
                    <td>100 – 199 pontos</td>
                    <td>R$ 5,90</td>
                  </tr>
                  <tr>
                    <td>200 – 499 pontos</td>
                    <td>R$ 9,90</td>
                  </tr>
                  <tr>
                    <td>500 – 799 pontos</td>
                    <td>R$ 12,90</td>
                  </tr>
                  <tr>
                    <td>800 – 999 pontos </td>
                    <td>R$ 14,90</td>
                  </tr>
                  <tr>
                    <td>mais de 1.000 pontos*</td>
                    <td>R$ 19,90</td>
                  </tr>
                </tbody>
              </Table>

              <ul>
                <li>
                  <strong>5.7.1.</strong> Cada usuários pode realizar até 10
                  (dez) transferências por ano.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>5.8.</strong> Os usuários têm prazos para utilizar os
                pontos existentes em suas carteiras dentro da plataforma, pois
                os pontos têm prazos de validade, os quais variam de acordo com
                a forma em que foram adquiridos, conforme tabela a seguir:
              </p>

              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Forma de aquisição dos pontos</th>
                    <th>Prazo de validade</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Pela venda de produtos anunciados</td>
                    <td>2 anos a partir da data da aquisição</td>
                  </tr>
                  <tr>
                    <td>Pontos comprados</td>
                    <td>2 anos a partir da data da compra</td>
                  </tr>
                  <tr>
                    <td>Pontos bonificados</td>
                    <td>4 meses a partir do recebimento</td>
                  </tr>
                  <tr>
                    <td>Pontos transferidos </td>
                    <td>Mantém a validade original</td>
                  </tr>
                </tbody>
              </Table>

              <p className="_topic-content">
                <strong>5.9.</strong> Os usuários declaram ter ciência de que
                depois de transcorrido o prazo de validade, os pontos expiram de
                sua carteira, não podendo ser recuperados em nenhuma hipótese.
              </p>

              <p className="_topic-content">
                <strong>5.10.</strong> Em caso de solicitação pelo usuário de
                exclusão do cadastro da plataforma, todos os pontos existentes
                em sua carteira serão perdidos, ou seja, caso o usuário efetue
                novo cadastro posteriormente, não recupera os pontos.
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">
                <strong>6.</strong> Cláusula sexta: da remuneração pelo uso da
                plataforma. Taxa Scamb.
              </h1>

              <p className="_topic-content">
                <strong>6.1.</strong> Pelo desenvolvimento das atividades de
                intermediação e para manutenção da plataforma, o Scamb cobrará
                do usuário COMPRADOR uma taxa incidente sobre cada transação
                realizada, ou seja, a taxa é devida somente quando a compra e
                for efetivada.
              </p>

              <p className="_topic-content">
                <strong>6.2.</strong> O valor da taxa é variável de acordo com o
                valor de pontos da transação, conforme a seguinte tabela:
              </p>

              <Table className="mbottom" striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>
                      Valor do produto em <strong>pontos</strong>
                    </th>
                    <th>
                      Taxa Scamb <strong>(R$)</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1 – 99 pontos</td>
                    <td>R$ 3,90</td>
                  </tr>
                  <tr>
                    <td>100 – 199 pontos</td>
                    <td>R$ 5,90</td>
                  </tr>
                  <tr>
                    <td>200 – 499 pontos</td>
                    <td>R$ 9,90</td>
                  </tr>
                  <tr>
                    <td>500 – 799 pontos</td>
                    <td>R$ 12,90</td>
                  </tr>
                  <tr>
                    <td>800 – 999 pontos </td>
                    <td>R$ 14,90</td>
                  </tr>
                  <tr>
                    <td>mais de 1.000 pontos*</td>
                    <td>R$ 19,90</td>
                  </tr>
                </tbody>
              </Table>
              <span className="_11px">(*) limite de 10 itens por pedido.</span>
              <br />
              <br />

              <p className="_topic-content">
                <strong>6.3.</strong> A taxa Scamb será cobrada do usuário
                COMPRADOR no momento da compra. Nesse momento, o usuário
                comprador pagará a taxa Scamb pelo meio de pagamento já
                cadastrado na plataforma.
              </p>

              <p className="_topic-content">
                <strong>6.4.</strong> O usuário, ao aceitar estes Termos e
                Condições de Uso, declara ter conhecimento da cobrança da taxa
                Scamb, conforme os preços da tabela do item anterior, incidente
                em cada transação em que figurar como usuário COMPRADOR.
              </p>

              <ul>
                <li>
                  <strong>6.4.1</strong> Ao usuário, quando figurar como
                  vendedor, não será cobrado nenhum valor à título de
                  remuneração da plataforma, pois a taxa é paga exclusivamente
                  pelo usuário COMPRADOR.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>6.5.</strong> O usuário está ciente que em caso em
                devolução do produto, por qualquer motivo, não haverá a
                devolução da taxa Scamb.
              </p>

              <p className="_topic-content">
                <strong>6.6.</strong> A taxa Scamb não poderá ser paga com
                pontos.
              </p>

              <p className="_topic-content">
                <strong>6.7.</strong> O pagamento da Taxa Scamb pelo usuário
                COMPRADOR será também processada por ******, pessoa jurídica de
                direito privado, inscrita no Cadastro Nacional de Pessoas
                Jurídicas sob o nº *****, empresa independente, responsável pela
                gestão de pagamentos on-line, devidamente regulamentada pelo
                Banco Central do Brasil, nos exatos termos do item 5.5.
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">
                <strong>7.</strong> Cláusula sétima: das opções de envio. Prazos
                e condições.
              </h1>

              <p className="_topic-content">
                <strong>7.1.</strong> Os usuários do Scamb têm disponíveis duas opções para envio dos produtos anunciados. O usuário VENDEDOR, ao disponibilizar o anúncio na plataforma escolherá qual será a forma de envio, são elas: “entrega em mãos” ou “frete via Correios”.
              </p>

              <ul>
                <li>
                  <strong>7.1.1.</strong> O usuário COMPRADOR se responsabilizar por atentar-se a forma de envio escolhida pelo usuário vendedor no anúncio, pois, a forma de envio do produto não poderá ser alterada. 
                </li>
              </ul>

              <p className="_topic-content">
                <strong>7.2.</strong> Quando o usuário VENDEDOR optar pelo “frete via Correios”, o usuário COMPRADOR se responsabiliza pelo pagamento do valor do frete no momento da compra, o qual é calculado com base na distância entre o usuário vendedor e o usuário comprador e o peso do produto.
              </p>

              <ul>
                <li>
                  <strong>7.2.1.</strong> O frete não poderá ser pago com pontos, portanto, o usuário COMPRADOR realizará o pagamento através do meio cadastrado na plataforma. 
                </li>
              </ul>

              <p className="_topic-content">
                <strong>7.3.</strong> Nessa modalidade, o usuário vendedor, deverá enviar o produto para o usuário comprador no prazo de 7 (sete) dias corridos a partir da efetivação da compra, utilizando a etiqueta de postagem e a declaração de conteúdo, enviadas pelo Scamb para seu e-mail de cadastro.
              </p>

              <ul>
                <li>
                  <strong>7.3.1.</strong> No momento da embalagem, o usuário VENDEDOR deve, obrigatoriamente, fotografar o produto e a embalagem e armazenar as fotografias para, caso solicitadas, sejam apresentadas, a fim de comprovar as condições do produto e da embalagem no momento da postagem. 
                </li>
              </ul>

              <p className="_topic-content">
                <strong>7.4.</strong> O cumprimento do prazo de entrega do produto, estabelecido no momento da postagem, é responsabilidade exclusiva dos Correios. Da mesma forma, os Correios serão os únicos responsáveis em caso de extravio da mercadoria.
              </p>

              <ul>
                <li>
                  <strong>7.4.1.</strong> O Scamb não se responsabiliza pela entrega ou pelo estado em que o produto adquirido chegue ao usuário comprador. 
                </li>
              </ul>

              <p className="_topic-content">
                <strong>7.5.</strong> Na hipótese de os Correios não conseguirem entregar o produto ao usuário comprador, por qualquer motivo, o produto retornará ao endereço do usuário VENDEDOR. Mas, após 3 (três) tentativas de entrega ao usuário VENDEDOR, sem sucesso, os Correios manterão a mercadoria armazenada para retirada durante o prazo máximo de 120 (cento e vinte dias). Decorrido referido período, os Correios encaminharão o produto para destruição. CONFIRMAR COM PARCEIRO LOGÍSTICO
              </p>

              <ul>
                <li>
                  <strong>7.5.1.</strong> O Scamb não controla esse prazo, nem se responsabiliza por qualquer eventual dano decorrente desse procedimento.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>7.6.</strong> Quando o usuário VENDEDOR optar pela “entrega em mãos”, a partir da efetivação da compra, combinará com o usuário COMPRADOR, através do chat de comunicação dentro da plataforma do Scamb, os termos da entrega do produto.
              </p>

              <ul>
                <li>
                  <strong>7.6.1.</strong> Os usuários se obrigam a realizar a “entrega em mãos” dentro do prazo de até 21 (vinte e um ) dias corridos, contados a partir da efetivação da compra.
                </li>
              </ul>

              <p className="_topic-content">
                <strong>7.7.</strong> Os pontos somente serão transferidos para a carteira do usuário VENDEDOR, após o usuário COMPRADOR confirmar o recebimento do produto na plataforma. 
              </p>

              <ul>
                <li>
                  <strong>7.7.1.</strong> O Usuário COMPRADOR tem 7 (sete) dias corridos do recebimento do produto para aceitar ou recusar dentro da plataforma. Caso discorra esse prazo sem manifestação, o produto será considerado como aceito e os pontos serão transferidos para a carteira do usuário VENDEDOR. 
                </li>
              </ul>
              
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title"><strong>8.</strong> Cláusula oitava: do cancelamento. </h1>
              
              <p className="_topic-content">
                <strong>8.1.</strong> Os usuários poderão desistir da compra ou venda antes do envio do produto, para tanto, deverão solicitar o cancelamento através do campo disponível na plataforma, o qual deverá ser realizado dentro do prazo de 7 (sete) dias corridos contados a partir da efetivação da compra ou antes do envio do produto pelo vendedor. 
              </p>

              <ul>
                <li>
                  <strong>8.1.1.</strong> Transcorrido o prazo estipulado nesta cláusula, ou efetivado o envio pelo vendedor, o cancelamento não poderá mais ser solicitado, uma vez que o produto já deverá ter sido encaminhado, portanto, deverá ser solicitada a devolução, nos termos da cláusula nona. 
                </li>
              </ul>
              
              <p className="_topic-content">
                <strong>8.2.</strong> Em casos de cancelamento, os pontos envolvidos na transação não serão transferidos à carteira do usuário VENDEDOR e o valor pago referente ao frete e a Taxa Scamb serão integralmente restituídos ao usuário COMPRADOR. 
              </p>
              
              <p className="_topic-content">
                <strong></strong>
                
              </p>
              
              <p className="_topic-content">
                <strong></strong>
                
              </p>
              
              <p className="_topic-content">
                <strong></strong>
                
              </p>

              <ul>
                <li>
                  <strong></strong>
                </li>
              </ul>

            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title"><strong>9.</strong> Cláusula nona: das devoluções.</h1>
              
              <p className="_topic-content">
                <strong>9.1.</strong> O usuário COMPRADOR tem o prazo de 7 (sete) dias corridos, contados a partir da data de recebimento para solicitar a devolução do produto,  Para efetuar o procedimento de devolução, o usuário COMPRADOR deverá recusar o produto através da plataforma, justificando o motivo da devolução. 
                
              </p>
              
              <p className="_topic-content">
                <strong>9.2.</strong> O usuário COMPRADOR se responsabiliza pela devolução do produto nas exatas condições como foi recebido. Isso significa que, produtos que tiveram suas etiquetas removidas, quando for o caso de produtos novos, ou foram usados, lavados ou danificados, não poderão ser devolvidos ao usuário VENDEDOR.
              </p>
              
              <p className="_topic-content">
                <strong>9.3.</strong> O usuário COMPRADOR tem o prazo de 3 (três) dias corridos para efetuar o envio do produto, contados a partir da data da recusa, via Correios para o endereço do usuário vendedor utilizando a etiqueta de postagem e a declaração de conteúdo, enviadas pelo Scamb para seu e-mail de cadastro.
              </p>

              <ul>
                <li>
                  <strong>9.3.1.</strong> A não observância do prazo de postagem previsto nesta cláusula implicará no cancelamento do pedido de devolução, não sendo possível fazer um novo pedido.
                </li>
                <li>
                  <strong>9.3.2.</strong> No momento da embalagem para devolução, o usuário COMPRADOR deve, obrigatoriamente, fotografar o produto e a embalagem e armazenar as fotografias para, caso solicitadas, sejam apresentadas, a fim de comprovar as condições do produto e da embalagem no momento da postagem para devolução.
                </li>
              </ul>
              
              <p className="_topic-content">
                <strong>9.4.</strong> Quando o usuário COMPRADOR solicitar a devolução do produto, a plataforma não autorizará a transferência dos pontos para a carteira do usuário VENDEDOR. 
              </p>
              
              <p className="_topic-content">
                <strong>9.5.</strong> Caso o produto postado pelo usuário COMPRADOR para devolução ao usuário VENDEDOR não possa ser entregue pelos Correios em decorrência de culpa do usuário VENDEDOR, como, por exemplo, em virtude de seu endereço cadastral estar errado, incompleto, ou inexistente, ou ainda em virtude de diversas tentativas de entrega sem sucesso, e, por esse (s) motivo (s), o produto retorne ao endereço do usuário COMPRADOR, o usuário COMPRADOR terá o prazo improrrogável de 7 (sete) dias corridos, a contar do recebimento da mercadoria, para comunicar o ocorrido ao Scamb, que tomará as providências cabíveis.
              </p>

              <ul>
                <li>
                  <strong>9.5.1.</strong> A não observância do prazo de postagem previsto nesta cláusula implicará no cancelamento do pedido de devolução, não sendo possível fazer um novo pedido.
                </li>
              </ul>
              
              <p className="_topic-content">
                <strong>9.6.</strong> O usuário está ciente que em caso em devolução do produto, por qualquer motivo, não haverá a devolução da taxa Scamb, assim como, não haverá a restituição do valor pago referente ao frete.
              </p>
              
              <p className="_topic-content">
                <strong>9.7.</strong> O usuário VENDEDOR, após receber o produto devolvido pelo usuário COMPRADOR, tem o prazo de 2 (dois) dias corridos para contestar a devolução, entrando em contato com a plataforma, explicando os motivos e enviando as fotos tiradas no momento da postagem, conforme cláusula 7.3.1.
              </p>
              
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title"><strong>10.</strong> Cláusula décima: dos parceiros</h1>
              
              <p className="_topic-content">
                <strong>10.1.</strong> Os usuários declaram ter ciência que, ao utilizar os pontos para compra de cupom de desconto nas empresas parceiras, estão sujeitos aos regulamentos e condições impostas pelo parceiro, não tendo o Scamb qualquer responsabilidade nesse negócio jurídico, atuando somente como plataforma de intermediação entre os usuários e os parceiros.
              </p>

              <ul>
                <li>
                  <strong>10.1.1.</strong> O Scamb recomenda aos usuários realizarem as compras de cupons de desconto com cautela e bom senso, observando com atenção a integralidade das condições, regras, prazos e estoque estabelecidos pelo parceiro. 
                </li>
              </ul>
              
              <p className="_topic-content">
                <strong>10.2.</strong> compra de cupom de desconto pelo usuário é uma transação realizada por intermédio da plataforma, portanto, ao usuário COMPRADOR será cobrada a taxa incidente sobre a transação, ou seja, nas compras de cupons também será cobrada a Taxa Scamb discriminada na cláusula sexta. 
              </p>

              <ul>
                <li>
                  <strong>10.2.1.</strong> A Taxa Scamb incidente sobre a transação de compra de cupom de desconto é idêntica aquela incidente sobre a compra e venda entre usuários, ou seja,  é variável de acordo com o valor de pontos da transação, conforme a seguinte tabela:
                </li>
                <li>
                    <Table className="mbottom" striped bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>
                        Valor do produto em <strong>pontos</strong>
                        </th>
                        <th>
                        Taxa Scamb <strong>(R$)</strong>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1 – 99 pontos</td>
                        <td>R$ 3,90</td>
                    </tr>
                    <tr>
                        <td>100 – 199 pontos</td>
                        <td>R$ 5,90</td>
                    </tr>
                    <tr>
                        <td>200 – 499 pontos</td>
                        <td>R$ 9,90</td>
                    </tr>
                    <tr>
                        <td>500 – 799 pontos</td>
                        <td>R$ 12,90</td>
                    </tr>
                    <tr>
                        <td>800 – 999 pontos </td>
                        <td>R$ 14,90</td>
                    </tr>
                    <tr>
                        <td>mais de 1.000 pontos*</td>
                        <td>R$ 19,90</td>
                    </tr>
                    </tbody>
                </Table>
                <span className="_11px">(*) limite de 10 itens por pedido.</span>
                </li>
                <li>
                    <strong>10.2.2.</strong> A taxa Scamb será cobrada do usuário COMPRADOR no momento da compra do cupom. Nesse momento, o usuário comprador pagará a taxa Scamb pelo meio de pagamento já cadastrado na plataforma. 
                </li>
              </ul>
              
              <p className="_topic-content">
                <strong>10.3.</strong> Os usuários têm ciência que o Scamb não tem qualquer responsabilidade sobre os produtos ou serviços prestados pelos parceiros, bem como, que qualquer vício no produto ou na prestação de serviço é de responsabilidade exclusiva do parceiro, devendo o usuário acioná-lo diretamente. 
              </p>
              
              <p className="_topic-content">
                <strong>10.4.</strong> Os usuários poderão desistir da compra do cupom de desconto dentro do prazo de 7 (sete) dias corridos contados da data da efetivação da compra. Para tanto, deverão solicitar o cancelamento através do campo disponível na plataforma.
              </p>

              <ul>
                <li>
                  <strong>10.4.1.</strong> Transcorrido o prazo estipulado nesta cláusula, o cancelamento não poderá mais ser solicitado, em nenhuma hipótese. 
                </li>
              </ul>
              
              <p className="_topic-content">
                <strong>10.5.</strong> Em casos de cancelamento, os pontos envolvidos na transação serão devolvidos à carteira do usuário e o valor pago referente a Taxa Scamb será integralmente restituído ao usuário COMPRADOR.
              </p>
              
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title"><strong>11.</strong> Cláusula décima-primeira: do limite da responsabilidade do Scamb.</h1>
              
              <p className="_topic-content">
                <strong>11.1.</strong> O Scamb é uma plataforma de intermediação de negócios, os quais são realizados direta e exclusivamente entre os usuários, não sendo, portanto, parte integrante das negociações de produtos, desta forma, a responsabilidade por todas as obrigações delas decorrentes, sejam fiscais, trabalhistas, consumeristas ou de qualquer outra natureza, será exclusivamente dos usuários.
              </p>
              
              <p className="_topic-content">
                <strong>11.2.</strong> Em razão de o Scamb não ser o proprietário dos produtos anunciados pelos usuários, nem deter a posse dos itens anunciados e, ainda, não realizar as ofertas de venda nem intervir na entrega dos produtos, fica isento de qualquer responsabilidade advinda da negociação de tais produtos, incluindo mas não se limitando a existência, quantidade, qualidade, estado, descrição, integridade ou legitimidade dos produtos oferecidos, adquiridos ou alienados pelos usuários.
              </p>
              
              <p className="_topic-content">
                <strong>11.3.</strong> O Scamb não outorga garantia por vícios ocultos ou aparentes nas negociações entre os usuários, sendo certo que cada usuário conhece e aceita ser o único responsável pelos produtos que anuncia ou pelas ofertas que realiza. O usuário VENDEDOR será o único e exclusivo responsável por quaisquer danos, indenizações e prejuízos eventualmente causados, devendo este indenizar o usuário COMPRADOR. 
              </p>
              
              <p className="_topic-content">
                <strong>11.4.</strong> O Scamb não se responsabiliza por vícios ou defeitos técnicos e/ou operacionais oriundos do sistema do usuário ou de terceiros, inclusive das empresas gestoras de pagamentos elencadas na cláusula quinta.
              </p>
              
              <p className="_topic-content">
                <strong>11.5.</strong> Em nenhum caso o site será responsável pelo lucro cessante ou por qualquer outro dano ou prejuízo que o usuário possa sofrer devido às negociações realizadas ou não realizadas através da plataforma, ainda que decorrentes da conduta de outros usuários.
              </p>
              
              <p className="_topic-content">
                <strong>11.6.</strong> O Scamb não se responsabiliza pelas obrigações de natureza tributária que incidam sobre as negociações realizadas entre os usuários.
              </p>
              
              <p className="_topic-content">
                <strong>11.7.</strong> A plataforma não se responsabiliza por qualquer dano, prejuízo ou perda sofridos pelo usuário em razão de falhas na internet, no sistema ou no servidor utilizado pelo usuário, ou aqueles decorrentes de condutas de terceiros, de caso fortuito ou de força maior.
              </p>
              <ul>
                <li>
                  <strong>11.7.1.</strong> O Scamb também não será responsável por qualquer vírus que possa atacar o equipamento do usuário em decorrência do acesso, utilização ou navegação na internet ou como consequência da transferência de dados, arquivos, imagens, textos ou áudio. 
                </li>
              </ul>
              
              <p className="_topic-content">
                <strong>11.8.</strong> O usuário indenizará o Scamb, empresas controladas ou controladoras, diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por outros usuários ou terceiros decorrentes de suas atividades na plataforma ou por seu descumprimento dos presentes termos e demais políticas do site, ou pela violação de qualquer lei ou direitos de terceiros, incluindo honorários advocatícios.
              </p>

            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title"><strong>12.</strong> Cláusula décima segunda: disposições finais.</h1>
              
              <p className="_topic-content">
                <strong>12.1.</strong> A concordância do usuário a estes Termos e Condições gerais de Uso não geram contrato de sociedade, de mandato, de franquia ou relação de trabalho entre o Scamb e o usuário. 
              </p>
              
              <p className="_topic-content">
                <strong>12.2.</strong> O uso comercial da expressão Scamb ou www.Scamb.com.vc como marca, nome empresarial ou nome de domínio, assim como os programas, bancos de dados, redes, arquivos que permitem que o usuário acesse e use sua conta são propriedade da plataforma e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos, salvo a autorização expressa do Scamb.
              </p>
              
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title"><strong>13.</strong> Cláusula décima-terceira: do foro.</h1>
              
              <p className="_topic-content">
                <strong>13.1.</strong> Estes Termos e Condições Gerais de Uso são regidos pelas leis vigentes na República Federativa do Brasil, portanto, para dirimir eventuais dúvidas ou questões relativas a ele, as partes elegem o Foro da Comarca de São Paulo (SP), com exclusão de qualquer outro, por mais privilegiado que seja. 
              </p>
              
            </div>

          </div>
        </div>
      </section>
    );
  }
}

export default TermsConditions;
