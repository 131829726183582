//===========================================================
// #1 - Base Imports
//===========================================================
import React from "react";
import { Link } from "react-router-dom";

//===========================================================
// #2 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

export const AdvertiserCard = (props) => {

    return (

        <Link to={props.storeLink} className="_advertiser-card">
            <div className="_col">

                <div to={"/loja/"} className="_advertiser-card-logo">
                    <img src={props.storeLogo} alt="Avatar" />
                </div>

                <div className="_advertiser-card-title--location">
                    {props.storeTitle}
                    <span className="title-location"> {props.storeLocation} </span>
                </div>

            </div>

            <div className="_col">
                
                {props.button === true && (
                <div className="_advertiser-card-follow">
                    <button type="button" className="btn btn-follow">
                        Ver loja
                    </button>
                </div>
                )}
                <div className="_advertiser-card-redirect">
                    <button type="button" className="btn">
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                    </button>
                </div>

            </div>
        </Link>

    )
};