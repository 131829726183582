//===========================================================
// #1 - Base Imports
//===========================================================
import React from "react";

export const CurrentPage = (props) => {

    return (

        <div className="_title-box">
            <span>
                {props.icon}
            </span>
            {props.title}
        </div>
    )
}