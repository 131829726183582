import React, { Component, forwardRef, useState, useImperativeHandle } from 'react';
import Header from '../components/account/Header';
import Product from '../components/GridProduct';
import {Filter, FilterHead} from '../components/Filters';
import Swal from 'sweetalert2';

// Modal Bootstrap
import { Button, Modal } from "react-bootstrap";

// Material UI
import Grid from '@material-ui/core/Grid';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes, faArrowRight, faLongArrowUp, faLongArrowDown, faSearch} from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

// Services
import Ad from '../services/Ad';
import Categorization from '../services/Categorization';
import Domain from '../services/Domain';

import Helmet from 'react-helmet';

// Images icons
import arrowUpDowN from "../assets/images/arrows-up-down@2x.png";
import filter from "../assets/images/filter@2x.png";

const ModalLoadMore = forwardRef((props, ref) => {
    // Bootstrap Modal
    const [show, setShow] = useState(false);
  
    // Handle Modal
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    useImperativeHandle(ref, () => ({
      openModal: () => handleShow(),
      closeModal: () => handleClose(),
    }));
  
    return (
      <>
        <Modal show={show} size="size-45w" onHide={handleClose} className="full-width" centered>
          <Modal.Header closeButton>
            <section className="_view-head">
              <div className="_view-head-content" style={{}}>
                <Button className="_back-history btn" onClick={handleClose}>
                  <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                </Button>
                <Modal.Title> {props.modalTitle} </Modal.Title>
              </div>
            </section>
          </Modal.Header>
          <Modal.Body className="_modal-filter">{props.children}</Modal.Body>
        </Modal>
      </>
    );
});

class Store extends Component {

    constructor(){
        super();

        // Refs
        this.refLoadMoreModal = React.createRef();
        this.refLoadMoreBrand = React.createRef();

        this.ad = new Ad();
        this.state = {
            adsSeller:[],
            images:{},
            store:{},
            amountOfAdsReturned:0,
            address:[],
            limit: 8,
            storeUrl:'',
            value: [0,0],
            showFilter: false,
            showOrderFilter: false,
            listConditions: [],
            listBrands: [],
            listCategorizations: [],
            listActiveCategorizations: [],
            searchFilters: {
                categorizations: []
            },
            key:'',
            category:'',
            tag:''
        }
        this.categorization = new Categorization();
        this.domain = new Domain();
    }
    
    componentDidMount() {

        let storeUrl = this.props.match.params.storeUrl;
        if(!storeUrl) this.props.history.push('/'); 
        
        this.setState({'storeUrl':storeUrl})
        this.loadScamberAdsByStoreUrl(storeUrl, this.state.limit);   

        this.loadFiltersOptions();
    }

    loadFiltersOptions = () => {

        //LISTA DE CATEGORIZACOES
        this.categorization.list().then((response) => {
            if (response.status === 200) {
              this.setState({ listCategorizations: response.data.payload });
              this.setState({ listActiveCategorizations: response.data.payload });
            }
          })
          .catch((error) =>
            Swal.fire({
              type: "info",
              html: this.categorization.formatErrorMessage(error.response),
              title: "",
            })
          );
        
        //LISTA DE CONDIÇÕES
        this.domain.listConditions().then((response) => {
            if (response.status === 200) {
              this.setState({ listConditions: response.data.payload });
            }
        }).catch((error) =>
            Swal.fire({
              type: "info",
              html: this.domain.formatErrorMessage(error.response),
              title: "",
            })
        );
    
        //LISTA DE MARCAS
        this.domain.listBrands().then((response) => {
            if (response.status === 200) {
              this.setState({ listBrands: response.data.payload});
            }
        }).catch((error) =>
            Swal.fire({
              type: "info",
              html: this.domain.formatErrorMessage(error.response),
              title: "",
            })
        );
    }

    formatCategoryList = (searchFilters) => {
        return searchFilters.categorizations.reduce((stringCategory, category, index) => { 
            if(index === searchFilters.categorizations.length -1) {
            stringCategory += category._id 
            } else {
            stringCategory += `${category._id},`
            }

            return stringCategory;
        }, "")
    }

    search = (key, limit, category, tag, countPage, newSearch, price, points, condition, categoryList, brand, deliveryType) => {
        if (!newSearch) {
          if (countPage.sum)
            this.setState({ pageNumber: (this.state.pageNumber += 1) });
          if (countPage.subtraction)
            this.setState({ pageNumber: (this.state.pageNumber -= 1) });
        }
    
        Swal.fire("Aguarde...");
        Swal.showLoading();
        // tratando & comercial
        if(key){
          if(key.indexOf('&') !== -1) key = key.replace('&','ampersand');
        }
        this.ad
          .findAdsByUserSearch(
            key ? key : null,
            limit ? limit : 8,
            category ? category : null,
            tag ? tag : null,
            null,
            newSearch ? 1 : this.state.pageNumber,
            price? price : null, 
            points? points : null, 
            condition? condition : null, 
            categoryList? categoryList : null, 
            brand? brand: null, 
            deliveryType? deliveryType: null,
            this.state.storeUrl
          )
          .then((response) => {
            Swal.close();
            if (response.data.payload.length <= 0)
              this.setState({ pageNumber: (this.state.pageNumber -= 1) });
            this.setState({ adsSeller: response.data.payload.ad });
            this.setState({amountOfAdsReturned: response.data.payload.ad.length})
          })
          .catch((error) =>
            Swal.fire({
              type: "info",
              html: this.ad.formatErrorMessage(error.response),
              title: "",
            })
          );
    };

    applyFilter = (filterName, filterValue, modalRef) => {
        let searchFilters = this.state.searchFilters;
    
        if(!filterValue) {
          delete searchFilters[filterName];
        } else {
          searchFilters[filterName] = filterValue;
        }
    
        this.setState({ searchFilters: searchFilters });
        if(modalRef) modalRef.current.closeModal();
    
        //CHAMANDO BUSCA
        // this.search(
        //   this.props.location.state?.key,
        //   this.state?.limit,
        //   this.props.location.state?.category,
        //   this.props.location.state?.tag,
        //   { sum: 1 },
        //   true,
        //   searchFilters.price? searchFilters.price._id : null, 
        //   searchFilters.points? searchFilters.points._id : null, 
        //   searchFilters.condition? searchFilters.condition._id : null, 
        //   searchFilters.categorizations.length > 0? this.formatCategoryList(searchFilters) : null, 
        //   searchFilters.brand? searchFilters.brand._id : null, 
        //   searchFilters.deliveryTypes? searchFilters.deliveryTypes._id : null
        // );
        
    }

    removeCategorizationFilter = (categorization, index) => {
        let searchFilters = this.state.searchFilters;
    
        searchFilters.categorizations = searchFilters.categorizations.slice(0, index);
        this.setState({ listActiveCategorizations: categorization.oldCategorizationList, searchFilters: searchFilters  });
    
        //CHAMANDO BUSCA
        // this.search(
        //   this.props.location.state?.key,
        //   this.state?.limit,
        //   this.props.location.state?.category,
        //   this.props.location.state?.tag,
        //   { sum: 1 },
        //   true,
        //   searchFilters.price? searchFilters.price._id : null, 
        //   searchFilters.points? searchFilters.points._id : null, 
        //   searchFilters.condition? searchFilters.condition._id : null, 
        //   searchFilters.categorizations.length > 0? this.formatCategoryList(searchFilters) : null, 
        //   searchFilters.brand? searchFilters.brand._id : null, 
        //   searchFilters.deliveryTypes? searchFilters.deliveryTypes._id : null
        // );
    }

    filterBrands = (e, filter) =>{
        if(e) e.preventDefault();
        // this.inputBrand = e.target.value;
        if(filter) if(filter.indexOf('&') !== -1) filter = filter.replace('&','ampersand');
        this.domain.listBrands(filter).then((response) => {
          if (response.status === 200) {
            this.setState({ listBrands: response.data.payload});
          }
        }).catch((error) =>
            Swal.fire({
              type: "info",
              html: this.domain.formatErrorMessage(error.response),
              title: "",
            })
        );
    }

    // Functions Filters

  handleShowFilter = () => {
    this.setState({
      showFilter: !this.state.showFilter,
    });
  };

  handleCloseFilter = () => {
    this.setState({
      showFilter: false,
    });
  };

  handleShowOrderFilter = () => {
    this.setState({
      showOrderFilter: !this.state.showOrderFilter,
    });
  };

  handleCloseOrderFilter = () => {
    this.setState({
      showOrderFilter: false,
    });
  };

  removeFilterBrand = (filterName, filterValue, modalRef) => {
    let searchFilters = this.state.searchFilters;

    if(!filterValue) {
      delete searchFilters[filterName];
    } else {
      searchFilters[filterName] = filterValue;
    }

    this.setState({ searchFilters: searchFilters });
    if(modalRef) modalRef.current.closeModal();

    // RESETANDO A LISTA DE MARCAS
    this.filterBrands(null,'');
  }

    applyCategorizationFilter = (categorization, modalRef) => {
        let searchFilters = this.state.searchFilters;
    
        categorization.oldCategorizationList = this.state.listActiveCategorizations;
        searchFilters.categorizations.push(categorization);
        this.setState({ searchFilters: searchFilters });
    
        if(modalRef) modalRef.current.closeModal();
    
        if (categorization.categorias && categorization.categorias.length > 0) {
          this.setState({ listActiveCategorizations: categorization.categorias });
        } else if (
          categorization.tipos_produtos &&
          categorization.tipos_produtos.length > 0
        ) {
          this.setState({ listActiveCategorizations: categorization.tipos_produtos });
        } else if (categorization.produtos && categorization.produtos.length > 0) {
          this.setState({ listActiveCategorizations: categorization.produtos });
        } else {
          this.setState({ listActiveCategorizations: [] });
        } 
    
        //CHAMANDO BUSCA
        // this.search(
        //   this.props.location.state?.key,
        //   this.state?.limit,
        //   this.props.location.state?.category,
        //   this.props.location.state?.tag,
        //   { sum: 1 },
        //   true,
        //   searchFilters.price? searchFilters.price._id : null, 
        //   searchFilters.points? searchFilters.points._id : null, 
        //   searchFilters.condition? searchFilters.condition._id : null, 
        //   searchFilters.categorizations.length > 0? this.formatCategoryList(searchFilters) : null, 
        //   searchFilters.brand? searchFilters.brand._id : null, 
        //   searchFilters.deliveryTypes? searchFilters.deliveryTypes._id : null
        // );
    }

    componentDidUpdate(){
        // Atualizando a pagina se mudar o storeUrl
        let storeUrl = this.props.match.params.storeUrl;
        if(this.state.storeUrl !== storeUrl){
            if(!storeUrl) this.props.history.push('/'); 
            this.setState({'storeUrl':storeUrl})
            this.setState({amountOfAdsReturned: 0})
            this.loadScamberAdsByStoreUrl(storeUrl, this.state.limit);        
        }
    }

    loadScamberAdsByStoreUrl = ( storeUrl,limit ) => {
        Swal.fire('Aguarde...');
        Swal.showLoading();
        this.ad.findAdsByUserSearch(
            null, 
            limit, 
            null , 
            null , 
            null , 
            null , 
            null , 
            null , 
            null , 
            null , 
            null , 
            null ,
            storeUrl 
        ).then((response) => {
            this.setState({amountOfAdsReturned: response.data.payload.ad.length})
            this.setState({adsSeller:response.data.payload.ad, images:response.data.payload.imagens, store:response.data.payload.minha_loja, address: response.data.payload.endereco})    
            Swal.close();
          })
          .catch((error)=>{
            this.props.history.push("/meu-perfil");
            setTimeout(() => {
                Swal.fire({type: "info", html: this.ad.formatErrorMessage(error.response),title: "Loja ainda não cadastrada!", timer: 5000, timerProgressBar: true})
            }, 10);
          })
    }

    loadMore = () =>{
        this.setState({limit: this.state.limit+=4})
        // this.loadScamberAdsByStoreUrl(this.state.storeUrl,this.state.limit);
        this.setState({
        showFilter: false,
        });
        this.search(
            null,
            this.state.limit,
            null,
            null,
            { sum: 0 },
            false,
            this.state.searchFilters.price? this.state.searchFilters.price._id : null, 
            `${this.state.preco_min? this.state.preco_min: 0 }-${this.state.preco_max? this.state.preco_max: 999999999}`, 
            this.state.searchFilters.condition? this.state.searchFilters.condition._id : null, 
            this.state.searchFilters.categorizations.length > 0? this.formatCategoryList(this.state.searchFilters) : null, 
            this.state.searchFilters.brand? this.state.searchFilters.brand._id : null, 
            this.state.searchFilters.deliveryTypes? this.state.searchFilters.deliveryTypes._id : null,
            this.state.storeUrl
        );
    }

    // Functions Filters

    handleShowFilter = () => {
        this.setState({
            showFilter: !this.state.showFilter
        })
    }

    handleCloseFilter = () => {
        this.setState({
            showFilter: false
        })
    }

    handleShowOrderFilter = () => {
        this.setState({
            showOrderFilter: !this.state.showOrderFilter
        })
    }

    handleCloseOrderFilter = () => {
        this.setState({
            showOrderFilter: false
        })
    }


    render() {
        
        const all_ads = this.state.adsSeller.length;
        const limit_ads = this.state.limit;
        
        return (

            <>
            <Helmet title="Scamb - Loja" />
            <div id="view_store">
                <Header address={this.state.address} images={this.state.images} store={this.state.store}></Header>
                
                <section className="_store-content">
                <div className="filter">
                    <div className="_active-filters--mobile">
                        <button type="button" onClick={this.handleShowOrderFilter}>
                        Ordenar
                        {/* <img src={arrowUpDowN} alt="" /> */}
                        <FontAwesomeIcon icon={faLongArrowUp} />
                        <FontAwesomeIcon icon={faLongArrowDown} />
                        </button>
                        <button type="button" onClick={this.handleShowFilter}>
                        Filtros
                        <svg class="ui-search-icon ui-search-icon--filter" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9 22h16.1v2h-16.1c-0.479 2.301-2.491 4.005-4.9 4.005s-4.42-1.704-4.894-3.973l-0.006-0.032h-6.1v-2h6.1c0.479-2.301 2.491-4.005 4.9-4.005s4.421 1.704 4.894 3.973l0.006 0.032zM18.1 8c0.48-2.301 2.491-4.005 4.9-4.005s4.421 1.704 4.894 3.973l0.006 0.032h4.1v2h-4.1c-0.48 2.301-2.491 4.005-4.9 4.005s-4.421-1.704-4.894-3.973l-0.006-0.033h-18.1v-2h18.1zM23 12c1.657 0 3-1.343 3-3s-1.343-3-3-3v0c-1.657 0-3 1.343-3 3s1.343 3 3 3v0zM11 26c1.657 0 3-1.343 3-3s-1.343-3-3-3v0c-1.657 0-3 1.343-3 3s1.343 3 3 3v0z">
                            </path>
                        </svg>
                        </button>
                    </div>

                    <div
                        className={
                        this.state.showOrderFilter
                            ? "_filter-order _filter-show"
                            : "_filter-order"
                        }
                    >
                        <FilterHead title="Ordenar anúncios">
                        <button
                            type="button"
                            className="_back-history"
                            onClick={this.handleCloseOrderFilter}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        </FilterHead>
                        <form onSubmit={(e)=>e.preventDefault()} className="_form-order-by">
                        <Filter filterTitle="Ordenar por">

                            {this.state.searchFilters.price? (
                            <button type="button" onClick={() => this.applyFilter("price", undefined)} className="_filter-selected">
                                {this.state.searchFilters.price.nome}
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            ): 
                                (  
                                <>
                                <label className="_filter-label">
                                    <input onClick={() => this.applyFilter("price", {_id: -1, nome: "Maior Preço"})} type="radio" name="filter-input-name" />
                                    <span className="_filter-title">Maior preço</span>
                                </label>

                                <label className="_filter-label">
                                    <input type="radio" onClick={() => this.applyFilter("price", {_id: 1, nome: "Menor Preço"})} name="filter-input-name" />
                                    <span className="_filter-title">Menor preço</span>
                                </label>
                                </> 
                                )   
                            }

                        
                        </Filter>
                            <div className="_apply-finter-button">

                            <Button
                            type="submit"
                            color="primary"
                            autoFocus
                            className="apply-filters"
                            onClick={(e)=>{
                                e.preventDefault();
                                this.setState({
                                showOrderFilter: false,
                                });
                                this.search(
                                null,
                                this.state.limit,
                                null,
                                null,
                                { sum: 0 },
                                false,
                                this.state.searchFilters.price? this.state.searchFilters.price._id : null, 
                                this.state.searchFilters.points? this.state.searchFilters.points._id : null, 
                                this.state.searchFilters.condition? this.state.searchFilters.condition._id : null, 
                                this.state.searchFilters.categorizations.length > 0? this.formatCategoryList(this.state.searchFilters) : null, 
                                this.state.searchFilters.brand? this.state.searchFilters.brand._id : null, 
                                this.state.searchFilters.deliveryTypes? this.state.searchFilters.deliveryTypes._id : null,
                                this.state.storeUrl
                                );
                            }}
                            >
                            Aplicar filtro
                            <FontAwesomeIcon icon={faCheck} />
                            </Button>
                            </div>
                        </form>
                    </div>

                    <div
                        className={
                        this.state.showFilter
                            ? "_wrap-filters _filter-show"
                            : "_wrap-filters"
                        }
                    >
                        <FilterHead title="Filtros">
                        <button
                            type="button"
                            className="_back-history"
                            onClick={this.handleCloseFilter}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        </FilterHead>

                        <form id="form-filters" className="_form _sidebar">
                        <Filter filterTitle="Categorias">
                            {this.state.searchFilters.categorizations.length > 0? (
                            <>
                                {this.state.searchFilters.categorizations.map((categorization, index) => 
                                (<button key={index} type="button" onClick={() => this.removeCategorizationFilter(categorization, index)} className="_filter-selected">
                                    {categorization.nome}
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>)
                                )}
                            </>
                            ): (<></>)}

                            {this.state.listActiveCategorizations.length > 0? this.state.listActiveCategorizations.slice(0, 7).map((categorization, index) => (
                            <label key={index} className="_filter-label">
                                <input onClick={() => this.applyCategorizationFilter(categorization)} type="radio" name="categorization" />
                                <span className="_filter-title">{categorization.nome}</span>
                                {/*<span className="_filter-count">(759)</span>*/}
                            </label>
                            )):(<></>)}

                            {this.state.listActiveCategorizations.length > 7? 
                            (<button
                                type="button"
                                className="_loadmore-in-modal"
                                onClick={() => this.refLoadMoreModal.current.openModal()}
                            >
                                Ver mais
                            </button>): (<></>) 
                            }

                        </Filter>

                        <Filter filterTitle="Marca">
                            {this.state.searchFilters.brand? (
                            <button type="button" onClick={(e) => this.removeFilterBrand("brand", undefined)} className="_filter-selected">
                                {this.state.searchFilters.brand.nome}
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            ): <>
                                {this.state.listBrands.length > 0? this.state.listBrands.slice(0, 7).map((brand, index) => (
                                <label key={index} className="_filter-label">
                                    <input onClick={() => this.applyFilter("brand", brand)} type="radio" name="brand" />
                                    <span className="_filter-title">{brand.nome}</span>
                                    {/*<span className="_filter-count">(759)</span>*/}
                                </label>
                                )):(<></>)}
                                </> 
                            }

                            {!this.state.searchFilters.brand? 
                            (<button
                                type="button"
                                className="_loadmore-in-modal"
                                onClick={() => this.refLoadMoreBrand.current.openModal()}
                            >
                                Ver mais
                            </button>): (<></>)
                            }

                        </Filter>

                        <Filter filterTitle="Condição">

                        {this.state.searchFilters.condition? (
                            <button type="button" onClick={() => this.applyFilter("condition", undefined)} className="_filter-selected">
                                {this.state.searchFilters.condition.nome}
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            ): <>
                                {this.state.listConditions.length > 0? this.state.listConditions.map((condition) => (
                                    <label key={condition.nome} className="_filter-label">
                                    <input onClick={() => this.applyFilter("condition", condition)} type="radio" name="condition" />
                                    <span className="_filter-title">{condition.nome}</span>
                                    {/*<span className="_filter-count">(759)</span>*/}
                                    </label>
                                )):(<></>) 
                                }
                                </> 
                            }
                            
                        </Filter>

                        <Filter filterTitle="Frete">

                            {this.state.searchFilters.deliveryTypes? (
                            <button type="button" onClick={() => this.applyFilter("deliveryTypes", undefined)} className="_filter-selected">
                                {this.state.searchFilters.deliveryTypes.nome}
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            ): 
                                (  
                                <>
                                <label className="_filter-label">
                                    <input onClick={() => this.applyFilter("deliveryTypes", {_id: "operador_logistico", nome: "Correios"})} type="radio" name="filter-input-name" />
                                    <span className="_filter-title">Correios</span>
                                </label>

                                <label className="_filter-label">
                                    <input type="radio" onClick={() => this.applyFilter("deliveryTypes", {_id: "entrega_maos", nome: "Entrega em mãos"})} name="filter-input-name" />
                                    <span className="_filter-title">Entrega em mãos</span>
                                </label>
                                </> 
                                )   
                            }


                            </Filter>

                        <Filter filterTitle="Pontos">
                            <div className="form-group">
                            <div className="_min-max">
                                <div className="input-group">
                                <input
                                    type="number"
                                    onChange={(e) => this.setState({preco_min: e.target.value})}
                                    value={this.state.preco_min}
                                    className="form-control"
                                    placeholder="Minimo"
                                    aria-describedby="min-money"
                                />
                                </div>

                                <div className="icon">
                                <FontAwesomeIcon
                                    icon={faArrowRight}
                                ></FontAwesomeIcon>
                                </div>

                                <div className="input-group">
                                <input
                                    type="number"
                                    onChange={(e) => this.setState({preco_max: e.target.value})}
                                    value={this.state.preco_max}
                                    className="form-control"
                                    placeholder="Máximo"
                                    aria-describedby="max-money"
                                />
                                </div>
                            </div>
                            </div>

                            {/* <button type="button" 
                            onClick={() => this.applyFilter("points", {_id: 
                                `${this.state.preco_min? this.state.preco_min: 0 }-${this.state.preco_max? this.state.preco_max: 999999999}`, nome: "points"})} 
                            disabled={true}
                            className="_apply-price">
                            Aplicar
                            <FontAwesomeIcon icon={faCheck} />
                            </button> */}
                        </Filter>
                        
                        <div className="_apply-finter-button">

                            <Button
                            onClick={(e)=>{
                                e.preventDefault();
                                this.setState({
                                showFilter: false,
                                });
                                this.search(
                                    null,
                                    this.state.limit,
                                    null,
                                    null,
                                    { sum: 0 },
                                    false,
                                    this.state.searchFilters.price? this.state.searchFilters.price._id : null, 
                                    `${this.state.preco_min? this.state.preco_min: 0 }-${this.state.preco_max? this.state.preco_max: 999999999}`, 
                                    this.state.searchFilters.condition? this.state.searchFilters.condition._id : null, 
                                    this.state.searchFilters.categorizations.length > 0? this.formatCategoryList(this.state.searchFilters) : null, 
                                    this.state.searchFilters.brand? this.state.searchFilters.brand._id : null, 
                                    this.state.searchFilters.deliveryTypes? this.state.searchFilters.deliveryTypes._id : null,
                                    this.state.storeUrl
                                );
                            }}
                            type="submit"
                            color="primary"
                            autoFocus
                            className="apply-filters"
                            >
                            Aplicar filtros
                            <FontAwesomeIcon icon={faCheck} />
                            
                            </Button>
                            </div>
                        </form>
                    </div>
                    </div>

                    {/* MODAIS */}
                    <ModalLoadMore modalTitle="Categorias" ref={this.refLoadMoreModal}>
                    <form className="form form-filter">
                        
                    <div className="search">
                                
                        {/* <div className="form-group search mobile">
                        <input type="text" placeholder="Busque por 'Blusas'" />
                        <button type="submit">
                            <FontAwesomeIcon icon={faSearch} className="fa-question-circle"/>
                        </button>
                        </div> */}

                    </div>
                    
                    <div className="_wrap-items">
                    <Grid container spacing={2}>
                        {this.state.listActiveCategorizations.map((categorization, index) => (
                        <Grid item lg={4} md={4} sm={12} xs={12} key={index}> 
                        {/* <div className="_filter-item">
                            <label className="_filter-label">
                            <input onClick={() => this.applyCategorizationFilter(categorization, this.refLoadMoreModal)} type="radio" name="categorization" />
                            <span className="_filter-title">{categorization.nome}</span>
                            </label>
                        </div> */}

                        <button type="button" className="btn-select--filter" onClick={() => this.applyCategorizationFilter(categorization, this.refLoadMoreModal)} name="categorization">
                            {categorization.nome}
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        </Grid>
                        ))
                        }
                        </Grid>
                        </div>
                    </form>
                    </ModalLoadMore>

                    <ModalLoadMore modalTitle="Marca" ref={this.refLoadMoreBrand}>
                    <form onSubmit={(e)=>this.filterBrands(e,this.inputBrand)} className="form form-filter _brands">

                    <div className="search">
                                
                        <div className="form-group search mobile">
                        <input onChange={(e)=> this.inputBrand = e.target.value} type="text" placeholder="Busque por 'Zara'" />
                        <button type="submit">
                            <FontAwesomeIcon icon={faSearch} className="fa-question-circle"/>
                        </button>
                        </div>

                    </div>

                    <div className="_wrap-items">


                        <Grid container spacing={2}>
                        {this.state.listBrands.map((brand, index) => (
                            <Grid item lg={4} md={4} sm={12} xs={12} key={index}> 
                            <button type="button" className="btn-select--filter" onClick={() => this.applyFilter("brand", brand, this.refLoadMoreBrand)}>
                                {brand.nome}
                                <FontAwesomeIcon icon={faCheck} />
                            </button> 
                            </Grid>
                        ))}
                        </Grid>
                        </div>
                    </form>
                    </ModalLoadMore>
                    
                    <div className="_store-products">
                    <div className="_products">
                        
                    <Grid container spacing={2}>
                        {this.state.adsSeller &&(
                            this.state.adsSeller.map((ad, index)=>
                            <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                                <Product
                                    link={`/produto?id=${ad._id}`}
                                    image={this.ad.loadImage(ad.fotos[0])?this.ad.loadImage(ad.fotos[0]):''}
                                    title={ad.titulo}
                                    discount={ad.pontos_de? ad.pontos_de.$numberDecimal : ''}
                                    price={ad.pontos_por? ad.pontos_por.$numberDecimal:''}
                                    idAd={ad._id}
                                    >
                                </Product>
                            </Grid>
                            )
                        )}
                        </Grid>
                    
                    </div>
                        <div className="_wrap-load-more">
                            
                            { all_ads >= limit_ads && this.state.amountOfAdsReturned === this.state.limit? (<button type="submit" onClick={()=>this.loadMore()}className="btn btn-red">Carregar mais</button>) : null }
                            
                        </div>
                    </div>
                    
                </section>
            </div>


            {/* MODAIS */}
            <ModalLoadMore modalTitle="Categorias" ref={this.refLoadMoreModal}>
                <form>
                    {[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26].map((filter_item) => (

                            <div className="_filter-item">
                            <label className="_filter-label">
                                <input type="radio" name="filter-input-name" />
                                <span className="_filter-title">Nome da categoria</span>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </label>
                        </div>

                    ))}
                </form>
            </ModalLoadMore>
            </>
        );
        
    };
    
};

export default Store;