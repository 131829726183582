import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import WrapView from "./layout/WrapView";
import { withRouter } from "react-router-dom";

// Bootstrap
import { Button } from "react-bootstrap";

// Forms
import { useForm } from "react-hook-form";

import Helmet from 'react-helmet';

// Material - UI
import {
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Slide,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTrashAlt,
  faChevronRight,
  faChevronLeft,
  faTruck,
  faMobile,
  faHandHoldingBox,
  faCheck,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import Swal from "sweetalert2";

import $ from "jquery";
import "jquery-mask-plugin";

import CreditCard from "../services/CreditCard";
import Categorization from "../services/Categorization";
import Domain from "../services/Domain";
import Ad from "../services/Ad";
import Endereco from '../services/Endereco';
import Scamber from '../services/Scamber';

import ReviewProduct from "./ReviewProduct";

import { connect } from "react-redux";
import { setAd } from "./../redux/actions/adActions";

// Styles
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
});

// Modal Dialog Base
const ModalDialog = forwardRef((props, ref) => {
  // Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  // Material ui Modal
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props.customMethod) props.customMethod();
  };

  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <FontAwesomeIcon
                icon={matches ? faTimes : faChevronLeft}
              ></FontAwesomeIcon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
        <DialogContent className={props.className}>
          <Typography component={"span"} variant={"body2"}>
            {props.children}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={"btn-step " + classes.center}
            onClick={handleClose}
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40 }}
          >
            {props.btnTitle}
            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

// Material ui Dialog Slide
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreateAd(props) {
  // Ref Modals
  const refModalDialogSize = React.createRef();
  const refModalDialogCategory = React.createRef();
  const refModalDialogPrice = React.createRef();

  // React Hook Form
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    watch,
  } = useForm({
    mode: "onBlur",
  });


  // Styles
  const classes = useStyles();

  //isEditing
  const [isEditing, setIsEditing] = useState(true);

  //Listagem do cadastro
  const [brands, setBrands] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [weights, setWeights] = useState([]);
  const [sizeGroup, setSizeGroup] = useState({});

  //Parametros selecionados pelo usuario
  const [listSelectedCategorization, setListSelectedCategorization] = useState(
    []
  );
  const [selectedImages] = useState([0, 0, 0, 0]);
  const [size, setSize] = useState("");
  const [condition, setCondition] = useState({ nome: "" });
  const [brand, setBrand] = useState({ nome: "" });
  const [weight, setWeight] = useState({ nome: "" });
  const [pricePointsOriginal, setPricePointsOriginal] = useState();
  const [pricePointsNewOriginal, setPricePointsNewOriginal] = useState();
  const [pricePoints, setPricePoints] = useState();
  const [keyReRender, setKeyReRender] = useState();

  //Label final concatenato de categorizações
  const [allCategorizationLabel, setAllCategorizationLabel] = useState("");

  //Label categorização selecionada
  const [category, setCategory] = useState("");

  //Lista Original de categorizações
  const [listCategorization, setListCategorization] = useState([]);

  //Lista ativa e visivel para o usuario no momento
  const [listActiveCategorization, setListActiveCategorization] = useState([]);

  //Return next page
  const [returnNextPage, setReturnNextPage] = useState(false);

  useEffect(() => {
    if (props.ad && returnNextPage) {
      setValue("titulo", props.ad.title);
      setValue("descricao", props.ad.description);
      setValue(
        "operador_logistico",
        props.ad.deliveryTypes?.operador_logistico
      );
      //setValue('aplicativo_entrega', props.ad.deliveryTypes?.aplicativo_entrega)
      setValue("entrega_maos", props.ad.deliveryTypes?.entrega_maos);
    }
  }, [isEditing]);

  const handleSize = (size) => {
    setSize(size);
  };

  const handleCondition = (e, condition) => {
    setCondition(condition);
  };

  const handleBrand = (e, brand) => {
    setBrand(brand);
  };

  const handleWeight = (e, weight) => {
    setWeight(weight);
  };

  const handlePrice = (price) => {
    if (!pricePointsNewOriginal) {
      setPricePointsNewOriginal(pricePoints);

    }

    //setPricePoints((Math.round((price * 1.818) * 100) / 100).toFixed(2));
    setPricePoints((Math.round((price * 1.818))));
  }

  const handleCategorization = (categorization) => {
    setCategory(categorization.nome);

    let listSelected;
    if (categorization.categorias && categorization.categorias.length > 0) {
      setListActiveCategorization(categorization.categorias);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      setListActiveCategorization(categorization.tipos_produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      setListActiveCategorization(categorization.produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else {
      listSelected = listSelectedCategorization;
      const lastIndex = listSelected.length > 0 ? listSelected.length - 1 : 0;

      if (listSelected[lastIndex] && listSelected[lastIndex].lastLevel) {
        listSelected[lastIndex] = {
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        };
        setListSelectedCategorization(listSelected);
      } else {
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        });
        setListSelectedCategorization(listSelected);
      }
    }
  };

  const handleCategorizationBack = (oldCategorizationList, index) => {
    const listSelected = listSelectedCategorization.slice(0, index);
    setListSelectedCategorization(listSelected);

    setListActiveCategorization(oldCategorizationList);
  };

  useEffect(() => {
    setAllCategorizationLabel(
      listSelectedCategorization.reduce((label, selected, index) => {
        return (label += ` ${index > 0 ? ">" : ""} ${
          selected.categorization.nome
        }`);
      }, "")
    );
  }, [
    listSelectedCategorization.length,
    listSelectedCategorization[listSelectedCategorization.length - 1],
  ]);

  const hasChindren = (categorization) => {
    if (categorization.categorias && categorization.categorias.length > 0) {
      return true;
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      return true;
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      return true;
    }

    return false;
  };

  const matches = useMediaQuery("(min-width:600px)");

  const checkUserStatus = (scamber) => {
    
    const creditCard = new CreditCard();
    const endereco = new Endereco();

    endereco.find().catch((error) => {
      if (!error.response) {
        Swal.fire({
          type: "info",
          html: creditCard.formatErrorMessage(error.response),
          title: "",
        });
        props.history.push("/completar-cadastro");
      } else if (error.response.status === 404) {
        props.history.push("/completar-cadastro");
      }
    });

    // se não tiver cpf e telefone cadastrado manda para completar cadastro
    
    const scamberInstance = new Scamber();

    scamberInstance.findScamber()
      .then((response)=>{
        if(!response.data.payload.cpf) return props.history.push("/completar-cadastro");
        if(!response.data.payload.telefone) return props.history.push("/completar-cadastro");
        if(!response.data.payload.minha_loja) return props.history.push("/completar-cadastro");
      })
      .catch((error) => {
        if (!error.response) {
          Swal.fire({
            type: "info",
            html: scamber.formatErrorMessage(error.response),
            title: "",
          });
          props.history.push("/completar-cadastro");
        } else if (error.response.status === 404) {
          props.history.push("/completar-cadastro");
        }
      });


  };

  const changePosition = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  };

  const loadAdInfo = () => {
    const categorization = new Categorization();
    const domain = new Domain();
    const ad = new Ad();

    const idAnuncio = props.match.params.idAnuncio;

    if (idAnuncio) {
      Swal.fire("Carregando anúncio... Aguarde");
      Swal.showLoading();
    }

    categorization
      .list()
      .then((response) => {
        if (response.status === 200) {
          // Ordenando categorias
          // changePosition(response.data.payload,4,0);
          // changePosition(response.data.payload,2,1);
          // changePosition(response.data.payload,4,2);

          setListCategorization(response.data.payload);
          setListActiveCategorization(response.data.payload);
        }
      })
      .catch((error) =>
        Swal.fire({
          type: "info",
          html: categorization.formatErrorMessage(error.response),
          title: "",
        })
      );

    domain
      .listBrands()
      .then((response) => {
        if (response.status === 200) {
          setBrands(response.data.payload);
        }
      })
      .catch((error) =>
        Swal.fire({
          type: "info",
          html: domain.formatErrorMessage(error.response),
          title: "",
        })
      );

    domain
      .listConditions()
      .then((response) => {
        if (response.status === 200) {
          setConditions(response.data.payload);
        }
      })
      .catch((error) =>
        Swal.fire({
          type: "info",
          html: domain.formatErrorMessage(error.response),
          title: "",
        })
      );

    domain
      .listWeights()
      .then((response) => {
        if (response.status === 200) {
          setWeights(response.data.payload);
        }
      })
      .catch((error) =>
        Swal.fire({
          type: "info",
          html: domain.formatErrorMessage(error.response),
          title: "",
        })
      );

    if (idAnuncio) {
      ad.find(idAnuncio)
        .then((response) => {
          let anuncio = response.data.payload;

          setValue("titulo", anuncio.titulo);
          setValue("descricao", anuncio.descricao);
          setValue("operador_logistico", anuncio.operador_logistico);
          //setValue('aplicativo_entrega', anuncio.aplicativo_entrega)
          setValue("entrega_maos", anuncio.entrega_maos);

          setBrand(anuncio.marca[0]);
          setCondition(anuncio.condicao[0]);
          setWeight(anuncio.peso[0]);

          setPricePointsOriginal(
            anuncio.pontos_de
              ? anuncio.pontos_de.$numberDecimal
              : anuncio.pontos_por.$numberDecimal
          );
          setPricePoints(anuncio.pontos_por.$numberDecimal);
          setPricePointsNewOriginal((Math.round((parseFloat(anuncio.pontos_por.$numberDecimal) / 1.818))));
          setKeyReRender(`${Math.floor((Math.random() * 1000))}-min`);
          
          setListSelectedCategorization(
            anuncio.categorizacao.map((cat) => {
              return { categorization: cat };
            })
          );

          if (anuncio.tamanho) {
            onSelectCategorization(
              anuncio.categorizacao.map((cat) => {
                return { categorization: cat };
              })
            );
            setSize(anuncio.tamanho.tamanhos[0]);
          }

          anuncio.fotos.map((foto, index) =>
            ad.loadImageFile(foto).then((file) => {
              setSelectedImages({ target: { files: [file] } }, index);
            })
          );

          Swal.close();
        })
        .catch((error) => {
          return Swal.fire({
            type: "info",
            html: ad.formatErrorMessage(error.response),
            title: "",
          });
        });
    }
  };

  useEffect(() => {
    if (props.scamber) {
      checkUserStatus(props.scamber);
      loadAdInfo();
    }
  }, [props.scamber]);

  const onSelectCategorization = (categorizationlist) => {
    const domain = new Domain();

    let selectedCategorizations = categorizationlist
      ? categorizationlist
      : listSelectedCategorization;

    let categorizationIdsSelected = selectedCategorizations.map(
      (selectedCategorization) => {
        return selectedCategorization.categorization._id;
      }
    );

    domain
      .findSizeGroup(categorizationIdsSelected)
      .then((response) => {
        if (response.status === 200) {
          setSizeGroup(response.data.payload);
        }
      })
      .catch(error => {
        if(error.response.status !== 404) {
          return Swal.fire({type:'info', html: domain.formatErrorMessage(error.response), title:''})
        } else {
          setSizeGroup({});
          setSize("");
        }
      })
    }


  const setSelectedImages = (e, index) => {
    var reader = new FileReader();
    var file = e.target.files[0];
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      //selectedImages.splice(index,1, {show: reader.result, file: file});     
      //props.setAd({ ...props.ad, selectedImages: selectedImages });
      var image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        if(this.width < this.height) {
          selectedImages.splice(index,1, {show: reader.result, file: file, orientation: '_portrait'});     
          props.setAd({ ...props.ad, selectedImages: selectedImages });
          // this.classList.remove('_landscape');
        } else if (this.width > this.height) {
          selectedImages.splice(index,1, {show: reader.result, file: file, orientation: '_landscape'});     
          props.setAd({ ...props.ad, selectedImages: selectedImages });
          // class_img.classList.remove('_portrait');
        } else {

          selectedImages.splice(index,1, {show: reader.result, file: file, orientation: '_square'});     
          props.setAd({ ...props.ad, selectedImages: selectedImages });
          // class_img.classList.add('_square');
          // this.classList.remove('_landscape');
        }
      }
    }

  };

  const removeImage = (e, index) => {
    selectedImages.splice(index, 1, 0);
    props.setAd({ ...props.ad, selectedImages: selectedImages });
  };

  const onSubmit = (data) => {
    data.aplicativo_entrega = false;

    let selectedBrand;

    if(brand && brand.nome !== data.brand) {
      selectedBrand = { nome: data.brand };
      setBrand({ nome: data.brand })
    } else if (brand) {
      selectedBrand = brand;
    } else {
      selectedBrand = { nome: data.brand };
      setBrand({ nome: data.brand })
    }

    const formatedData = {
      ...props.ad,
      selectedImages,
      title: data.titulo,
      description: data.descricao,
      condition,
      listSelectedCategorization,
      brand: selectedBrand,
      size,
      weight,
      pricePointsOriginal: pricePointsNewOriginal
        ? pricePointsNewOriginal
        : pricePointsOriginal,
      pricePoints,
      shippingTax: checkShippingTax(pricePoints),
      allCategorizationLabel,
      deliveryTypes: {
        operador_logistico: data.operador_logistico,
        aplicativo_entrega: data.aplicativo_entrega,
        entrega_maos: data.entrega_maos,
      },
    };

    if (
      !data.operador_logistico &&
      !data.aplicativo_entrega &&
      !data.entrega_maos
    ) {
      return Swal.fire("Você deve selecionar ao menos um tipo de entrega!");
    }

    for (let i = 0; i < selectedImages.length; i++) {
      if (selectedImages[i]) {
        props.setAd(formatedData);
        setIsEditing(false);
        break;
      } else {
        return Swal.fire("Você deve selecionar ao menos uma foto!");
      }
    }
  };

  const checkShippingTax = (pricePoints) => {
    if(pricePoints <= 99) {
      return '3.90';
    } else if(pricePoints >= 100 && pricePoints <= 199) {
      return '5.90';
    } else if(pricePoints >= 200 && pricePoints <= 499) {
      return '9.90';
    } else if(pricePoints >= 500 && pricePoints <= 799) {
      return '12.90';
    } else if(pricePoints >= 800 && pricePoints <= 999) {
      return '14.90';
    } else if(pricePoints >= 1000) {
      return '19.90';
    }
  }

  const openCategorizationModal = () => {
    setListActiveCategorization(listCategorization);
    setListSelectedCategorization([]);
    setAllCategorizationLabel("");

    refModalDialogCategory.current.openModal();
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start'
  });

  return (
    <>
      <Helmet title="Scamb - Criar novo" />
      {isEditing ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalDialog
            dialogTitle="Selecione o tamanho"
            btnTitle="Aplicar"
            className="_modal-weight"
            ref={refModalDialogSize}
          >
            <div className="_content">
              {sizeGroup.nome ? (
                <>
                  <div className="tag-size">{sizeGroup.nome}</div>

                  <Grid container spacing={1}>
                    {sizeGroup.tamanhos.map((tamanho, index) => (
                      <Grid item xs={2} key={index}>
                        <div className="wrap-size">
                          <label>
                            <input
                              type="radio"
                              name="select_weight"
                              value={size}
                              onChange={() => handleSize(tamanho)}
                              checked={
                                size.nome === tamanho.nome ? "checked" : ""
                              }
                            ></input>
                            <span className="_mark">{tamanho.nome}</span>
                          </label>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </div>
          </ModalDialog>

          <ModalDialog
            customMethod={onSelectCategorization}
            dialogTitle="Categorias"
            btnTitle="Aplicar"
            className="_modal-category"
            ref={refModalDialogCategory}
          >
            <span className="_content">
              <a href="#!" className="tag-selected-radio">
                {listSelectedCategorization.map(
                  (selectedCategorization, index) => {
                    if (index === 0) {
                      return (
                        <div
                          onClick={() =>
                            handleCategorizationBack(
                              selectedCategorization.oldCategorizationList,
                              index
                            )
                          }
                          key={index}
                          className="_title-selected _only"
                        >
                          <FontAwesomeIcon
                            className="icon"
                            icon={faChevronLeft}
                          ></FontAwesomeIcon>
                          <span class="_text">

                          {selectedCategorization.categorization.nome}
                          </span>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          onClick={() =>
                            handleCategorizationBack(
                              selectedCategorization.oldCategorizationList,
                              index
                            )
                          }
                          key={index}
                          className="_title-selected _only _multiple"
                        >
                          <span class="_text">

                          {selectedCategorization.categorization.nome}
                          </span>
                        </div>
                      );
                    }
                  }
                )}
              </a>

              <ul id="drilldown">
                {listActiveCategorization.map((categorization, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="radio"
                        name="select_category_lvl_1"
                        checked={
                          category === categorization.nome ? "checked" : ""
                        }
                        onChange={() => handleCategorization(categorization)}
                      />
                      <span className="_name">{categorization.nome}</span>
                      {hasChindren(categorization) ? (
                        <div className="icon">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                          ></FontAwesomeIcon>
                        </div>
                      ) : (
                        <></>
                      )}
                    </label>
                  </li>
                ))}
              </ul>
            </span>
          </ModalDialog>

          <ModalDialog
            dialogTitle="Preço do produto"
            btnTitle="Aplicar"
            className="_modal-price"
            ref={refModalDialogPrice}
          >
            <span className="_content">
              <Grid container spacing={2}>
                {/* Price #1 */}
                <Grid item xs={12}>
                  <div className="_label">Valor em real para conversão *</div>
                  <TextField
                    id="outlined-state"
                    label=" "
                    type="number"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="add_real_price"
                    fullWidth
                    defaultValue={pricePointsNewOriginal}
                    key={keyReRender}
                    onChange={(e) => {
                      handlePrice(e.target.value);
                    }}
                    inputProps={{
                      //className:'money',
                      startadornment: (
                        <InputAdornment
                          className="_adornment-price"
                          position="start"
                        >
                          R$
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Price #2 */}
                <Grid item xs={12}>
                  <div className="_label">Valor convertido em pontos *</div>
                  <TextField
                    id="outlined-state"
                    label=" "
                    type="number"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="add_points_price"
                    fullWidth
                    value={pricePoints}
                    inputProps={{
                      //className:'money',
                      startadornment: (
                        <InputAdornment
                          className="_adornment-price"
                          position="start"
                        >
                          Pontos
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <div className="price-info">
                <h3>Ajudante de precificação</h3>
                <h3>
                  Você vai avaliar seu produto em reais e nós vamos converter em
                  pontos Scamb para você. Essas são algumas dicas:
                </h3>
                <ul>
                  <li>
                    <p>- seja justx</p>
                  </li>
                  <li>
                    <p>
                      - é legal dar uma pesquisada no site da marca para ter uma
                      base
                    </p>
                  </li>
                  <li>
                    <p>
                      - veja aqui no Scamb se há produtos similares ou da mesma
                      marca
                    </p>
                  </li>
                  <li>
                    <p>
                      - arranhões e desgastes contam na hora da precificação
                    </p>
                  </li>
                  <li>
                    <p>- qual a sua pressa em vender o item?</p>
                  </li>
                  <li>
                    <p>- lembre-se que sempre pode haver negociação</p>
                  </li>
                </ul>
              </div>
            </span>
          </ModalDialog>

          <div id="view_create-ad">
            <WrapView
              headline="Capriche nas fotos
              e na descrição do seu produto!"
              stepTitle="O que você está anunciando?"
              className="_screen-conf create-new-add"
            >
              <section className="_add-photos">
                <div className="form-group _photos">
                  <div className="_label">
                    Fotos *
                    <small className="form-text text-muted">
                      Upload máximo de 4 fotos.
                    </small>
                  </div>

                  <div className="_wrap-photos">
                  <div className="_wrap-photo-item">
                        <div style={{width:"150px",height:"120px"}} className="_photo-item">
                          <input
                            type="file"
                            accept="image/x-png, image/jpeg"
                            name="photo-upload1"
                            id="photo-upload1"
                            className="form-control-file"
                            onChange={(e) => setSelectedImages(e, 0)}
                          />

                          
                          {props.ad?.selectedImages[0] ? (
                            <div className="_image-selected">


                              <img
                                src={selectedImages[0].show}
                                alt=""
                                className={"my-image " +props.ad.selectedImages[0].orientation}
                              />

                              <button
                                type="button"
                                className="btn btn-transparent _delete-image"
                                onClick={(e) => removeImage(e, 0)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                ></FontAwesomeIcon>
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}

                          <label htmlFor="photo-upload1">
                            <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>

                            <div className="_title-label">Adicionar foto principal</div>

                            <div className="_accept-formats-label">
                              JPG, GIF E PNG Somente
                            </div>
                          </label>
                        </div>
                    </div>
                      
                    <div className="_wrap-photo-item">
                      <div className="_photo-item">
                        <input
                          type="file"
                          accept="image/x-png, image/jpeg"
                          name="photo-upload2"
                          id="photo-upload2"
                          className="form-control-file"
                          onChange={(e) => setSelectedImages(e, 1)}
                          disabled={selectedImages[0] === 0}
                        />

                        {props.ad?.selectedImages[1] ? (
                          <div className="_image-selected">
                            <img src={selectedImages[1].show} alt="" className={"my-image " +props.ad.selectedImages[1].orientation} />

                            <button
                              type="button"
                              className="btn btn-transparent _delete-image"
                              onClick={(e) => removeImage(e, 1)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                              ></FontAwesomeIcon>
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}

                        <label htmlFor="photo-upload2">
                          <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>

                          <div className="_title-label">Adicionar foto</div>

                          <div className="_accept-formats-label">
                            JPG, GIF E PNG Somente
                          </div>
                        </label>
                      </div>
                    </div>

                    <div className="_wrap-photo-item">
                      <div className="_photo-item">
                        <input
                          type="file"
                          accept="image/x-png, image/jpeg"
                          name="photo-upload3"
                          id="photo-upload3"
                          className="form-control-file"
                          onChange={(e) => setSelectedImages(e, 2)}
                          disabled={selectedImages[1] === 0}
                        />

                        {props.ad?.selectedImages[2] ? (
                          <div className="_image-selected">
                            <img src={selectedImages[2].show} alt="" className={"my-image " +props.ad.selectedImages[2].orientation} />

                            <button
                              type="button"
                              className="btn btn-transparent _delete-image"
                              onClick={(e) => removeImage(e, 2)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                              ></FontAwesomeIcon>
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}

                        <label htmlFor="photo-upload3">
                          <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>

                          <div className="_title-label">Adicionar foto</div>

                          <div className="_accept-formats-label">
                            JPG, GIF E PNG Somente
                          </div>
                        </label>
                      </div>
                    </div>

                    <div className="_wrap-photo-item">
                      <div className="_photo-item">
                        <input
                          type="file"
                          accept="image/x-png, image/jpeg"
                          name="photo-upload4"
                          id="photo-upload4"
                          className="form-control-file"
                          onChange={(e) => setSelectedImages(e, 3)}
                          disabled={selectedImages[2] === 0}
                        />

                        {props.ad?.selectedImages[3] ? (
                          <div className="_image-selected">
                            <img src={selectedImages[3].show} alt="" className={"my-image " +props.ad.selectedImages[3].orientation} />

                            <button
                              type="button"
                              className="btn btn-transparent _delete-image"
                              onClick={(e) => removeImage(e, 3)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                              ></FontAwesomeIcon>
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}

                        <label htmlFor="photo-upload4">
                          <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>

                          <div className="_title-label">Adicionar foto</div>

                          <div className="_accept-formats-label">
                            JPG, GIF E PNG Somente
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="form-body">
                <Grid container spacing={2}>
                  {/* Title */}
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-add-title"
                      label="Título do anúncio *"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="titulo"
                      fullWidth
                      //InputProps={{ style: {height: (matches ? 40 : 53)}}}
                      helperText={errors.titulo?.type === "minLength" && "é muito curto (minimo 10 caracteres)" || !!errors.titulo && "não pode ficar em branco"}
                      error={!!errors.titulo}
                      inputRef={register({
                        required: true,
                        minLength: 10,
                      })}
                      inputProps={{
                        maxLength: 40,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {/* Description */}
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-add-description"
                      label="Descrição *"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      type="textarea"
                      multiline
                      rows={4}
                      name="descricao"
                      fullWidth
                      helperText={errors.descricao?.type === "minLength" && "é muito curto (minimo 30 caracteres)" || !!errors.descricao && "não pode ficar em branco"}
                      error={!!errors.descricao}
                      inputRef={register({
                        required: true,
                        minLength: 30,
                      })}
                      inputProps={{
                        maxLength: 300,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {/* Condition */}
                  <Grid item xs={12}>
                    <Autocomplete
                      id="outlined-condition"
                      autoComplete={true}
                      options={conditions}
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      size="small"
                      fullWidth
                      value={condition}
                      onChange={(e, value) => handleCondition(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Condição *"
                          name="add_condition"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          error={!!errors.add_condition}
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Category */}
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-category"
                      label="Categoria *"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="add_category"
                      value={allCategorizationLabel}
                      fullWidth
                      error={!!errors.add_category}
                      inputRef={register({
                        required: true,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => openCategorizationModal()}
                      inputProps={{
                        endadornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon
                              icon={faChevronRight}
                            ></FontAwesomeIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                {/* Brand */}
                <Grid item xs={12}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    id="outlined-brand"
                    options={brands}
                    includeInputInList 
                    freeSolo
                    getOptionLabel={(option) => option.nome}
                    getOptionSelected={(option) => option._id }
                    fullWidth
                    value={brand}
                    onChange={(e, value) => handleBrand(e, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Marca *"
                        name="brand"
                        variant="outlined"
                        size={matches ? "small" : "medium" }
                        error={!!errors.brand}
                        inputRef={register({
                          required: true,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>

                 {/* Size */}
                 {sizeGroup.nome? (<Grid item xs={12}>
                  <TextField
                    id="outlined-size"
                    label="Tamanho *"
                    type="text"
                    variant="outlined"
                    size={matches ? "small" : "small" }
                    name="add_size"
                    value={size.nome}
                    fullWidth
                    error={!!errors.add_size}
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => refModalDialogSize.current.openModal()}
                    inputProps={{
                      endadornment: (
                        <InputAdornment position="end">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                          ></FontAwesomeIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>):(<></>)}

                  {/* Weight */}
                  <Grid item xs={12}>
                    <Autocomplete
                      id="outlined-weight"
                      options={weights}
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      fullWidth
                      value={weight}
                      onChange={(e, value) => handleWeight(e, value)}
                      inputprops={{ classes: classes.resize }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Peso *"
                          name="add_weight"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          error={!!errors.add_weight}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputRef={register({
                            required: true,
                          })}
                        />
                      )}
                    />
                  </Grid>

                  {/* Price */}
                  <Grid item xs={12}>
                    {/* <div className="_label">Preço *</div> */}
                    <TextField
                      id="outlined-price"
                      label="Preço *"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="add_price"
                      fullWidth
                      error={!!errors.add_price}
                      // error={!!errors.add_weight}
                      // inputRef={register({
                      //   required: true,
                      // })}
                      value={pricePoints}
                      onClick={() => refModalDialogPrice.current.openModal()}
                      inputRef={register({
                        required: true,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        endadornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon
                              icon={faChevronRight}
                            ></FontAwesomeIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <section className="_select-freight">
                  <div className="_label">Tipo de entrega *</div>
                  <Grid container spacing={2} className="_wrap-freight">
                  <Grid item md={2}>
                      <div className="_wrap-check-radio">
                        <label className="_check-radio">
                          <input
                          type="checkbox"
                          className="form-check-input"
                          name="operador_logistico"
                          //error={!!errors.operador_logistico}
                          ref={register({
                            required: false
                          })}
                          />
                          <span className="overlay"></span>
                          <div className="_wrap-alt-icon">
                            <div className="_alt-icon">
                              <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
                            </div>
                            <span>Correios</span>
                          </div>
                          <span className="check">
                            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                          </span>
                        </label>
                      </div>
                    </Grid>
                    { false? (<Grid item md={2}>
                      <div className="_wrap-check-radio">
                        <label className="_check-radio">
                          <input
                          type="checkbox"
                          className="form-check-input"
                          name="aplicativo_entrega"
                          //error={!!errors.aplicativo_entrega}
                          ref={register({
                            required: false
                          })}
                          />
                          <span className="overlay"></span>
                          <div className="_wrap-alt-icon">
                            <div className="_alt-icon">
                              <FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>
                            </div>
                            <span>Aplicativo de entrega</span>
                          </div>
                          <span className="check">
                            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                          </span>
                        </label>
                      </div>
                    </Grid>):(<></>)}
                    <Grid item md={2}>
                      <div className="_wrap-check-radio">
                        <label className="_check-radio">
                          <input
                          type="checkbox"
                          className="form-check-input"
                          name="entrega_maos"
                          //error={!!errors.entrega_maos}
                          ref={register({
                            required: false
                          })}
                          />
                          <span className="overlay"></span>
                          <div className="_wrap-alt-icon">
                            <div className="_alt-icon">
                              <FontAwesomeIcon icon={faHandHoldingBox}></FontAwesomeIcon>
                            </div>
                            <span>Entrega em mãos</span>
                          </div>
                          <span className="check">
                            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                          </span>
                        </label>
                      </div>
                    </Grid>
                  </Grid>
                </section>

                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: matches ? 35 : 35 }}
                  disabled={!formState.isValid && !setReturnNextPage}
                >
                  Continuar
                  <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                </Button>
              </section>
            </WrapView>
          </div>
        </form>
      ) : (
        <ReviewProduct
          setIsEditing={setIsEditing}
          setReturnNextPage={setReturnNextPage}
        ></ReviewProduct>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    scamber: state.scamberReducer.scamber,
    ad: state.adReducer.ad,
  };
};

export default withRouter(connect(mapStateToProps, { setAd })(CreateAd));
