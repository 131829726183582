import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

// Bootstrap
import { Button } from "react-bootstrap";

// Material - UI
import { makeStyles, useMediaQuery } from "@material-ui/core";
import Helmet from 'react-helmet';

// Styles
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
});

export default function Welcome() {
  // Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const [redirect, setRedirect] = useState(false);

  const handleRedirect = () => {
    setRedirect(!redirect);
  };

  const renderRedirect = () => {
    if (redirect) return <Redirect to="/criar-conta" />;
  };

  return (
    <div id="view_access">
      <Helmet title="Scamb - Prosseguir" />
      {
        //Redirect Call
        renderRedirect()
      }

      <section className="_wrap-access-screen">
        <div className="access-screen">
          <h1 className="_access-screen-title">
            Olá!
            <span className="heading">
              Antes de continuarmos, você precisa criar uma conta. Mas não se preocupe, é super rápido!
            </span>
          </h1>

          <Button
            className={"btn-step " + classes.center}
            color="primary"
            autoFocus
            onClick={() => handleRedirect()}
            style={{ height: matches ? 35 : 40 }}
          >
            Crie sua conta
          </Button>

          <div className="_have-account _color _black _fw700">
            Já possui uma conta? <Link to="/login">Faça o login</Link>
          </div>
        </div>
      </section>
    </div>
  );
}
