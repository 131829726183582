import Service from './Service';

class Ad extends Service {

  constructor(){
    super();
  }

  insert( adInfo ) {
    return this.axiosInstance.post(`/ad`, adInfo, { headers: {'Content-Type': 'multipart/form-data'} });
  }

  update( adInfo, idAd ) {
    return this.axiosInstance.put(`/ad/update/${idAd}`, adInfo, { headers: {'Content-Type': 'multipart/form-data'} });
  }

  listActive( ) {
    return this.axiosInstance.get(`/ad/active`);
  }

  listInactive( ) {
    return this.axiosInstance.get(`/ad/inactive`);
  }

  find( idAd ) {
    return this.axiosInstance.get(`/ad/find/${idAd}`);
  }

  setState( adInfo ) {
    return this.axiosInstance.put(`/ad/setState/`, adInfo);
  }

  findForAll( idAd ){
    return this.axiosInstance.get(`/ad/find/all/${idAd}`);
  }

  findOtherAdsOfSeller( idAd ){
    return this.axiosInstance.get(`/ad/find/scamberAds/${idAd}`);
  }

  findAdByStoreName( storeUrl, limit ){
    return this.axiosInstance.get(`/ad/find/scamberStore/${storeUrl}?limit=${limit}`);
  }

  findAdsByUserSearch( searchString, limit, category, tag, aleatory, pageNumber, price, points, condition, categoryList, brand, deliveryType, storeUrl){
    return this.axiosInstance.get(`ad/find/products/?limit=${limit}${searchString? '&key=' + searchString : ''}${category? '&category=' + category : ''}${tag? '&tag=' + tag : ''}${aleatory? '&aleatory=' + aleatory : ''}${pageNumber? '&pageNumber=' + pageNumber : ''}${price? '&price=' + price : ''}${points? '&points=' + points : ''}${condition? '&condition=' + condition : ''}${categoryList? '&categoryList=' + categoryList : ''}${brand? '&brand=' + brand : ''}${deliveryType? '&deliveryType=' + deliveryType : ''}${storeUrl? '&storeUrl=' + storeUrl : ''}`);
  }

}

export default Ad;
