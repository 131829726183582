import React, { Component } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/account/Layout";
import CreditCard from "../../components/account/wallet/CreditCard";
import ActivityHistory from "../../components/account/wallet/ActivityHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// Icons
import {
  faChevronUp,
  faChevronDown,
  faWallet,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import Visa from "../../assets/images/credit-card/visa.png";
import Mastercard from "../../assets/images/credit-card/mastercard.png";
import AmericanExpress from "../../assets/images/credit-card/americanExpress.png";
import DinersClub from "../../assets/images/credit-card/DinersClub.png";

import Helmet from 'react-helmet';


class MyWallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      points_info: false,
      toggle_icon: true,
      cards:""
    };
  }

  handlePointsInfo = () => {
    this.setState({
      points_info: !this.state.points_info,
      toggle_icon: !this.state.toggle_icon,
    });
  };

  render() {
    return (
      <div id="view_my-wallet">
        <Helmet title="Scamb - Minha carteira" />
        <Layout>
          <div id="_my-wallet">
            <h1 className="_title-box">
              <span>
                <FontAwesomeIcon icon={faWallet} />
              </span>
              Minha Carteira
            </h1>

            <div className="_wrap-cards _flex">
              <div className="ui-card _fluid">
                <div className="ui-card-head _with-icon">
                  <h1 className="ui-card-title">Meus Pontos</h1>

                  <button
                    type="button"
                    className="btn btn-transparent rotate"
                    onClick={this.handlePointsInfo}
                  >
                    <FontAwesomeIcon
                      icon={
                        this.state.toggle_icon ? faChevronDown : faChevronUp
                      }
                    ></FontAwesomeIcon>
                  </button>
                </div>

                <div className="ui-card-content">
                  <h1 className="my-points">
                    <div className="_product-price _2x">
                      <div className="ui-item__price">
                        <span class="price-tag-fraction">0</span>
                        <span class="price-tag-symbol">pts</span>
                      </div>
                    </div>
                  </h1>
                  {this.state.points_info ? (
                    <div className="points_informations">
                      <ul className="_list-info">
                        <li>
                          <div className="key">
                            Pontos adquiridos
                            <div className="_product-price _1x">
                              <div className="ui-item__price">
                                <span class="price-tag-fraction">940</span>
                                <span class="price-tag-symbol">pts</span>
                              </div>
                            </div>
                          </div>
                          <div className="val">Expira em: 248 Dias</div>
                        </li>
                        <li>
                          <div className="key">
                            Pontos comprados
                            <div className="_product-price _1x">
                              <div className="ui-item__price">
                                <span class="price-tag-fraction">260</span>
                                <span class="price-tag-symbol">pts</span>
                              </div>
                            </div>
                          </div>
                          <div className="val">Não expiram</div>
                        </li>
                        <li>
                          <div className="key">
                            Pontos Bonificados:
                            <div className="_product-price _1x">
                              <div className="ui-item__price">
                                <span class="price-tag-fraction">60</span>
                                <span class="price-tag-symbol">pts</span>
                              </div>
                            </div>
                          </div>
                          <div className="val">Expira em: 78 Dias</div>
                        </li>
                      </ul>
                    </div>
                  ) : null}

                  <div className="add-points">
                    <h1 className="add-points-title">
                      adicione pontos a sua carteira
                    </h1>

                    <div className="add-points-content">
                      <ul className="_payment-methods-list">
                        <li>
                          <div className="creadit-card--flag">
                            <img src={Visa} alt="" />
                          </div>
                        </li>
                        <li>
                          <div className="creadit-card--flag">
                            <img src={Mastercard} alt="" />
                          </div>
                        </li>
                        <li>
                          <div className="creadit-card--flag">
                            <img src={AmericanExpress} alt="" />
                          </div>
                        </li>
                        <li>
                          <div className="creadit-card--flag">
                            <img src={DinersClub} alt="" />
                          </div>
                        </li>
                      </ul>

                      <Link className="link-button" to="/">
                        Comprar mais pontos
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="_wrap-cards">
              <div className="ui-card _fluid _cc">
                <div className="ui-card-head _with-icon">
                  <h1 className="ui-card-title">Meus Cartões</h1>
                  <Link to="/metodo-de-pagamento" className="btn-add-payment">
                    Novo cartão
                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                  </Link>
                </div>

                <div className="ui-card-content">
                  <CreditCard></CreditCard>
                </div>
              </div>
            </div>

            <div className="_wrap-cards">
              <div className="ui-card _fluid _with-icon activity-history">
                <div className="ui-card-head _with-icon">
                  <h1 className="ui-card-title">Histórico de atividades</h1>
                </div>

                <div className="ui-card-content">
                  {/* <ActivityHistory></ActivityHistory> */}
                  <section className="_load-more-activities">
                    {/* <Link
                      to="/historico-de-atividades"
                      className="link-button _load-more"
                    >
                      Ver histórico completo
                    </Link> */}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default MyWallet;
