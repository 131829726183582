import React, { useState } from "react";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from "@fortawesome/pro-light-svg-icons";

export const Filter = (props) => {
    const [filter, setFilter] = useState(false);

    const handleFilter = () => {
        setFilter(!filter);
    }

    return (
        <>
            
            <div className="_filter">
                <button type="button" className="_filter-button" onClick={handleFilter}>
                    {props.filterTitle}
                    <FontAwesomeIcon icon={filter ? faChevronUp : faChevronDown}></FontAwesomeIcon>
                </button>
            
                <div className={filter ? "_filter-item _filter-show" : "_filter-item"}>
                    { props.children }
                </div>
            </div>
        </>
    )

}

export const FilterItem = (props) => {

    return (
        <label className="_filter-label">
            <input type={props.inputType} name={props.inputName} />
            <span className="_filter-title">{props.filterTitle}</span>
            {props.filterCount && (
                <span className="_filter-count">({props.filterCount})</span>
            )}
        </label>
    )

}

export const FilterHead = (props) => {
    return (
        <div className="_filter-head">
            {props.children}
            <div className="_title"> {props.title} </div>
        
        </div>
    )
}