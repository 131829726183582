import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// JQuery
import $ from "jquery";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';

// Slider
import ImageGallery from "react-image-gallery";

// Material - UI
import { useMediaQuery } from "@material-ui/core";

import { Button } from "react-bootstrap";

// Manifest Images
import manifest_1 from "../assets/images/institucional/landing-page/manifesto/1.png";
import manifest_2 from "../assets/images/institucional/landing-page/manifesto/2.png";
import manifest_3 from "../assets/images/institucional/landing-page/manifesto/3.png";
import manifest_4 from "../assets/images/institucional/landing-page/manifesto/4.png";
import manifest_5 from "../assets/images/institucional/landing-page/manifesto/5.png";
import manifest_6 from "../assets/images/institucional/landing-page/manifesto/6.png";
import manifest_7 from "../assets/images/institucional/landing-page/manifesto/7.png";
import manifest_8 from "../assets/images/institucional/landing-page/manifesto/8.png";
import manifest_9 from "../assets/images/institucional/landing-page/manifesto/9.png";
import manifest_10 from "../assets/images/institucional/landing-page/manifesto/10.png";
import manifest_11 from "../assets/images/institucional/landing-page/manifesto/11.png";
import manifest_12 from "../assets/images/institucional/landing-page/manifesto/12.png";
import manifest_13 from "../assets/images/institucional/landing-page/manifesto/13.png";

import Helmet from 'react-helmet';
// Manifest Images
const images_manifest = [
    { original: manifest_1, thumbnail: manifest_1 },
    { original: manifest_2, thumbnail: manifest_2 },
    { original: manifest_3, thumbnail: manifest_3 },
    { original: manifest_4, thumbnail: manifest_4 },
    { original: manifest_5, thumbnail: manifest_5 },
    { original: manifest_6, thumbnail: manifest_6 },
    { original: manifest_7, thumbnail: manifest_7 },
    { original: manifest_8, thumbnail: manifest_8 },
    { original: manifest_9, thumbnail: manifest_9 },
    { original: manifest_10, thumbnail: manifest_10 },
    { original: manifest_11, thumbnail: manifest_11 },
    { original: manifest_12, thumbnail: manifest_12 },
    { original: manifest_13, thumbnail: manifest_13 },
];

const params_manifest = {
    showThumbnails: false,
    showFullscreenButton: false,
    showPlayButton: false,
    infinite: true,
    thumbnailPosition: "bottom",
    showBullets: true,
    showNav: true,
    autoPlay: false,
    slideDuration: 450,
    slideInterval: 10000,
};

// Data
const nav = [
    { title: "como funciona?", link: "#como-funciona" },
    { title: "sobre o scamb", link: "#sobre-o-scamb" },
    { title: "manifesto", link: "#manifesto" },
    { title: "insight", link: "#insight" },
    { title: "por que o scamb?", link: "#scamb" },
    { title: "inscreva-se", link: "#inscreva-se" },
];


const Section = (props) => {
    return (
        <section id={props.id} className={props.className} style={{backgroundColor: `${ props.bgColor }`}}>
            <div className="_med-container">
                { props.children }
            </div>
        </section>
    )
}

const Headline = (props) => {
    return (
        <hgroup className="_block-headline">
            <h1 className={props.className} style={{fontSize: `${ props.fontSize }px`}}> { props.title } </h1>
            <h2>
                { props.children }
            </h2>
        </hgroup>
    )
}

function Institutional () {

    const matches = useMediaQuery("(min-width:600px)");

    const [ menu, setMenu ] = useState(false);

    const handleMenu = () => {
        setMenu(!menu)
    }
    
    const closeMenu = () => {
        setMenu(false)
    }

    useEffect(() => {

        $(".sliding-link").on('click', function (e) {
            e.preventDefault();
            var aid = $(this).attr("href");
            $('html,body').animate({
                scrollTop: $(aid).offset().top
            }, 'slow');
        });

    }, [])

    return (
        <main role="main" id="view_institutional">
            <Helmet title="Scamb - Sobre o Scamb" />
            <Section className="menu">
                <button type="button" className="_btn-menu" onClick={handleMenu}>
                    <span>Menu</span>
                    <FontAwesomeIcon icon={ menu ? faTimes : faBars }></FontAwesomeIcon>
                </button>

                <nav className={ menu ? "_nav" : "_nav _is-hidden" }>
                    <ul>
                        { nav.map(( nav_item, index ) => (

                            <li onClick={closeMenu} key={ index }>
                                <a href={ nav_item.link } className="sliding-link">
                                    { nav_item.title }
                                </a>
                            </li>

                        )) }
                    </ul>
                </nav>
            </Section>

            <Section className="point1 with-video" bgColor="#FFFFFF" id="como-funciona">
                
                <Headline className="headline" title="Como funciona?" fontSize={ matches ? 28 : 18 }></Headline>
                
                <div className="video">
                    <iframe src="https://player.vimeo.com/video/466248629" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>

                <Button
                    type="button"
                    color="primary"
                    className="_button"
                    autoFocus
                  >
                      <Link to="/criar-conta">
                          Crie sua conta
                      </Link>
                  </Button>
            </Section>

            <Section className="point2 with-video" bgColor="#14233e" id="sobre-o-scamb">
                
                <Headline className="headline" title="Sobre o Scamb" fontSize={ matches ? 28 : 18 }>
                    Quer saber um pouco mais sobre o Scamb? O que nos diferencia?
                    Neste vídeo, duas de nossas fundadoras explicam um pouco mais sobre a plataforma!
                </Headline>

                <div className="video">
                    <iframe src="https://player.vimeo.com/video/466257620" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>

            </Section>

            <Section className="point3" bgColor="#FFFFFF" id="manifesto">
                
                <Headline className="headline" title="Manifesto" fontSize={ matches ? 28 : 18 }></Headline>
                
                <ImageGallery {...params_manifest} items={images_manifest} className="manifest" />

            </Section>

            <Section className="point4 with-video" bgColor="#E3B487" id="insight">
                
                <Headline className="headline" title="Insight" fontSize={ matches ? 28 : 18 }></Headline>
                
                <div className="video">
                    <iframe src="https://player.vimeo.com/video/466259883" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>

            </Section>

            <Section className="point5 with-video" bgColor="#F9F9F9" id="scamb">
                
                <Headline className="headline" title="Por que Scamb?" fontSize={ matches ? 28 : 18 }>
                    Não seria legal usar coisas que estão paradas em casa como moeda para adquirir outras coisas? Ganhar pontos na venda,
                    e com esses pontos, poder comprar outras coisas? Ou ainda mais: e se esses pontos virassem desconto em serviços ou produtos de parceiros do Scamb? 
                    <br/>
                    <br/>
                    Essa é a nossa proposta! Para o Scamb, as coisas têm valor. Somos uma nova forma de consumir, que
                    possibilita o acesso a coisas, estimula a circularidade e o consumo consciente! 
                    <br/>
                    <br/>
                    No Scamb as coisas funcionam de um jeito diferente:
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            - Criamos os pontos como nossa moeda, para promover a circularidade das coisas sem o uso do dinheiro.
                        </li>
                        <li>
                            - Temos uma curadoria ativa que trabalha para oferecer um acervo bacana. Assim, os Scambers podem comprar coisas legais de uma forma mais sustentável.
                        </li>
                        <li>
                            - Estimulamos o senso de comunidade e o conceito de desapegar e não “se livrar”, para que os Scambers desapeguem de coisas com a mesma qualidade daquilo que gostariam de comprar!
                        </li>
                        <li>
                            - Queremos que o assunto segunda mão entre na vida das pessoas de forma leve e fácil, do jeito que deve ser!
                        </li>
                    </ul>
                    <br/>
                    Não faz muito sentido?
                </Headline>

            </Section>

            <section class="newsletter point6 _bg-color _blue" id="inscreva-se">
          <div class="_med-container">
            <div class="newsletter-content">
              <h1 class="_color _white _font _comfortaa _fw700 text-center">
                Inscreva-se e receba notícias!
              </h1>

              <h2 class="_color _white _font _comfortaa _fw300 text-center">
                estamos trabalhando para criar uma plataforma incrível pra você!
                Mas mesmo antes de lançarmos, você já pode fazer parte deste
                movimento com a gente! Torne-se um Scamber desde já! Inscreva-se
                e acompanhe todas as novidades!
              </h2>

              {/* Begin Mailchimp Signup Form */}
              <div id="mc_embed_signup">
                <form
                  action="https://scamb.us4.list-manage.com/subscribe/post?u=461e5f1fcbec6418e8a7261bc&amp;id=a8d600c8bb"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  class="newsletter-form needs-validation validate"
                  target="_blank"
                  novalidate
                >
                  <div id="mc_embed_signup_scroll">
                    <div class="form-group mc-field-group">
                      <input
                        type="email"
                        name="EMAIL"
                        class="form-control email"
                        id="mce-EMAIL"
                        placeholder="Digite seu melhor email"
                        required
                      />
                    </div>
                    <div id="mce-responses" class="clear">
                      <div
                        class="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        class="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>{" "}
                    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_461e5f1fcbec6418e8a7261bc_a8d600c8bb"
                        tabindex="-1"
                        value=""
                      />
                    </div>
                    <div class="form-group clear">
                      <input
                        type="submit"
                        value="Me inscrever"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        class="button"
                      />
                    </div>
                  </div>
                </form>
              </div>
              {/*End mc_embed_signup*/}
            </div>
          </div>
        </section>

        </main>
    )

}

export default Institutional;